// Core
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import moment from 'moment';
// Material UI
import { Grid, Typography } from '@material-ui/core';
// Utils
import { compose } from '@Utils/index';
import { AbsentIcon } from '@Utils/constans/icons';
import { formatPhoneNumber } from '@Utils/formatting';
// Components
import FieldWrapper from '@CommonScene/FieldWrapper';

import useStyles from './styles';

const ProfileViewForm = ({ form, justify }) => {
  const classes = useStyles();
  const formatDisplay = value => {
    return !value ? <AbsentIcon /> : value;
  };
  const formatPhone = value => {
    return formatPhoneNumber(value);
  };
  const formatDob = value => {
    return !value ? <AbsentIcon /> : moment(value).format('YYYY-MM-DD');
  };
  return (
    <Grid
      container
      className={classes.wrapper}
      direction="row"
      justify={justify}
      alignItems="center"
    >
      <Grid item className={classes.form}>
        <FieldWrapper
          label="First name"
          labelClassName={classes.label}
          childrenClassName={classes.formValueWrapper}
          className={classes.field}
        >
          <Typography variant="body2" className={classes.formValue}>
            {formatDisplay(form.fields.firstName.value)}
          </Typography>
        </FieldWrapper>
        <FieldWrapper
          label="Middle name"
          labelClassName={classes.label}
          childrenClassName={classes.formValueWrapper}
          className={classes.field}
        >
          <Typography variant="body2" className={classes.formValue}>
            {formatDisplay(form.fields.middleName.value)}
          </Typography>
        </FieldWrapper>
        <FieldWrapper
          label="Last name"
          labelClassName={classes.label}
          childrenClassName={classes.formValueWrapper}
          className={classes.field}
        >
          <Typography variant="body2" className={classes.formValue}>
            {formatDisplay(form.fields.lastName.value)}
          </Typography>
        </FieldWrapper>
        <FieldWrapper
          label="Title"
          labelClassName={classes.label}
          childrenClassName={classes.formValueWrapper}
          className={classes.field}
        >
          <Typography variant="body2" className={classes.formValue}>
            {formatDisplay(form.fields.title.name)}
          </Typography>
        </FieldWrapper>
        <FieldWrapper
          label="Date of birth"
          labelClassName={classes.label}
          childrenClassName={classes.formValueWrapper}
          className={classes.field}
        >
          <Typography variant="body2" className={classes.formValue}>
            {formatDob(form.fields.dob.value)}
          </Typography>
        </FieldWrapper>
        <FieldWrapper
          label="E-mail"
          labelClassName={classes.label}
          childrenClassName={classes.formValueWrapper}
          className={classes.field}
        >
          <Typography variant="body2" className={classes.formValue}>
            {formatDisplay(form.fields.email.value)}
          </Typography>
        </FieldWrapper>
        <FieldWrapper
          label="Phone"
          labelClassName={classes.label}
          childrenClassName={classes.formValueWrapper}
          className={classes.field}
        >
          <Typography variant="body2" className={classes.formValue}>
            {formatPhone(form.fields.phone.value)}
          </Typography>
        </FieldWrapper>
        <FieldWrapper
          label="Employee ID"
          labelClassName={classes.label}
          childrenClassName={classes.formValueWrapper}
          className={classes.field}
        >
          <Typography variant="body2" className={classes.formValue}>
            {formatDisplay(form.fields.employeeId.value)}
          </Typography>
        </FieldWrapper>
        <FieldWrapper
          label="Country"
          labelClassName={classes.label}
          childrenClassName={classes.formValueWrapper}
          className={classes.field}
        >
          <Typography variant="body2" className={classes.formValue}>
            {formatDisplay(form.fields.country.name)}
          </Typography>
        </FieldWrapper>
        <FieldWrapper
          label="Region"
          labelClassName={classes.label}
          childrenClassName={classes.formValueWrapper}
          className={classes.field}
        >
          <Typography variant="body2" className={classes.formValue}>
            {formatDisplay(form.fields.region.value)}
          </Typography>
        </FieldWrapper>
        <FieldWrapper
          label="City"
          labelClassName={classes.label}
          childrenClassName={classes.formValueWrapper}
          className={classes.field}
        >
          <Typography variant="body2" className={classes.formValue}>
            {formatDisplay(form.fields.city.name)}
          </Typography>
        </FieldWrapper>
        <FieldWrapper
          label="Office"
          labelClassName={classes.label}
          childrenClassName={classes.formValueWrapper}
          className={classes.field}
        >
          <Typography variant="body2" className={classes.formValue}>
            {formatDisplay(form.fields.officeAddress.value)}
          </Typography>
        </FieldWrapper>
        <FieldWrapper
          label="Job Title"
          labelClassName={classes.label}
          childrenClassName={classes.formValueWrapper}
          className={classes.field}
        >
          <Typography variant="body2" className={classes.formValue}>
            {formatDisplay(form.fields.jobTitle.name)}
          </Typography>
        </FieldWrapper>
        <FieldWrapper
          label="Team/Dept."
          labelClassName={classes.label}
          childrenClassName={classes.formValueWrapper}
          className={classes.field}
        >
          <Typography variant="body2" className={classes.formValue}>
            {formatDisplay(form.fields.teamName.value)}
          </Typography>
        </FieldWrapper>
        <FieldWrapper
          label="Line manager"
          labelClassName={classes.label}
          childrenClassName={classes.formValueWrapper}
          className={classes.field}
        >
          <Typography variant="body2" className={classes.formValue}>
            {formatDisplay(form.fields.lineManager.value)}
          </Typography>
        </FieldWrapper>
        <FieldWrapper
          label="Gender"
          labelClassName={classes.label}
          childrenClassName={classes.formValueWrapper}
          className={classes.field}
        >
          <Typography variant="body2" className={classes.formValue}>
            {formatDisplay(form.fields.gender.name)}
          </Typography>
        </FieldWrapper>
        <FieldWrapper
          label="Job grade"
          labelClassName={classes.label}
          childrenClassName={classes.formValueWrapper}
          className={classes.field}
        >
          <Typography variant="body2" className={classes.formValue}>
            {formatDisplay(form.fields.jobGrade.name)}
          </Typography>
        </FieldWrapper>
        <FieldWrapper
          label="Job status"
          labelClassName={classes.label}
          childrenClassName={classes.formValueWrapper}
          className={classes.field}
        >
          <Typography variant="body2" className={classes.formValue}>
            {formatDisplay(form.fields.jobStatus.name)}
          </Typography>
        </FieldWrapper>
        <FieldWrapper
          label="Employment category"
          labelClassName={classes.label}
          childrenClassName={classes.formValueWrapper}
          className={classes.field}
        >
          <Typography variant="body2" className={classes.formValue}>
            {formatDisplay(form.fields.employmentCategory.name)}
          </Typography>
        </FieldWrapper>
      </Grid>
    </Grid>
  );
};

ProfileViewForm.propTypes = {
  form: PropTypes.object,
  justify: PropTypes.string
};

ProfileViewForm.defaultProps = {
  justify: 'center'
};

export default compose(observer)(ProfileViewForm);
