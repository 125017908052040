/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Material UI
import { Grid, Radio, RadioGroup, FormControlLabel } from '@material-ui/core';

// Utils
import { SEND_INVITATION_OPTIONS } from '@Utils/constans';

// Styles
import useStyles from './styles';

const SendInviteCheckbox = ({
  className,
  onChange,
  checked,
  disabled,
  label,
  expand,
  defaultValue
}) => {
  const classes = useStyles();
  const labelClassName = classnames(classes.radioLabel, {
    [classes.nowrap]: expand
  });

  return (
    <Grid
      container
      className={className}
      classes={{ root: classes.wrapperRoot }}
    >
      {!expand && <Grid item xs={3} />}
      <Grid item xs={9} classes={{ root: classes.padding }}>
        <RadioGroup
          onChange={onChange}
          value={checked}
          disabled={disabled}
          label={label}
          defaultValue={defaultValue}
        >
          <FormControlLabel
            control={<Radio classes={{ root: classes.radio }} />}
            value={SEND_INVITATION_OPTIONS.SEND}
            label="Send SMS Invitation now"
            classes={{ label: labelClassName }}
          />
          <FormControlLabel
            control={<Radio classes={{ root: classes.radio }} />}
            value={SEND_INVITATION_OPTIONS.DO_NOT_SEND}
            label="Do not send invitation"
            classes={{ label: labelClassName }}
          />
        </RadioGroup>
      </Grid>
    </Grid>
  );
};

SendInviteCheckbox.defaultProps = {
  disabled: false
};

SendInviteCheckbox.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  expand: PropTypes.bool,
  classname: PropTypes.string,
  defaultValue: PropTypes.string,
  className: PropTypes.string
};

export default SendInviteCheckbox;
