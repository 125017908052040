// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';

// Components
import { BlockWrapper } from '@CommonScene';
import ExportButton from '@CommonScene/ExportButton';

// Material Ui
import { Grid } from '@material-ui/core';

// Icons
import { Person } from '@Utils/constans/icons';

// Providers
import { RiskStatusProvider } from '@Providers/';

// Utils
import { compose } from '@Utils';
import { formatDate, getStatusText } from '@Utils/formatting';
import { getDashboardSortParams } from '@Utils/ag-grid';
import { getMyTeamPath } from '@Utils/constans/paths';

// styles
import './styles.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

export class RiskStatusTable extends Component {
  static propTypes = {
    dateRange: PropTypes.object,
    setFilterParams: PropTypes.func,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      columnDefs: [
        {
          headerName: 'Full Name',
          field: 'fullName',
          valueGetter: params => {
            return params.data &&
              params.data.profile &&
              params.data.profile.firstName &&
              params.data.profile.lastName
              ? `${params.data.profile.firstName} ${
                  params.data.profile.lastName
                }`
              : '-';
          },
          valueSetter: params => {
            return (
              params.data &&
              `${params.data.profile.firstName} ${params.data.profile.lastName}`
            );
          },
          sortable: true,
          colId: 'fullName',
          filter: 'agTextColumnFilter',
          resizable: true
        },
        {
          headerName: 'Risk Status',
          field: 'status',
          sortable: true,
          filter: 'agSetColumnFilter',
          filterParams: {
            values: ['Extremely high', 'High', 'Unknown', 'Quarantine', 'Low'],
            apply: true,
            newRowsAction: 'keep',
            closeOnApply: true
          },
          enableRowGroup: false,
          cellRendererFramework: params => {
            const color = this.getColor(params.value);
            const text = getStatusText(params.value);
            return (
              <>
                <span
                  className="statusDot"
                  style={{ backgroundColor: color }}
                />
                <span> {text}</span>
              </>
            );
          },
          resizable: true,
          colId: 'status'
        },
        {
          headerName: 'Team/Dept.',
          field: 'profile.teamName',
          sortable: true,
          filter: 'agTextColumnFilter',
          resizable: true,
          colId: 'teamName',
          valueGetter: params => {
            return params.data &&
              params.data.profile &&
              params.data.profile.teamName
              ? params.data.profile.teamName
              : '-';
          }
        },
        {
          headerName: 'Office',
          field: 'profile.officeAddress',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            apply: true,
            menuTabs: ['generalMenuTab', 'columnsMenuTab'],
            closeOnApply: true,
            filterOptions: ['equals'],
            suppressAndOrCondition: true
          },
          resizable: true,
          colId: 'officeAddress',
          valueGetter: params => {
            return params.data &&
              params.data.profile &&
              params.data.profile.officeAddress
              ? params.data.profile.officeAddress
              : '-';
          }
        },
        {
          headerName: 'Country',
          field: 'profile.address.city.country',
          sortable: true,
          filter: 'agTextColumnFilter',
          resizable: true,
          colId: 'address.city.country',
          valueGetter: params => {
            return params.data &&
              params.data.profile.address &&
              params.data.profile.address.city &&
              params.data.profile.address.city.country
              ? params.data.profile.address.city.country
              : '-';
          }
        },
        {
          headerName: 'Reported Date',
          field: 'date',
          sortable: true,
          valueGetter: params => {
            return params.data && params.data.date
              ? formatDate(params.data.date)
              : '-';
          },
          menuTabs: ['generalMenuTab', 'columnsMenuTab'],
          resizable: true,
          colId: 'date'
        }
      ],
      defaultColDef: {
        flex: 1,
        resizable: true,
        enableValue: true,
        enableRowGroup: false,
        enablePivot: true,
        sortable: true,
        filter: true,
        filterParams: {
          apply: true,
          closeOnApply: true,
          filterOptions: ['equals'],
          suppressAndOrCondition: true
        }
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressRowGroups: false
            }
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel'
          }
        ],
        defaultToolPanel: ''
      },
      allColumns: true,
      groupHeaderHeight: 75,
      pagination: true,
      paginationPageSize: 25,
      cacheBlockSize: 25,
      rowModelType: 'serverSide',
      maxBlocksInCache: 1,
      suppressExcelExport: true,
      suppressCsvExport: true,
      loadedData: []
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dateRange !== this.props.dateRange && this.gridApi) {
      this.gridApi.purgeServerSideCache();
      this.setState({ loadedData: [] });
    }
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
    params.api.setServerSideDatasource(this.serverSideDatasource());
  };

  clearAllFilter = () => {
    this.gridApi.setFilterModel({});
    this.gridApi.setSortModel({});
    this.setState({ loadedData: [] });
  };

  serverSideDatasource = () => {
    return {
      getRows: params => {
        RiskStatusProvider.getRiskStatusByPeople(
          this.getFetchParams(params)
        ).then(response => {
          this.setLoadedData(response.data);
          if (response.data.length > 0) {
            params.successCallback(response.data, response.pagination.total);
          } else {
            params.successCallback(response.data, 0);
            params.api.showNoRowsOverlay();
          }
        });
      }
    };
  };

  getFetchParams = params => {
    const sortParams = getDashboardSortParams(params.request.sortModel);
    const queryParams = {
      offset: params.request.startRow,
      limit: params.request.endRow - params.request.startRow,
      sort: sortParams || '',
      'risk.fromDate': this.props.dateRange.from,
      'risk.toDate': this.props.dateRange.to
    };
    const filterParams = this.getFilterParams(params.request.filterModel);
    return (
      Object.keys(queryParams)
        .map(key => `${key}=${queryParams[key]}`)
        .join('&') + filterParams
    );
  };

  getFilterParams = filterModel => {
    let filterParams = '';
    const filterObject = {};
    Object.keys(filterModel).forEach(item => {
      let filterKey = '';
      let filterValue = filterModel[item].filter;
      const statusValues = filterModel[item].values;
      switch (item) {
        case 'fullName':
          filterKey = 'profile.fullName';
          break;
        case 'status':
          if (statusValues && statusValues.length > 0) {
            filterValue = filterModel[item].values.map(value => {
              return this.getStatusValue(value);
            });
            filterKey = 'risk.statuses';
          }
          break;

        case 'address.city.country':
          filterKey = 'profile.country';
          break;
        case 'teamName':
          filterKey = 'profile.team';
          break;
        case 'officeAddress':
          filterKey = 'profile.office';
          break;

        default:
          break;
      }
      if (filterKey) {
        filterParams += `&${filterKey}=${filterValue}`;
        filterObject[filterKey] = filterValue;
      }
    });
    this.props.setFilterParams(filterObject);
    return filterParams;
  };

  getStatusValue = status => {
    switch (status) {
      case 'Extremely high':
        return 'EXTREME_RISK';
      case 'High':
        return 'HIGH_RISK';
      case 'Unknown':
        return 'UNKNOWN';
      case 'Quarantine':
        return 'QUARANTINE';
      case 'Low':
        return 'LOW_RISK';
      default:
        return status;
    }
  };

  getValidStatuses = () => {
    return [
      'EXTREME_RISK',
      'HIGH_RISK',
      'UNKNOWN',
      'QUARANTINE',
      'LOW_RISK',
      'Extremely high',
      'High',
      'Unknown',
      'Quarantine',
      'Low'
    ];
  };

  getColor = text => {
    switch (text) {
      case 'EXTREME_RISK':
        return '#FE4848';
      case 'HIGH_RISK':
        return '#FFA91E';
      case 'UNKNOWN':
        return '#E9E2F4';
      case 'QUARANTINE':
        return '#9B51E0';
      case 'LOW_RISK':
        return '#6FC960';
      default:
        return '#9B51E0';
    }
  };

  setLoadedData = data => {
    if (this.state.loadedData.length > 0) {
      const allreadyLoadedData = [...this.state.loadedData];
      data.forEach(newItem => {
        const exists = allreadyLoadedData.find(
          item => item.profile.email === newItem.profile.email
        );

        if (!exists) {
          allreadyLoadedData.push(newItem);
        }
      });
      this.setState({ loadedData: allreadyLoadedData });
    } else {
      this.setState({ loadedData: data });
    }
  };

  getCSVData = data => {
    return data.map(item => {
      return {
        'Full Name': `${item.profile.firstName} ${item.profile.lastName}`,
        'Risk Status': item.status,
        'Team/Dept.': item.profile && item.profile.teamName,
        Office: item.profile && item.profile.officeAddress,
        Country: item.profile.address && item.profile.address.city.country,
        'Reported Date': formatDate(item.date, 'MM/DD/YYYY')
      };
    });
  };

  render() {
    const { history } = this.props;
    const { loadedData } = this.state;

    return (
      <>
        <BlockWrapper>
          <div className="ag-theme-alpine">
            <div className="agTableHeaderContent">
              <Person />
              <h1 className="agTableHeader">COVID-19 Risk Status Details</h1>
              <button
                className="clear-all-dashboard"
                onClick={this.clearAllFilter}
              >
                Clear Filter
              </button>
            </div>
            {loadedData.length > 0 && (
              <div className="export-btn-risk-dashboard">
                <ExportButton
                  count={loadedData.length}
                  jsonData={this.getCSVData(loadedData)}
                />
              </div>
            )}
          </div>
          <Grid container className="chartWrapper">
            <div className="ag-theme-alpine" style={{ width: '100%' }}>
              <br />
              <AgGridReact
                onGridReady={this.onGridReady}
                pagination={this.state.pagination}
                paginationPageSize={this.state.paginationPageSize}
                cacheBlockSize={this.state.cacheBlockSize}
                suppressRowClickSelection
                suppressCellSelection
                rowStyle={{ cursor: 'pointer' }}
                frameworkComponents={this.state.frameworkComponents}
                columnDefs={this.state.columnDefs}
                defaultColDef={this.state.defaultColDef}
                rowSelection={this.state.rowSelection}
                sideBar={this.state.sideBar}
                groupHeaderHeight={this.state.groupHeaderHeight}
                allColumns={this.state.allColumns}
                rowModelType={this.state.rowModelType}
                maxBlocksInCache={this.state.maxBlocksInCache}
                suppressExcelExport={this.state.suppressExcelExport}
                suppressCsvExport={this.state.suppressCsvExport}
                onFilterChanged={() => this.setState({ loadedData: [] })}
                onRowClicked={params => {
                  if (params.data && params.event.target.textContent !== '') {
                    history.push(
                      `${getMyTeamPath.teamMember()}/${
                        params.data.profile.phone
                      }?backLinkKey=riskStatus`
                    );
                  }
                }}
              />
            </div>
          </Grid>
        </BlockWrapper>
      </>
    );
  }
}

export default compose(observer)(RiskStatusTable);
