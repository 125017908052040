// Core
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
// Material UI
import { Grid } from '@material-ui/core';
// Utils
import { compose } from '@Utils/index';
import { useStore } from '@Utils/hooks';
// Components
import { SelectOptions, TextField } from '@CommonScene/index';
import FieldWrapper from '@CommonScene/FieldWrapper';

import useStyles from './styles';

const SupportForm = ({ form }) => {
  const classes = useStyles();
  const {
    SupportTeamStore: { onFieldChange }
  } = useStore();

  const handleOnChange = e => {
    const { name, value } = e.target;
    onFieldChange(name, value, 'createSupportForm');
  };

  return (
    <Grid container>
      <SelectOptions
        className={classes.marginBottom16}
        name="topic"
        label="Topic"
        items={form.fields.topic.items}
        inputProps={{ maxLength: 40 }}
        value={form.fields.topic.value}
        error={form.fields.topic.error}
        onChange={handleOnChange}
      />
      <FieldWrapper label="Your name" className={classes.marginBottom16}>
        <TextField
          name="name"
          fullWidth
          inputProps={{ maxLength: 40 }}
          value={form.fields.name.value}
          error={form.fields.name.error}
          onChange={handleOnChange}
        />
      </FieldWrapper>
      <FieldWrapper label="Contact e-mail" className={classes.marginBottom16}>
        <TextField
          name="email"
          type="email"
          fullWidth
          inputProps={{ maxLength: 40 }}
          value={form.fields.email.value}
          error={form.fields.email.error}
          onChange={handleOnChange}
        />
      </FieldWrapper>
      <FieldWrapper
        label="Describe your issue"
        alignItems="flex-start"
        className={classes.marginBottom16}
      >
        <TextField
          name="description"
          multiline
          rows={7}
          fullWidth
          value={form.fields.description.value}
          error={form.fields.description.error}
          onChange={handleOnChange}
          className={classes.marginBottom16}
        />
      </FieldWrapper>
    </Grid>
  );
};

SupportForm.propTypes = {
  form: PropTypes.object.isRequired
};

export default compose(observer)(SupportForm);
