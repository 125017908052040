/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import moment from 'moment';

// Utils
import { compose } from '@Utils/index';
import { formatDate } from '@Utils/formatting';

// Providers
import { VaultProvider } from '@Providers';

// Components
import StatusChip from '@CommonScene/materialUIComponents/statusChip';
import LogActions from '../LogActions';

// styles
import './styles.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

export class ImportLogTableWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: [
        {
          headerName: 'File Name',
          field: 'inputFile.fileName',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: { apply: true, newRowsAction: 'keep' },
          resizable: true,
          colId: 'fileName',
          width: 160,
          enableRowGroup: false,
          valueGetter: params => {
            return params.data &&
              params.data.inputFile &&
              params.data.inputFile.fileName
              ? params.data.inputFile.fileName
              : '-';
          }
        },
        {
          headerName: 'Successful',
          field: 'processingStatistics.validRows',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: { apply: true, newRowsAction: 'keep' },
          resizable: true,
          colId: 'validRows',
          enableRowGroup: false,
          width: 140,
          valueGetter: params => {
            return params.data &&
              params.data.processingStatistics &&
              typeof params.data.processingStatistics.validRows !== 'undefined'
              ? params.data.processingStatistics.validRows
              : '-';
          }
        },
        {
          headerName: 'Failed',
          field: 'processingStatistics.invalidRows',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: { apply: true, newRowsAction: 'keep' },
          resizable: true,
          colId: 'invalidRows',
          enableRowGroup: false,
          width: 100,
          valueGetter: params => {
            return params.data &&
              params.data.processingStatistics &&
              typeof params.data.processingStatistics.invalidRows !==
                'undefined'
              ? params.data.processingStatistics.invalidRows
              : '-';
          }
        },
        {
          headerName: 'Upload Status',
          field: 'currentStatus',
          cellRenderer: 'statusChipRenderer',
          cellRendererParams: params => {
            return {
              text: params.data ? params.data.currentStatus : params.value
            };
          },
          colId: 'currentStatus',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: { apply: true, newRowsAction: 'keep' },
          resizable: true,
          width: 150,
          keyCreator(params) {
            return params.value;
          }
        },
        {
          headerName: 'Upload Start',
          width: 155,
          field: 'startedAt',
          keyCreator(params) {
            return formatDate(params.value, 'MM/DD/YYYY');
          },
          valueGetter: params => {
            return params.data &&
              params.data.statusHistory &&
              params.data.statusHistory[0]
              ? formatDate(params.data.statusHistory[0].statusAt)
              : '-';
          },
          filter: 'agDateColumnFilter',
          filterParams: {
            comparator(filterLocalDateAtMidnight, cellValue) {
              const dateValue = formatDate(cellValue);
              const dateAsString = moment(dateValue).format('DD/MM/YYYY');
              if (dateAsString === null) {
                return -1;
              }
              const dateParts = dateAsString.split('/');
              const cellDate = new Date(
                Number(dateParts[2]),
                Number(dateParts[1]) - 1,
                Number(dateParts[0])
              );

              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              }
              if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              }
              return 0;
            },
            browserDatePicker: true,
            apply: true,
            newRowsAction: 'keep'
          },
          resizable: true,
          colId: 'uploadStart'
        },
        {
          headerName: 'Upload End',
          width: 155,
          field: 'finishedAt',
          keyCreator(params) {
            return formatDate(params.value, 'MM/DD/YYYY');
          },
          valueGetter: params => {
            return params.data &&
              params.data.statusHistory &&
              params.data.statusHistory[1]
              ? formatDate(params.data.statusHistory[1].statusAt)
              : '-';
          },
          filter: 'agDateColumnFilter',
          filterParams: {
            comparator(filterLocalDateAtMidnight, cellValue) {
              const dateValue = formatDate(cellValue);
              const dateAsString = moment(dateValue).format('DD/MM/YYYY');
              if (dateAsString === null) {
                return -1;
              }
              const dateParts = dateAsString.split('/');
              const cellDate = new Date(
                Number(dateParts[2]),
                Number(dateParts[1]) - 1,
                Number(dateParts[0])
              );

              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              }
              if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              }
              return 0;
            },
            browserDatePicker: true,
            apply: true,
            newRowsAction: 'keep'
          },
          resizable: true,
          colId: 'uploadEnd'
        },
        {
          headerName: 'Processing Report',
          cellRenderer: 'actionsRenderer',
          pinned: 'right',
          cellRendererParams: params => {
            return (
              params.data && {
                rowId: params.data.outputFile,
                currentStatus: params.data.currentStatus,
                fileName: params.data.inputFile.fileName
              }
            );
          },
          enableRowGroup: false
        }
      ],
      rowData: [],
      frameworkComponents: {
        statusChipRenderer: StatusChip,
        actionsRenderer: LogActions
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressRowGroups: false
            }
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel'
          }
        ],
        defaultToolPanel: ''
      },
      multiSortKey: 'ctrl',
      paginationPageSize: 10,
      pagination: true,
      cacheBlockSize: 10,
      maxBlocksInCache: 1,
      rowModelType: 'serverSide',
      suppressExcelExport: true,
      suppressCsvExport: true,
      defaultColDef: {
        flex: 1,
        minWidth: 100,
        resizable: true,
        enableValue: true,
        enableRowGroup: true,
        enablePivot: true,
        sortable: true,
        filter: true,
        fontSize: 14
      }
    };
  }

  static propTypes = {
    ImportLogStore: PropTypes.object.isRequired,
    MyTeamStore: PropTypes.object.isRequired,
    CommonStore: PropTypes.object.isRequired
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
    params.api.setServerSideDatasource(this.serverSideDatasource());
  };

  componentDidMount() {
    const {
      MyTeamStore: { getLatestUploadStatus }
    } = this.props;
    getLatestUploadStatus();
  }

  clearAllFilter = () => {
    const AllFilter = [
      'validRows',
      'invalidRows',
      'currentStatus',
      'uploadStart',
      'uploadEnd',
      'fileName'
    ];
    AllFilter.forEach(value => {
      const clearAll = this.gridApi.getFilterInstance(value);
      clearAll.setModel(null);
      this.gridApi.onFilterChanged();
    });
  };

  componentWillUnmount() {
    const { resetList, resetOffset } = this.props.ImportLogStore;
    resetOffset();
    resetList();
    clearInterval(this.timerRef);
  }

  serverSideDatasource = () => {
    return {
      getRows: params => {
        VaultProvider.getImportLogs(this.getFetchParams(params)).then(
          response => {
            if (response.data.length > 0) {
              params.successCallback(response.data, response.pagination.total);
            } else {
              params.successCallback(response.data, 0);
              params.api.showNoRowsOverlay();
            }
          }
        );
        this.timerRef = setInterval(() => {
          const {
            MyTeamStore: { getLatestUploadStatus },
            CommonStore: { pending }
          } = this.props;
          getLatestUploadStatus();
          if (!pending) {
            VaultProvider.getImportLogs(
              this.getFetchParams(params, 'update')
            ).then(response => {
              if (response.data.length > 0) {
                params.successCallback(
                  response.data,
                  response.pagination.total
                );
                params.api.redrawRows();
              } else {
                params.successCallback(response.data, 0);
                params.api.redrawRows();
                params.api.showNoRowsOverlay();
              }
            });
          }
        }, 10000);
      }
    };
  };

  getFetchParams = params => {
    return {
      offset: params.request.startRow,
      limit: 10,
      sort: 'audit.updatedAt,desc',
      version: 1
    };
  };

  render() {
    return (
      <>
        <div className="filterAll">
          <button className="clear-all" onClick={this.clearAllFilter}>
            Clear Filter
          </button>
        </div>
        <div className="ag-theme-alpine" style={{ width: '100%' }}>
          <AgGridReact
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            frameworkComponents={this.state.frameworkComponents}
            sideBar={this.state.sideBar}
            onGridReady={this.onGridReady}
            paginationPageSize={this.state.paginationPageSize}
            pagination={this.state.pagination}
            rowModelType={this.state.rowModelType}
            suppressExcelExport={this.state.suppressExcelExport}
            suppressCsvExport={this.state.suppressCsvExport}
            cacheBlockSize={this.state.cacheBlockSize}
            maxBlocksInCache={this.state.maxBlocksInCache}
          />
        </div>
      </>
    );
  }
}

export default compose(
  inject('ImportLogStore', 'CommonStore', 'MyTeamStore'),
  observer
)(ImportLogTableWrapper);
