/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import { action, observable } from 'mobx';

// Stores
import ValidationStore from '@Stores/ValidationStore';

// Providers
import {
  VaultProvider,
  WalletsProvider,
  InvitationProvider,
  NpsProvider
} from '@Providers';

// Stores
import CommonStore from '@Stores/CommonStore';

// Utils
import { AdminsForms as forms } from '@Assets/config/forms/admins';
import { getParsedCookies } from '@Utils/formatting';
import { generateGUID, getResourcePayload } from '@Utils';
import { generateEventBody, getSignedEvent } from '@Utils/blockchain';

class AdminsStore extends ValidationStore {
  @observable forms = forms;

  @observable offset = 0;

  @observable limit = 50;

  @observable lastPage = false;

  @observable hasNextPage = true;

  admins = observable.array([]);

  @observable checkedAllRows = false;

  @observable checkedCount = 0;

  @observable checkedPendingExpiredCount = 0;

  @observable checkedNewStatusCount = 0;

  @action('AdminsStore => clearForms')
  clearForms = () => {
    this.forms = forms;
  };

  @action('AdminsStore => resetOffset') resetOffset = () => {
    this.offset = 0;
  };

  @action('MyTeamStore => _setAdminsList')
  _setAdminsList = response => {
    this.lastPage = !response.pagination.hasNext;

    if (this.offset === 0) {
      this.admins.replace(response.data);
      return;
    }

    const admins = [...this.admins, ...response.data];
    this.admins.replace(admins);
  };

  @action('AdminsStore =>reachedLastPage') reachedLastPage = () => {
    this.hasNextPage = false;
  };

  @action('AdminsStore => getAdmins')
  getAdmins = () => {
    CommonStore.setPending();
    const param = `offset=${this.offset}&limit=${this.limit}`;
    return VaultProvider.getAdminsProfiles(param).then(profilesResponse => {
      const profiles = profilesResponse.data;
      if (profilesResponse.pagination.hasNext === false) {
        this.reachedLastPage();
      }
      const requests = profiles.map(profile => {
        return InvitationProvider.getParticipantList({
          q: profile.phone
        }).then(participantsResponse => {
          const content = participantsResponse.content[0] || {};
          return {
            ...profile,
            status: content.status,
            status_updated_at: content.last_updated,
            role_id: content.role && content.role.id,
            id: content.id
          };
        });
      });
      Promise.all(requests)
        .then(mappedMembers => {
          this._setAdminsList({ ...profilesResponse, data: mappedMembers });
        })
        .finally(CommonStore.clearPending);
    });
  };

  @action('AdminsStore => resetAdminsList')
  resetAdminsList = () => {
    this.resetOffset();
    this.admins.replace([]);
  };

  @action('AdminsStore => changeOffset') changeOffset = () => {
    this.offset = this.offset + this.limit;
  };

  @action('AdminsStore => createNewAdmin')
  createNewAdmin = data => {
    const tenantId = getParsedCookies().tenant;
    const inviteNow = data.sendCheckbox.value === 'sendSms';

    const formData = Object.keys(data).reduce((acc, key) => {
      if (data[key].value) {
        if (key === 'phone') {
          acc[key] = `+${data[key].value}`;
        } else {
          acc[key] = data[key].value;
        }
      }
      return acc;
    }, {});

    const payload = {
      guid: generateGUID(),
      inviteNow,
      employerProfile: formData
    };

    return WalletsProvider.getNode(tenantId).then(nodeResponse => {
      WalletsProvider.getPlatformProperties().then(
        platformPropertiesReponse => {
          delete payload.employerProfile.send_sms;
          const event = generateEventBody({
            eventCode: 'EMPLOYER.PROFILE.LOADED',
            payload,
            scAddress: nodeResponse.smart_contract_address,
            tenant: tenantId
          });

          WalletsProvider.sendEvent(
            getSignedEvent(
              event,
              platformPropertiesReponse.event_ledger_address
            )
          );
        }
      );
    });
  };

  _baseInvite = ({ rowId, method }) => {
    CommonStore.setPending();
    return InvitationProvider.getParticipantList({
      q: rowId
    }).then(participantsResponse => {
      const content = participantsResponse.content[0] || {};
      const currentAdmin = {
        ...this.admins.find(admin => rowId === admin.phone),
        role_id: content.role && content.role.id,
        id: content.id
      };

      let payload = {};

      if (method === 'inviteUser') {
        payload = [
          {
            identity: currentAdmin.phone,
            identity_type: 'PHONE',
            role_id: currentAdmin.role_id,
            send_notification: true
          }
        ];
      } else if (method === 'resendInvitation') {
        payload = { participants: [currentAdmin.id] };
      } else {
        payload = { ...payload, role: currentAdmin.role_id };
      }

      return NpsProvider[method](payload).finally(CommonStore.clearPending);
    });
  };

  resendInvitation = rowId => {
    return this._baseInvite({
      rowId,
      method: 'resendInvitation'
    });
  };

  revokeInvitation = rowId => {
    return this._baseInvite({
      rowId,
      method: 'revokeInvitation'
    });
  };

  inviteUser = rowId => {
    return this._baseInvite({
      rowId,
      method: 'inviteUser'
    });
  };

  @action('AdminsStore => toggleAllTableRowChecked')
  toggleAllTableRowChecked = () => {
    this.admins.forEach((admin, index) => {
      this.admins[index].checked = !this.checkedAllRows;
    });
    this.checkedAllRows = !this.checkedAllRows;
    this.checkedCount = this.admins.filter(admin => admin.checked).length;
    this.checkedPendingExpiredCount = this.admins.filter(
      admin =>
        admin.checked &&
        (admin.status === 'PENDING' || admin.status === 'EXPIRED')
    ).length;
    this.checkedNewStatusCount = this.admins.filter(
      admin => admin.checked && admin.status === 'NEW'
    ).length;
  };

  @action('AdminsStore => toggleTableRowChecked')
  toggleTableRowChecked = rowId => {
    this.admins.forEach((admin, index) => {
      if (rowId === admin.phone) {
        this.admins[index].checked = !admin.checked;
      }
    });
    this.checkedAllRows = this.admins.every(admin => admin.checked);
    this.checkedCount = this.admins.filter(admin => admin.checked).length;
    this.checkedPendingExpiredCount = this.admins.filter(
      admin =>
        admin.checked &&
        (admin.status === 'PENDING' || admin.status === 'EXPIRED')
    ).length;
    this.checkedNewStatusCount = this.admins.filter(
      admin => admin.checked && admin.status === 'NEW'
    ).length;
  };

  @action('AdminsStore => validateUniqueField')
  validateUniqueField = (field, value, phone) => {
    const fieldName = `${field}[eq]`;
    const params = {
      version: 1
    };
    params[fieldName] = value;
    return VaultProvider.getAdminsProfiles(params).then(profilesResponse => {
      if (profilesResponse.data != null && profilesResponse.data.length > 0) {
        if (
          !phone ||
          profilesResponse.data.some(profile => profile.phone !== phone)
        ) {
          const fieldStr = field === 'email' ? 'e-mail' : field;
          this.updateFieldProp(
            field,
            'error',
            `Employer with this ${fieldStr} already invited.`
          );
        }
      } else {
        this.updateFieldProp(field, 'error', null);
      }
      return null;
    });
  };

  @action('AdminsStore => updateFieldProp')
  updateFieldProp = (field, propName, value) => {
    if (this.forms.newAdminForm.fields[field]) {
      this.forms.newAdminForm.fields[field][propName] = value;
    }
    return null;
  };

  @action('AdminsStore => setAdminData') setAdminData = members => {
    this.admins.replace(members);
  };

  setAdminProfile = data => {
    this.updateFieldProp('firstName', 'value', data.firstName);
    this.updateFieldProp('lastName', 'value', data.lastName);
    this.updateFieldProp('middleName', 'value', data.middleName);
    this.updateFieldProp('email', 'value', data.email);
    this.updateFieldProp('phone', 'value', data.phone);
    this.updateFieldProp('status', 'value', data.status);
  };

  @action('AdminsStore => loadAdminProfile')
  loadAdminProfile = phone => {
    CommonStore.setPending();
    const params = {
      version: getResourcePayload('EMPLOYER_PROFILE').audit.version,
      'phone[eq]': phone
    };
    return VaultProvider.getAdminsProfiles(params)
      .then(response => {
        const profileResponse = response;
        if (profileResponse.data != null && profileResponse.data.length > 0) {
          const data = profileResponse.data[0];
          this.setAdminProfile(data);
        }
      })
      .catch(err => console.log(err))
      .finally(CommonStore.clearPending);
  };

  @action('AdminsStore => updateAdminData')
  updateAdminData = data => {
    const formData = Object.keys(data).reduce((acc, key) => {
      if (data[key].value) {
        acc[key] = data[key].value;
      }
      return acc;
    }, {});

    const tenantId = getParsedCookies().tenant;
    const payload = {
      guid: generateGUID(),
      employerProfile: formData
    };
    return WalletsProvider.getNode(tenantId).then(nodeResponse => {
      WalletsProvider.getPlatformProperties().then(
        platformPropertiesReponse => {
          delete payload.employerProfile.sendCheckbox;
          delete payload.employerProfile.status;
          const event = generateEventBody({
            eventCode: 'EMPLOYER.PROFILE.LOADED',
            payload,
            scAddress: nodeResponse.smart_contract_address,
            tenant: tenantId
          });

          WalletsProvider.sendEvent(
            getSignedEvent(
              event,
              platformPropertiesReponse.event_ledger_address
            )
          );
        }
      );
    });
  };
}

export default new AdminsStore();
