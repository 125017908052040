/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Store

export const SupportForm = {
  createSupportForm: {
    fields: {
      name: {
        value: '',
        error: null,
        rule: 'required'
      },
      topic: {
        value: '',
        error: null,
        rule: 'required',
        items: [
          { name: 'Data load issues', id: 1 },
          { name: 'Request for employee file template', id: 2 },
          { name: 'Request employee data load service', id: 3 },
          { name: 'HAYFT cards support', id: 4 },
          { name: 'Reporting support or requests', id: 5 },
          { name: 'Platform access issues', id: 6 },
          { name: 'I want to provide general feedback', id: 7 },
          { name: 'Cancellation requestion', id: 8 },
          { name: 'Data Security/Data Privacy support', id: 9 },
          { name: 'Other support request', id: 10 }
        ]
      },
      email: {
        value: '',
        error: null,
        rule: 'required|email'
      },
      description: {
        value: '',
        error: null,
        rule: 'required'
      }
    },
    meta: {
      isValid: false,
      error: null
    }
  }
};
