/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Chart } from 'react-google-charts';

// Material UI
import { Grid, withStyles, CircularProgress, Box } from '@material-ui/core';

// Components
import { BlockWrapper } from '@CommonScene';

// Icons
import { ChartIcon } from '@Utils/constans/icons';

// Utils
import { compose } from '@Utils';

// Styles
import styles from './styles';

class BarChart extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
    title: PropTypes.string,
    chartType: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    nameKey: PropTypes.string.isRequired,
    valueKey: PropTypes.string.isRequired,
    pending: PropTypes.bool,
    colors: PropTypes.array
  };

  state = {
    isChartReady: false
  };

  render() {
    const {
      classes,
      data,
      title,
      chartType,
      width,
      height,
      pending,
      colors
    } = this.props;

    return (
      <BlockWrapper withBottomMargin title={title} Icon={ChartIcon}>
        <Grid container className={classes.chartWrapper}>
          {data.length ? (
            <Chart
              width={width}
              height={height}
              chartType={chartType}
              loader={<div>Loading Chart...</div>}
              data={
                chartType === 'BarChart' ? data : [['Date', 'Cases '], ...data]
              }
              options={{
                chartArea: { width: '50%', height: '80%' },
                vAxis: {
                  title: 'Question(s)',
                  textStyle: { fontSize: 12, color: '#2F0A6B', fontWeight: 600 }
                },
                colors,
                bar: { groupWidth: '80%' },
                isStacked: chartType === 'BarChart' ? 'percent' : '',
                legendTextStyle: { color: '#2F0A6B' },
                titleTextStyle: { color: '#2F0A6B' },
                fontName: 'Roboto'
              }}
            />
          ) : (
            <Box display="flex">
              <Box m="auto">
                {pending ? <CircularProgress /> : <p>No data</p>}
              </Box>
            </Box>
          )}
        </Grid>
      </BlockWrapper>
    );
  }
}

export default compose(
  withStyles(styles),
  observer
)(BarChart);
