/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withRouter, Switch, Route } from 'react-router-dom';

// Material UI
import { Grid, withStyles } from '@material-ui/core';

// Components
import { MainContentWrapper, BlockWrapper } from '@CommonScene';
import IndividualForm from '@MyTeamScene/scenes/NewTeamMember/container/IndividualForm';
import BulkUploadForm from '@MyTeamScene/scenes/NewTeamMember/container/BulkUploadForm';

// Utils
import { compose } from '@Utils';
import { getMyTeamPath } from '@Utils/constans/paths';

// Styles
import styles from './styles';

export class NewTeamMember extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
  };

  getHeaderOptions() {
    return {
      title: 'Add Team Member'
    };
  }

  getTabsOptions() {
    return {
      tabs: [
        {
          title: 'In Bulk',
          isActive: this.props.location.pathname.includes(
            getMyTeamPath.newTeamBulk()
          ),
          onClick: () => {
            this.props.history.push({
              pathname: getMyTeamPath.newTeamBulk()
            });
          }
        },
        {
          title: 'Individual',
          isActive: this.props.location.pathname.includes(
            getMyTeamPath.newTeamIndividual()
          ),
          onClick: () => {
            this.props.history.push({
              pathname: getMyTeamPath.newTeamIndividual()
            });
          }
        }
      ]
    };
  }

  render() {
    const { classes } = this.props;

    return (
      <MainContentWrapper
        headerOptions={this.getHeaderOptions()}
        tabsOptions={this.getTabsOptions()}
      >
        <BlockWrapper className={classes.blockWrapper}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item>
              <img
                src="/public/img/Add-team-member_3x.png"
                className={classes.image}
              />
            </Grid>
          </Grid>
          <Switch>
            <Route
              path={getMyTeamPath.newTeamBulk()}
              exact
              component={BulkUploadForm}
            />
            <Route
              path={getMyTeamPath.newTeamIndividual()}
              exact
              component={IndividualForm}
            />
          </Switch>
        </BlockWrapper>
      </MainContentWrapper>
    );
  }
}

export default compose(
  withStyles(styles),
  withRouter,
  observer
)(NewTeamMember);
