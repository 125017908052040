/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import shortid from 'shortid';
import classnames from 'classnames';

// Material UI
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography
} from '@material-ui/core';
// Utils
import { compose } from '@Utils';
import { CheckedCircle, UncheckedCircle } from '@Utils/constans/icons';

// Styles
import useStyles from './styles';

const Cards = ({ data, activeCards, setActiveCardIndex }) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.wrapper} spacing={2}>
      <Grid item xs={12}>
        <Grid container justify="center" spacing={4}>
          {data.map((card, i) => (
            <Grid key={card} item>
              <Card
                className={classnames(
                  classes.card,
                  activeCards.includes(i) ? classes.activeCard : null
                )}
              >
                <CardActionArea onClick={() => setActiveCardIndex(i)}>
                  <CardMedia
                    className={classes.media}
                    image={card.imageUrl}
                    title={card.icon}
                  />
                  <CardContent className={classes.cardBody}>
                    <Typography className={classes.title}>
                      {activeCards.includes(i) ? (
                        <CheckedCircle />
                      ) : (
                        <UncheckedCircle />
                      )}
                      {card.description}
                    </Typography>
                    <ul>
                      {card.questions.map(question => (
                        <li key={shortid.generate()}>
                          {question.questionText}
                        </li>
                      ))}
                    </ul>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

Cards.propTypes = {
  data: PropTypes.array,
  activeCards: PropTypes.array,
  setActiveCardIndex: PropTypes.func
};

Cards.defaultProps = {
  data: [],
  activeCardIndex: 0
};

export default compose(observer)(Cards);
