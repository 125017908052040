// Core
import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';
import { Chart } from 'react-google-charts';

// Material UI
import { Grid, withStyles, Box, CircularProgress } from '@material-ui/core';

// Components
import { BlockWrapper } from '@CommonScene';

// Icons
import { ChartIcon } from '@Utils/constans/icons';

// Utils
import { compose } from '@Utils';

// Styles
import styles from './styles';

class ColumnChart extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
    title: PropTypes.string,
    colors: PropTypes.array,
    CommonStore: PropTypes.object.isRequired
  };

  state = {
    isChartReady: false
  };

  render() {
    const { classes, data, title, colors } = this.props;
    const {
      CommonStore: { pending }
    } = this.props;

    return (
      <BlockWrapper withBottomMargin title={title} Icon={ChartIcon}>
        <Grid container className={classes.chartWrapper}>
          {/* Received data is in the form [[Heading],[value1], [value2]...] so to check if values exist data.length >1 is used. */}
          {data.length > 1 ? (
            <Chart
              width={'100%'}
              height={'300px'}
              chartType="ColumnChart"
              loader={<div>Loading Chart</div>}
              data={data}
              options={{
                colors,
                legend: {
                  position: 'bottom'
                },
                vAxis: {
                  title: 'Volume',
                  titleTextStyle: { color: '#2F0A6B' },
                  textStyle: { fontSize: 12, color: '#2F0A6B', fontWeight: 600 }
                },
                hAxis: {
                  textStyle: { fontSize: 10, color: '#2F0A6B', fontWeight: 600 }
                },
                legendTextStyle: { color: '#2F0A6B' },
                chartArea: { width: '80%', height: '50%' },
                isStacked: true,
                bar: { groupWidth: '80%' },
                fontName: 'Roboto'
              }}
            />
          ) : (
            <Box display="flex">
              <Box m="auto">
                {pending ? <CircularProgress /> : <p>No data</p>}
              </Box>
            </Box>
          )}
        </Grid>
      </BlockWrapper>
    );
  }
}

export default compose(
  inject('CommonStore'),
  withStyles(styles),
  observer
)(ColumnChart);
