// Core
import { action, observable } from 'mobx';
// Stores
import ValidationStore from '@Stores/ValidationStore';
import CommonStore from '@Stores/CommonStore';
// Providers
import { StaticContentProvider, VaultProvider } from '@Providers';
import { debounce } from 'lodash';
import { DELAY_REQUEST } from '@Utils/constans';

// @Utils

class ReportedTripsStore extends ValidationStore {
  @observable activeTab = 'past';

  @observable offset = 0;

  @observable limit = 1000;

  @observable lastPage = false;

  @observable loading = false;

  @observable query = '';

  @observable loadedDataCount = 0;

  trips = observable.array([]);

  temp = observable.array([]);

  countryFlags = observable.array([]);

  @action('ReportedTripsStore => getTrips')
  getTrips = () => {
    this.updateLoading(true);
    const queryParams = {
      sort: 'trip.statusAt,desc',
      limit: this.limit,
      offset: this.offset
    };
    return VaultProvider.getTravelReport(queryParams).then(response => {
      this.loadCountryFlags(response.data).then(() => {
        this._setUpcomingTripList(response);
      });
    });
  };

  @action('ReportedTripsStore => loadCountryFlags')
  loadCountryFlags = trips => {
    const icons = [...trips].map(data => data.trip.details.city.icon);
    const iconSet = [...new Set(icons)];
    const iconSetRequest = iconSet.map(icon => {
      const iconArr = icon.split('/');
      return StaticContentProvider.getStaticContent(iconArr[0], iconArr[1])
        .then(image => {
          const urlCreator = window.URL || window.webkitURL;
          const imageUrl = urlCreator.createObjectURL(image);
          return {
            id: icon,
            imageUrl
          };
        })
        .catch(error => {
          console.log(error);
          return {
            id: icon,
            imageUrl: null
          };
        });
    });
    return Promise.all(iconSetRequest).then(response => {
      this.updateCountryFlags(response);
    });
  };

  @action('ReportedTripsStore => updateCountryFlags')
  updateCountryFlags = countryFlags => {
    this.countryFlags = countryFlags;
  };

  @action('ReportedTripsStore => getCountryFlagsById')
  getCountryFlagsById = id => {
    const countryFlag = this.countryFlags.find(flag => flag.id === id);
    return countryFlag ? countryFlag.imageUrl : '';
  };

  @action('ReportedTripsStore => updateLoading')
  updateLoading = loading => {
    if (loading) {
      CommonStore.setPending();
    } else {
      CommonStore.clearPending();
    }
    this.loading = loading;
  };

  @action('ReportedTripsStore => _setTripList')
  _setTripList = response => {
    this.lastPage = !response.pagination.hasNext;

    if (this.offset === 0) {
      this.trips.replace(response.data);
      return;
    }

    const trips = [...this.trips, ...response.data];
    this.trips.replace(trips);
  };

  @action('ReportedTripsStore => _setUpcomingTripList')
  _setUpcomingTripList = response => {
    this.lastPage = !response.pagination.hasNext;

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const tomorrow = new Date();
    tomorrow.setHours(0, 0, 0, 0);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const upcomingTrips = [...response.data].filter(data => {
      const fromDate = new Date(data.trip.details.from);
      const toDate = !data.trip.details.to
        ? null
        : new Date(data.trip.details.to);
      const isPastTrip = fromDate < today && (!toDate || toDate < tomorrow);
      if (this.activeTab === 'upcoming') {
        return !isPastTrip;
      }
      return isPastTrip;
    });
    this.temp = [...this.temp, ...upcomingTrips];
    if (!this.lastPage) {
      this.changeOffset();
      this.getTrips();
      this.trips.replace(this.temp);
    } else {
      this.trips.replace(this.temp);
      this.updateLoading(false);
    }
  };

  @action('ReportedTripsStore => updateActiveTab') updateActiveTab = tab => {
    this.activeTab = tab;
  };

  @action('ReportedTripsStore => resetTripList')
  resetTripList = () => {
    this.resetOffset();
    this.trips.replace([]);
    this.temp = [];
  };

  @action('ReportedTripsStore => setQuery') setQuery = query => {
    this.resetOffset();
    this.query = query;
    this.search();
  };

  @action('ReportedTripsStore => resetOffset') resetOffset = () => {
    this.offset = 0;
  };

  @action('ReportedTripsStore => changeOffset') changeOffset = () => {
    this.offset = this.offset + this.limit;
  };

  search = debounce(this.getTrips, DELAY_REQUEST);
}

export default new ReportedTripsStore();
