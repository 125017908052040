/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  wrapperRoot: {
    width: 450
  },
  uploadBox: {
    marginBottom: theme.spacing(1),
    minHeight: 74,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    backgroundColor: '#F8F6FA',
    cursor: 'pointer',
    border: '1px dashed #DCB8FD'
  },
  fileSelected: {
    backgroundImage: 'none',
    border: '1px solid #E9E2F4'
  },
  iconWrapper: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    background: '#E9E2F4',
    borderRadius: '50%'
  },
  label: {
    color: '#2F0A6B',
    maxWidth: 310
  },
  iconDone: {
    position: 'absolute',
    right: 0,
    top: 2
  },
  instructionWrapper: {
    color: '#A095AE'
  },
  instruction: {
    lineHeight: '14px'
  },
  error: {
    color: '#FE4848'
  },
  closeIcon: {
    fill: '#9B51E0'
  },
  fileNameContainer: {
    position: 'relative',
    margin: `0 ${theme.spacing(1)}px`
  },
  loader: {
    marginTop: theme.spacing(1)
  }
}));

export default useStyles;
