/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

// Utils
import { compose, generateGUID } from '@Utils';

// Material UI
import {
  Grid,
  withStyles,
  Typography,
  TextField,
  CircularProgress
} from '@material-ui/core';

// Components
import { BlockWrapper, MainContentWrapper } from '@CommonScene/index';
import { SecondaryButton } from '@CommonScene/materialUIComponents/PrimaryButton';
import { Company } from '@Utils/constans/icons';

// styles
import styles from './styles';

export class CompanySettings extends Component {
  state = {
    companyName: '',
    originalCompanyName: '',
    hasCompanyNameError: false,
    isNew: true,
    guid: '',
    loading: true
  };

  static propTypes = {
    CompanySettingsStore: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  componentDidMount() {
    const { getCompanyProfile } = this.props.CompanySettingsStore;
    const query = {
      version: 1
    };

    const updateCompanyProfileState = response => {
      if (response.data != null && response.data.length > 0) {
        const companyProfile = response.data[0];
        const {
          companyName,
          guid,
          audit: { revision }
        } = companyProfile;
        this.setState({
          companyName,
          guid,
          revision,
          isNew: false,
          loading: false,
          originalCompanyName: companyName
        });
      }
      this.setState({ loading: false });
    };

    getCompanyProfile(query, updateCompanyProfileState);
  }

  getHeaderOptions() {
    return '';
  }

  handleSubmit = () => {
    const { isNew, guid, companyName, revision } = this.state;
    if (!companyName) {
      this.setState({ hasCompanyNameError: true });
    } else {
      const { history, CompanySettingsStore } = this.props;
      const { saveCompanyProfile, updateCompanyProfile } = CompanySettingsStore;
      const redirect = () => history.push('/my-team');
      const payload = {
        companyName,
        guid: guid || generateGUID(),
        revision
      };
      if (isNew) {
        saveCompanyProfile(payload, { version: 1 }, redirect);
      } else {
        updateCompanyProfile(guid, payload, 'version=1', redirect);
      }
    }
  };

  handleChange = event => {
    const { value } = event.target;
    const hasCompanyNameError = !value;
    this.setState({ companyName: value, hasCompanyNameError });
  };

  render() {
    const { classes, history } = this.props;
    const {
      loading,
      companyName,
      hasCompanyNameError,
      originalCompanyName
    } = this.state;
    return (
      <MainContentWrapper headerOptions={this.getHeaderOptions()}>
        <BlockWrapper>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item>
              <Company />
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            className={classes.contentWrapper}
          >
            <Grid item>
              <Typography className={classes.title}>
                Tell Us Your Company Name
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            className={classes.contentWrapper}
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
              >
                <Grid item xs={2} classes={{ root: classes.label }}>
                  Company name*
                </Grid>
                <Grid item xs={9} classes={{ root: classes.padding }}>
                  <TextField
                    name="company-name-input"
                    value={companyName}
                    onChange={this.handleChange}
                    fullWidth
                    disabled={loading}
                    error={hasCompanyNameError}
                    required
                    InputProps={{
                      classes: {
                        root: classes.inputRoot,
                        input: classes.input,
                        focused: classes.focused,
                        error: classes.error
                      }
                    }}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={2}
            className={classes.buttonsWrapper}
          >
            <Grid item>
              <SecondaryButton
                onClick={this.handleSubmit}
                disabled={
                  hasCompanyNameError ||
                  !companyName ||
                  companyName === originalCompanyName
                }
              >
                Save
              </SecondaryButton>
            </Grid>
            {companyName && (
              <Grid item>
                <SecondaryButton onClick={() => history.push('/my-team')}>
                  Skip
                </SecondaryButton>
              </Grid>
            )}
          </Grid>
        </BlockWrapper>
      </MainContentWrapper>
    );
  }
}

export default compose(
  withStyles(styles),
  inject('CompanySettingsStore'),
  withRouter,
  observer
)(CompanySettings);
