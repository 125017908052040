/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import shortid from 'shortid';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

// Configs
import { groupTableConfig } from '@Assets/config/tables/covid19';

// Utils
import { compose } from '@Utils';

// Material UI
import {
  Grid,
  Table,
  TableHead,
  TableBody,
  withStyles
} from '@material-ui/core';

// Components
import BlockWrapper from '@CommonScene/BlockWrapper';
import BodyRow from '@CommonScene/DataTable/BodyRow';
import TableHeaderRow from '@CommonScene/DataTable/HeaderRow';
import NoRecordsBlock from '@CommonScene/DataTable/NoRecordsBlock';

import styles from './styles';

export class CasesStatistics extends Component {
  state = {
    officeGroup: 'OFFICE',
    teamGroup: 'TEAM'
  };

  static propTypes = {
    classes: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired
  };

  componentDidMount() {
    const {
      store: { getCovidGroupData }
    } = this.props;
    const { officeGroup, teamGroup } = this.state;
    getCovidGroupData(officeGroup);
    getCovidGroupData(teamGroup);
  }

  componentWillUnmount() {}

  renderTable = (tableConfig, data, totalCount) => {
    const { classes } = this.props;
    const footerData = { label: 'Total', value: totalCount };
    const footerCellStyle = {
      fontSize: '14px',
      fontWeight: '500',
      borderBottom: '0px',
      paddingBottom: '0px',
      paddingLeft: '8px',
      paddingTop: '16px'
    };
    const footerRowCells = [
      {
        key: 'label',
        cb: value => value,
        style: footerCellStyle
      },
      {
        key: 'value',
        cb: value => value,
        style: {
          textAlign: 'right',
          paddingRight: '32px',
          ...footerCellStyle
        }
      }
    ];
    return (
      <BlockWrapper className={classes.tableWrapper}>
        <Table className={classes.table}>
          <TableHead>
            <TableHeaderRow id="header-row" headings={tableConfig.headings} />
          </TableHead>
        </Table>
        {data.length ? (
          <div className={classes.scrollDiv}>
            <Table style={{ tableLayout: 'fixed' }} className={classes.table}>
              <TableBody>
                {data.map(rowData => {
                  return (
                    <BodyRow
                      key={shortid.generate()}
                      rowData={rowData}
                      rowCells={tableConfig.rowCells}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </div>
        ) : null}
        {data.length ? (
          <Table className={classes.table}>
            <TableBody>
              <BodyRow rowData={footerData} rowCells={footerRowCells} />
            </TableBody>
          </Table>
        ) : null}
        {!data.length ? <NoRecordsBlock id="no-records-block" /> : null}
      </BlockWrapper>
    );
  };

  render() {
    const {
      store: { groupData },
      classes
    } = this.props;

    return (
      <Grid container className={classes.mainWrapper}>
        <Grid item xs={6} className={classes.officeBlock}>
          {this.renderTable(
            groupTableConfig('Office', groupData.office.data.length),
            groupData.office.data,
            groupData.office.totalCount
          )}
        </Grid>
        <Grid item xs={6} className={classes.regionBlock}>
          {this.renderTable(
            groupTableConfig('Team', groupData.team.data.length),
            groupData.team.data,
            groupData.team.totalCount
          )}
        </Grid>
      </Grid>
    );
  }
}

export default compose(
  withStyles(styles),
  observer
)(CasesStatistics);
