/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';

// Components
import { MainContentWrapper, DataTable } from '@CommonScene';
import Filter from '@StatisticsScene/scenes/NodeActivity/NodeActivityList/components/Filter';
import SummaryBlock from '@StatisticsScene/scenes/common/SummaryBlock';
import withDownloadButtonOptions from '@StatisticsScene/scenes/common/HOC';

// Utils
import { compose } from '@Utils';

// Configs
import { tableConfig } from '@Assets/config/tables/nodeActivity';

export class NodeActivityList extends Component {
  static propTypes = {
    getSpeedDialMenuOptions: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    BaseReportingStore: PropTypes.object.isRequired,
    NodeActivityReportStore: PropTypes.object.isRequired,
    AuthStore: PropTypes.object.isRequired,
    CommonStore: PropTypes.object.isRequired
  };

  getReport() {
    // TODO: Find a better name for method. A lot of 'getReport'
    const {
      NodeActivityReportStore: { getReport, nodeType, setPageParams, dateRange }
    } = this.props;
    setPageParams({
      limit: 25,
      skip: 0,
      types: [nodeType],
      roles: ['Employee'],
      ts_from: dateRange.from,
      ts_to: dateRange.to,
      activity_types: []
    });

    getReport('UTILIZATION_LVL_1').then(() => {
      const {
        NodeActivityReportStore: { setFilters }
      } = this.props;
      setFilters([
        'role',
        {
          name: 'activity_types',
          items: [
            'Event sent',
            { name: 'Event received', defaultValue: { checked: false } }
          ]
        }
      ]);
    });
  }

  componentDidMount() {
    this.getReport();
  }

  componentWillUnmount() {
    const {
      NodeActivityReportStore: { resetDetails }
    } = this.props;
    resetDetails();
  }

  getHeaderOptions = () => ({
    title: 'Team activity'
  });

  fetchTableData = () => {
    const {
      NodeActivityReportStore: {
        updatePageParams,
        pageParams,
        getReport,
        report,
        details
      }
    } = this.props;
    const totalItemsCount = report.aggregation.reduce(
      (acc, val) => Number(val.total) + acc,
      0
    );
    updatePageParams({
      skip: pageParams.skip + pageParams.limit,
      lastPage: totalItemsCount === details.length
      // roles: selectedFilters.role || [],
      // activity_types: selectedFilters.activity_types || []
    });
    if (totalItemsCount !== details.length) {
      getReport('UTILIZATION_LVL_1');
    }
  };

  render() {
    const { NodeActivityReportStore } = this.props;

    return (
      <MainContentWrapper
        headerOptions={this.getHeaderOptions()}
        speedDialMenuOptions={this.props.getSpeedDialMenuOptions({
          reportId: 'UTILIZATION_LVL_1',
          pageParams: NodeActivityReportStore.pageParams,
          details: NodeActivityReportStore.details,
          aggregation: NodeActivityReportStore.report.aggregation
        })}
      >
        <Filter />
        <SummaryBlock
          totalLabel="Total Activity Level"
          totalValueKey="activity"
          items={NodeActivityReportStore.report.aggregation.map(item => {
            return {
              total: item.total,
              activity: item.activity,
              status: item.status
            };
          })}
        />
        <DataTable
          tableConfig={tableConfig}
          tableData={NodeActivityReportStore.details}
          fetchTableData={this.fetchTableData}
          hasMoreData={!NodeActivityReportStore.pageParams.lastPage}
        />
      </MainContentWrapper>
    );
  }
}

export default compose(
  withTranslation(),
  inject(
    'BaseReportingStore',
    'NodeActivityReportStore',
    'AuthStore',
    'CommonStore'
  ),
  withDownloadButtonOptions,
  observer
)(NodeActivityList);
