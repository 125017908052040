/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import shortid from 'shortid';
import { withTranslation } from 'react-i18next';

import {
  TableRow,
  TableCell,
  TableSortLabel,
  withStyles,
  CircularProgress
} from '@material-ui/core';

// Components
import { Checkbox, CustomTooltip } from '@CommonScene';

// Utils
import { compose } from '@Utils';

// Styles
import styles from './styles';

export class HeaderRow extends Component {
  state = {
    selectionLoading: false
  };

  static propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    headings: PropTypes.array.isRequired,
    getSortableHeadingCell: PropTypes.func,
    withActions: PropTypes.bool,
    hasCheckbox: PropTypes.bool,
    checkedCheckbox: PropTypes.bool,
    onCheckboxChange: PropTypes.func,
    order: PropTypes.string,
    orderBy: PropTypes.string,
    handleSorting: PropTypes.func,
    dataLength: PropTypes.number
  };

  static defaultProps = {
    getSortableHeadingCell: () => {
      // Stub function
    },
    onCheckboxChange: () => {
      // stub function
    },
    withActions: false
  };

  getSortableHeadingCell(title, cellId, callBackFn) {
    const { order, orderBy, handleSorting, classes, t } = this.props;

    return (
      <CustomTooltip label={t('common.sort')} enterDelay={300}>
        <TableSortLabel
          classes={{ active: classes.sortLabel, icon: classes.sortIcon }}
          className="sort-label"
          active={orderBy === cellId}
          direction={order}
          onClick={() => handleSorting(cellId, callBackFn)}
        >
          {title}
        </TableSortLabel>
      </CustomTooltip>
    );
  }

  renderHeadingCellContent(heading) {
    if (heading.sortable) {
      return this.getSortableHeadingCell(heading.title, heading.id, heading.cb);
    }
    return heading.title ? heading.title : heading;
  }

  onCheckboxChange = () => {
    this.setState({ selectionLoading: true }, () => {
      setTimeout(() => {
        this.props.onCheckboxChange();
        this.setState({ selectionLoading: false });
      }, 300);
    });
  };

  render() {
    const {
      classes,
      order,
      orderBy,
      withActions,
      hasCheckbox,
      checkedCheckbox,
      headings,
      dataLength
    } = this.props;

    const checkboxCellContent = !this.state.selectionLoading ? (
      <Checkbox checked={checkedCheckbox} onChange={this.onCheckboxChange} />
    ) : (
      <CircularProgress color="inherit" size={20} />
    );

    return (
      <TableRow>
        {hasCheckbox ? (
          <TableCell className={classes.cell}>
            {dataLength ? checkboxCellContent : null}
          </TableCell>
        ) : null}
        {headings.map(heading => (
          <TableCell
            key={shortid.generate()}
            className={classes.cell}
            style={heading.style}
            sortDirection={
              orderBy === heading.id && dataLength > 1 ? order : false
            }
          >
            {this.renderHeadingCellContent(heading)}
          </TableCell>
        ))}
        {withActions ? <TableCell className={classes.cell} /> : null}
      </TableRow>
    );
  }
}

export default compose(
  withTranslation(),
  withStyles(styles),
  observer
)(HeaderRow);
