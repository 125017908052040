/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Utils
import { formatDate } from '@Utils/formatting';

import { translation } from '@Utils/translation';

// Stores
import MyTeamStore from '@Stores/MyTeamStore';

export const renderRolesNames = key => translation(key, 'rolesNames');

export const handleResendCode = ({ rowId }) => {
  MyTeamStore.resendInvitation(rowId);
  MyTeamStore.resetMembersList();
  MyTeamStore.getTeamMembers();
};

export const handleSendCode = ({ rowId }) => {
  MyTeamStore.sendInvitation(rowId);
  MyTeamStore.resetMembersList();
  MyTeamStore.getTeamMembers();
};

export const handleRevokeCode = ({ rowId }) => {
  MyTeamStore.revokeInvitation(rowId);
  MyTeamStore.resetMembersList();
  MyTeamStore.getTeamMembers();
};

// TODO: commented code not used in October release
export const tableConfig = {
  rowIdKey: 'phone',
  extraIdKey: 'phone',
  headings: [
    'Full name',
    'Working availability',
    'Team/Dept.',
    'Office',
    'Country',
    'Test Date',
    'Reported Date'
  ],
  rowCells: [
    'profile.fullName',
    'workAbility',
    'profile.teamName',
    'profile.officeAddress',
    'profile.address.city.country',
    { key: 'testDate', cb: formatDate },
    { key: 'statusAt', cb: formatDate }
  ]
};

export const groupTableConfig = (type, dataSize) => {
  return {
    rowIdKey: 'label',
    extraIdKey: 'label',
    headings: [
      {
        title: type,
        style: { paddingLeft: '8px', paddingBottom: '16px' }
      },
      {
        title: 'Cases',
        style: {
          textAlign: 'right',
          paddingRight: '32px',
          paddingBottom: '16px'
        }
      }
    ],
    rowCells: [
      {
        key: 'label',
        cb: value => value,
        style: {
          paddingLeft: '8px',
          paddingTop: '16px',
          paddingBottom: '16px'
        }
      },
      {
        key: 'value',
        cb: value => value,
        style: {
          textAlign: 'right',
          paddingRight: dataSize > 4 ? '15px' : '32px',
          paddingTop: '16px',
          paddingBottom: '16px'
        }
      }
    ]
  };
};
