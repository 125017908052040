/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

// Material UI
import { Grid, Typography, Button, withStyles } from '@material-ui/core';

// Utils
import classNames from 'classnames';
import { compose } from '@Utils';

// Styles
import styles from './styles';

const NotFoundPageLayout = ({ classes, t }) => (
  <Grid item xs={12} className={classes.wrapper}>
    <div className={classes.mainBody}>
      <div>
        <Typography
          variant="h3"
          component="h2"
          className={classNames(classes.text, classes.marginBottom)}
        >
          {t('notFound.heading')}
        </Typography>
        <Typography variant="h5" component="h3" className={classes.text}>
          {t('notFound.mainText')}
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          component={Link}
          to={'/my-team'}
        >
          {t('notFound.back')}
        </Button>
      </div>
    </div>
  </Grid>
);

NotFoundPageLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};

export default compose(
  withStyles(styles),
  withTranslation()
)(NotFoundPageLayout);
