/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import moment from 'moment';
import shortid from 'shortid';

// Components
import StatusChip from '@CommonScene/materialUIComponents/statusChip';
import Actions from '@MyTeamScene/scenes/MyTeamList/components/Actions';
import DropDownMenu from '@CommonScene/MainContentWrapper/components/ContainerHeader/components/DropDownMenu';
import { BlockWrapper, CustomTooltip } from '@CommonScene/';

// Providers
import { VaultProvider } from '@Providers';

// Utils
import { compose } from '@Utils';
import { formatDate, formatPhoneNumber } from '@Utils/formatting';
import { getFilterParams, getSortParams } from '@Utils/ag-grid';
import ExportButton from '@CommonScene/ExportButton';

import { getMyTeamPath } from '@Utils/constans/paths';
import IconActions from '../IconActions';

// styles
import './styles.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

let globalNewIds = [];
let globalPendingIds = [];
let globalExpiredIds = [];

export class TableWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: [
        {
          headerName: 'First Name',
          field: 'firstName',
          colId: 'firstName',
          checkboxSelection: true,
          headerCheckboxSelection: true,
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            apply: true,
            newRowsAction: 'keep',
            closeOnApply: true,
            filterOptions: ['contains', 'equals'],
            suppressAndOrCondition: true
          },
          resizable: true
        },
        {
          headerName: 'Last Name',
          field: 'lastName',
          colId: 'lastName',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            apply: true,
            newRowsAction: 'keep',
            closeOnApply: true,
            filterOptions: ['contains', 'equals'],
            suppressAndOrCondition: true
          },
          resizable: true
        },
        {
          headerName: 'Middle Name',
          field: 'middleName',
          colId: 'middleName',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            apply: true,
            newRowsAction: 'keep',
            closeOnApply: true,
            filterOptions: ['contains', 'equals'],
            suppressAndOrCondition: true
          },
          hide: true,
          resizable: true,
          valueGetter: params => {
            return params.data && params.data.middleName
              ? params.data.middleName
              : '-';
          }
        },
        {
          headerName: 'Title',
          field: 'title.name',
          colId: 'title',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            apply: true,
            newRowsAction: 'keep',
            closeOnApply: true,
            filterOptions: ['contains', 'equals'],
            suppressAndOrCondition: true
          },
          hide: true,
          resizable: true,
          valueGetter: params => {
            return params.data && params.data.title && params.data.title.name
              ? params.data.title.name
              : '-';
          }
        },
        {
          headerName: 'Employee Id',
          field: 'employeeId',
          colId: 'employeeId',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            apply: true,
            newRowsAction: 'keep',
            closeOnApply: true,
            filterOptions: ['contains', 'equals'],
            suppressAndOrCondition: true
          },
          hide: true,
          resizable: true,
          valueGetter: params => {
            return params.data && params.data.employeeId
              ? params.data.employeeId
              : '-';
          }
        },
        {
          headerName: 'Country',
          field: 'address.city.country',
          colId: 'address.city.country',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            apply: true,
            newRowsAction: 'keep',
            closeOnApply: true,
            filterOptions: ['contains', 'equals'],
            suppressAndOrCondition: true
          },
          hide: true,
          resizable: true,
          valueGetter: params => {
            return params.data &&
              params.data.address &&
              params.data.address.city &&
              params.data.address.city.country
              ? params.data.address.city.country
              : '-';
          }
        },
        {
          headerName: 'Region',
          field: 'address.city.region',
          colId: 'address.city.region',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            apply: true,
            newRowsAction: 'keep',
            closeOnApply: true,
            filterOptions: ['contains', 'equals'],
            suppressAndOrCondition: true
          },
          hide: true,
          resizable: true,
          valueGetter: params => {
            return params.data &&
              params.data.address &&
              params.data.address.city &&
              params.data.address.city.region
              ? params.data.address.city.region
              : '-';
          }
        },
        {
          headerName: 'City',
          field: 'address.city.name',
          colId: 'address.city.name',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            apply: true,
            newRowsAction: 'keep',
            closeOnApply: true,
            filterOptions: ['contains', 'equals'],
            suppressAndOrCondition: true
          },
          hide: true,
          resizable: true,
          valueGetter: params => {
            return params.data &&
              params.data.address &&
              params.data.address.city &&
              params.data.address.city.name
              ? params.data.address.city.name
              : '-';
          }
        },
        {
          headerName: 'Job Title',
          field: 'jobTitle.name',
          colId: 'jobTitle',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            apply: true,
            newRowsAction: 'keep',
            closeOnApply: true,
            filterOptions: ['contains', 'equals'],
            suppressAndOrCondition: true
          },
          hide: true,
          resizable: true,
          valueGetter: params => {
            return params.data &&
              params.data.jobTitle &&
              params.data.jobTitle.name
              ? params.data.jobTitle.name
              : '-';
          }
        },
        {
          headerName: 'Line Manager',
          field: 'lineManager',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            apply: true,
            newRowsAction: 'keep',
            closeOnApply: true,
            filterOptions: ['contains', 'equals'],
            suppressAndOrCondition: true
          },
          hide: true,
          resizable: true,
          valueGetter: params => {
            return params.data && params.data.lineManager
              ? params.data.lineManager
              : '-';
          }
        },
        {
          headerName: 'Gender',
          field: 'gender.name',
          colId: 'gender',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            apply: true,
            newRowsAction: 'keep',
            closeOnApply: true,
            filterOptions: ['contains', 'equals'],
            suppressAndOrCondition: true
          },
          hide: true,
          resizable: true,
          valueGetter: params => {
            return params.data && params.data.gender && params.data.gender.name
              ? params.data.gender.name
              : '-';
          }
        },
        {
          headerName: 'Job Grade',
          field: 'jobGrade.name',
          colId: 'jobGrade',
          sortable: false,
          hide: true,
          resizable: true,
          valueGetter: params => {
            return params.data &&
              params.data.jobGrade &&
              params.data.jobGrade.name
              ? params.data.jobGrade.name
              : '-';
          }
        },
        {
          headerName: 'Last Modified',
          field: 'audit.updatedAt',
          colId: 'audit.updatedAt',
          sortable: true,
          hide: true,
          valueGetter: params => {
            return params.data &&
              params.data.audit &&
              params.data.audit.updatedAt
              ? formatDate(params.data.audit.updatedAt)
              : '';
          },
          filter: 'agDateColumnFilter',
          filterParams: {
            comparator(filterLocalDateAtMidnight, cellValue) {
              const dateValue = formatDate(cellValue);
              const dateAsString = moment(dateValue).format('DD/MM/YYYY');
              if (dateAsString === null) {
                return -1;
              }
              const dateParts = dateAsString.split('/');
              const cellDate = new Date(
                Number(dateParts[2]),
                Number(dateParts[1]) - 1,
                Number(dateParts[0])
              );

              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              }
              if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              }
              return 0;
            },
            browserDatePicker: true,
            apply: true,
            newRowsAction: 'keep',
            closeOnApply: true,
            filterOptions: ['greaterThan', 'lessThan'],
            suppressAndOrCondition: true
          },
          resizable: true
        },
        {
          headerName: 'Employment Category',
          field: 'employmentCategory.name',
          colId: 'employmentCategory',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            apply: true,
            newRowsAction: 'keep',
            closeOnApply: true,
            filterOptions: ['contains', 'equals'],
            suppressAndOrCondition: true
          },
          hide: true,
          resizable: true,
          valueGetter: params => {
            return params.data &&
              params.data.employmentCategory &&
              params.data.employmentCategory.name
              ? params.data.employmentCategory.name
              : '-';
          }
        },
        {
          headerName: 'DOB',
          field: 'dob',
          colId: 'dob',
          sortable: true,
          valueGetter: params => {
            return params.data && params.data.dob
              ? formatDate(params.data.dob, 'MM/DD/YYYY')
              : '-';
          },
          filter: 'agDateColumnFilter',
          filterParams: {
            comparator(filterLocalDateAtMidnight, cellValue) {
              const dateValue = formatDate(cellValue);
              const dateAsString = moment(dateValue).format('DD/MM/YYYY');
              if (dateAsString === null) {
                return -1;
              }
              const dateParts = dateAsString.split('/');
              const cellDate = new Date(
                Number(dateParts[2]),
                Number(dateParts[1]) - 1,
                Number(dateParts[0])
              );

              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              }
              if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              }
              return 0;
            },
            browserDatePicker: true,
            apply: true,
            newRowsAction: 'keep',
            closeOnApply: true,
            filterOptions: ['greaterThan', 'lessThan'],
            suppressAndOrCondition: true
          },
          hide: true,
          resizable: true
        },
        {
          headerName: 'Phone',
          field: 'phone',
          colId: 'phone',
          sortable: true,
          cellRenderer: params =>
            formatPhoneNumber(params.data && params.data.phone),
          filter: 'agTextColumnFilter',
          filterParams: {
            apply: true,
            newRowsAction: 'keep',
            closeOnApply: true,
            filterOptions: ['contains', 'equals'],
            suppressAndOrCondition: true
          },
          resizable: true
        },
        {
          headerName: 'E-mail',
          field: 'email',
          colId: 'email',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            apply: true,
            newRowsAction: 'keep',
            closeOnApply: true,
            filterOptions: ['contains', 'equals'],
            suppressAndOrCondition: true
          },
          resizable: true
        },
        {
          headerName: 'Office',
          field: 'officeAddress',
          colId: 'officeAddress',
          filter: 'agTextColumnFilter',
          filterParams: {
            apply: true,
            newRowsAction: 'keep',
            closeOnApply: true,
            filterOptions: ['contains', 'equals'],
            suppressAndOrCondition: true
          },
          resizable: true,
          valueGetter: params => {
            return params.data && params.data.officeAddress
              ? params.data.officeAddress
              : '-';
          }
        },
        {
          headerName: 'Team/Dept.',
          field: 'teamName',
          colId: 'teamName',
          filter: 'agTextColumnFilter',
          filterParams: {
            apply: true,
            newRowsAction: 'keep',
            closeOnApply: true,
            filterOptions: ['contains', 'equals'],
            suppressAndOrCondition: true
          },
          resizable: true,
          valueGetter: params => {
            return params.data && params.data.teamName
              ? params.data.teamName
              : '-';
          }
        },
        {
          headerName: 'Status',
          field: 'status',
          resizable: true,
          filter: 'agSetColumnFilter',
          filterParams: {
            values: ['New', 'Active', 'Pending', 'Expired'],
            apply: true,
            newRowsAction: 'keep',
            closeOnApply: true
          },
          width: 180,
          sortable: false,
          cellRenderer: 'statusChipRenderer',
          cellRendererParams: params => {
            return { text: params.data ? params.data.status : params.value };
          },
          colId: 'status'
        },
        {
          headerName: 'Actions',
          resizable: true,
          pinned: 'right',
          width: 150,
          menuTabs: ['generalMenuTab', 'columnsMenuTab'],
          cellRenderer: 'actionsRenderer',
          cellRendererParams: params => {
            return (
              params.data && {
                rowId: params.data.phone,
                status: params.data.status,
                reloadData: this.reloadData
              }
            );
          }
        }
      ],
      defaultColDef: {
        flex: 1,
        resizable: true,
        enableValue: true,
        enableRowGroup: false,
        enablePivot: true,
        sortable: true,
        filter: true
      },
      rowSelection: 'multiple',
      rowData: [],
      frameworkComponents: {
        statusChipRenderer: StatusChip,
        actionsRenderer: Actions
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressRowGroups: false
            }
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel'
          }
        ],
        defaultToolPanel: ''
      },
      allColumns: true,
      multiSortKey: 'ctrl',
      groupHeaderHeight: 75,
      enableSend: false,
      enableResend: false,
      pendingIds: [],
      expiredIds: [],
      newIds: [],
      paginationPageSize: 25,
      cacheBlockSize: 25,
      pagination: true,
      rowModelType: 'serverSide',
      maxBlocksInCache: 1,
      checkbox: false,
      suppressExcelExport: true,
      suppressCsvExport: true,
      totalPageSize: 0,
      recordSelected: 0,
      loadedData: []
    };
  }

  static propTypes = {
    MyTeamStore: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
    params.api.setServerSideDatasource(this.serverSideDatasource());
  };

  serverSideDatasource = () => {
    return {
      getRows: params => {
        const filterParams = getFilterParams(params.request.filterModel);
        const sortParams = getSortParams(params.request.sortModel);
        const param = `sort=${sortParams || 'audit.updatedAt%2Cdesc'}&offset=${
          params.request.startRow
        }&limit=${params.request.endRow -
          params.request.startRow}${filterParams}`;

        VaultProvider.getProfiles(param).then(profilesResponse => {
          const response = profilesResponse;
          this.setLoadedData(response.data);
          this.props.MyTeamStore._setMembersList(response);
          this.setState({ totalPageSize: profilesResponse.pagination.total });
          if (response.data.length > 0) {
            params.successCallback(response.data, response.pagination.total);
          } else {
            params.successCallback(response.data, 0);
            params.api.showNoRowsOverlay();
          }
          if (this.state.checkbox) {
            this.gridApi.forEachNode(node => {
              node.setSelected(true);
            });
          }
        });
      }
    };
  };

  componentDidMount() {
    const {
      MyTeamStore: { getLatestUploadStatus }
    } = this.props;

    // temporary solution for notification
    getLatestUploadStatus();
    this.timerRef = setInterval(() => {
      getLatestUploadStatus();
    }, 10000);

    globalNewIds = [];
    globalPendingIds = [];
    globalExpiredIds = [];
  }

  onRowSelected = event => {
    if (event.data.status === 'ACTIVE') {
      return;
    }
    let enableSend = true;
    let enableResend = true;
    if (event.node.selected === true) {
      switch (event.data.status) {
        case 'NEW': {
          enableSend = true;
          globalNewIds.push({
            identity: event.data.phone,
            identity_type: 'PHONE',
            role_id: event.data.role_id,
            send_notification: true
          });
          break;
        }
        case 'PENDING': {
          enableResend = true;
          globalPendingIds.push(event.data.phone);
          break;
        }
        case 'EXPIRED': {
          enableResend = true;
          globalExpiredIds.push(event.data.phone);
          break;
        }
        default:
          break;
      }
      this.setState(
        {
          checkbox: this.gridApi.getSelectedRows().length % 25 === 0
        },
        () => {
          if (
            !this.state.checkbox &&
            this.gridApi.getSelectedRows().length % 25 === 0
          ) {
            this.gridApi.deselectAll();
          }
        }
      );
    } else {
      if (event.data.status === 'NEW') {
        globalNewIds = globalNewIds.filter(
          each => each.identity !== event.data.phone
        );
      }
      if (event.data.status === 'PENDING') {
        globalPendingIds = globalPendingIds.filter(
          each => each !== event.data.phone
        );
      }
      if (event.data.status === 'EXPIRED') {
        globalExpiredIds = globalExpiredIds.filter(
          each => each !== event.data.phone
        );
      }
      this.setState({ checkbox: false }, () => {
        if (
          !this.state.checkbox &&
          this.gridApi.getSelectedRows().length % 25 === 0
        ) {
          this.gridApi.deselectAll();
        }
      });
    }
    // disable inivation and resend button if no rows selected
    if (event.data.status === 'PENDING' && globalPendingIds.length === 0) {
      enableResend = false;
    }
    if (event.data.status === 'NEW' && globalNewIds.length === 0) {
      enableSend = false;
    }
    if (event.data.status === 'EXPIRED' && globalExpiredIds.length === 0) {
      enableResend = false;
    }
    // FIXME: find a better way with access manipulations
    if (globalPendingIds.length === 0 && globalExpiredIds.length === 0) {
      enableResend = false;
    }
    if (globalNewIds.length === 0) {
      enableSend = false;
    }
    this.setState({
      enableResend,
      enableSend,
      newIds: globalNewIds,
      pendingIds: globalPendingIds,
      expiredIds: globalExpiredIds
    });
    // Header checkbox gets deselected is any of the rows are unchecked
    if (event.node.selected === false) {
      this.setState({ checkbox: false });
    }
    // to deselect all rows if Selectall is clicked
    if (
      !this.state.checkbox &&
      this.gridApi.getSelectedRows().length % 25 === 0
    ) {
      this.gridApi.deselectAll();
      globalNewIds = [];
      globalPendingIds = [];
      globalExpiredIds = [];
    }
    this.setState({ recordSelected: this.gridApi.getSelectedRows().length });
  };

  handleResendCode = () => {
    const { MyTeamStore } = this.props;
    const { pendingIds, expiredIds } = this.state;
    MyTeamStore.resendBatchInvitations([...pendingIds, ...expiredIds]).then(
      () => {
        this.setState({
          pendingIds: [],
          expiredIds: [],
          enableResend: false,
          enableSend: false,
          checkbox: false
        });
        this.reloadData();
        this.gridApi.deselectAll();
      }
    );
  };

  handleSendCode = () => {
    const { MyTeamStore } = this.props;
    const { newIds } = this.state;
    MyTeamStore.sendBatchInvitations(newIds).then(() => {
      this.setState({
        newIds: [],
        enableSend: false,
        enableResend: false,
        checkbox: false,
        mewIdTest: {}
      });
      this.reloadData();
      this.gridApi.deselectAll();
    });
  };

  componentWillUnmount() {
    const {
      MyTeamStore: { resetMembersList, resetOffset }
    } = this.props;
    resetOffset();
    resetMembersList();
    clearInterval(this.timerRef);
  }

  // clear all selected filters

  clearAllFilter = () => {
    this.gridApi.setFilterModel({});
    this.gridApi.setSortModel({});
  };

  headerSelection = event => {
    this.gridApi.forEachNode(node => {
      if (event.target.checked === true) {
        this.setState({ checkbox: true });
        node.setSelected(true);
      } else {
        this.setState({ checkbox: false });
        node.setSelected(false);
      }
    });
  };

  reloadData = () => {
    this.gridApi.purgeServerSideCache();
  };

  setLoadedData = data => {
    if (this.state.loadedData.length > 0) {
      const allreadyLoadedData = [...this.state.loadedData];
      data.forEach(newItem => {
        const exists = allreadyLoadedData.find(
          item => item.guid === newItem.guid
        );

        if (!exists) {
          allreadyLoadedData.push(newItem);
        }
      });
      this.setState({ loadedData: allreadyLoadedData });
    } else {
      this.setState({ loadedData: data });
    }
  };

  getCSVData = data => {
    return data.map(item => {
      return {
        Title: item.title && item.title.name,
        'Full Name': `${item.firstName}  ${item.lastName}`,
        'Middle Name': item.middleName,
        'Last Name': item.lastName,
        'First Name': item.firstName,
        Phone: item.phone,
        'E-mail': item.email,
        'Employee Id': item.employeeId,
        Country: item.address && item.address.city.country,
        Region: item.address && item.address.city.region,
        City: item.address && item.address.city.name,
        'Job Title': item.jobTitle && item.jobTitle.name,
        'Line Manager': item.lineManager,
        Gender: item.gender && item.gender.name,
        'Job Grade': item.jobGrade && item.jobGrade.name,
        'Last Modified': formatDate(item.audit.updatedAt),
        'Employment Category':
          item.employmentCategory && item.employmentCategory.name,
        DOB: formatDate(item.dob, 'MM/DD/YYYY'),
        Office: item.officeAddress,
        Team: item.teamName,
        Status: item.status
      };
    });
  };

  render() {
    const { history } = this.props;
    const { loadedData } = this.state;
    return (
      <>
        <div className="filterAll">
          <button className="clear-all-myteam" onClick={this.clearAllFilter}>
            Clear Filter
          </button>
          {loadedData.length > 0 && (
            <ExportButton
              count={loadedData.length}
              jsonData={this.getCSVData(loadedData)}
            />
          )}
          <DropDownMenu />
        </div>
        <div className="buttonRow">
          <CustomTooltip key={shortid.generate()} label="Select All">
            <input
              type="checkbox"
              onChange={event => this.headerSelection(event)}
              className="checkBoxAll"
              checked={this.state.checkbox}
            />
          </CustomTooltip>
          <IconActions
            enableResend={this.state.enableResend}
            enableSend={this.state.enableSend}
            handleResendCode={this.handleResendCode}
            handleSendCode={this.handleSendCode}
          />
          <p className="selectedRows">
            <b>
              {this.state.recordSelected} of {this.state.totalPageSize} selected{' '}
            </b>
          </p>
        </div>
        <BlockWrapper>
          <div className="ag-theme-alpine" style={{ width: '100%' }}>
            <AgGridReact
              multiSortKey={this.state.multiSortKey}
              onGridReady={this.onGridReady}
              paginationPageSize={this.state.paginationPageSize}
              cacheBlockSize={this.state.cacheBlockSize}
              pagination={this.state.pagination}
              suppressRowClickSelection
              onRowClicked={params => {
                if (params.data && params.event.target.textContent !== '') {
                  history.push(
                    `${getMyTeamPath.teamMember()}/${params.data.phone}`
                  );
                }
              }}
              suppressCellSelection
              rowStyle={{ cursor: 'pointer' }}
              frameworkComponents={this.state.frameworkComponents}
              columnDefs={this.state.columnDefs}
              defaultColDef={this.state.defaultColDef}
              rowSelection={this.state.rowSelection}
              sideBar={this.state.sideBar}
              groupHeaderHeight={this.state.groupHeaderHeight}
              allColumns={this.state.allColumns}
              rowModelType={this.state.rowModelType}
              maxBlocksInCache={this.state.maxBlocksInCache}
              onRowSelected={this.onRowSelected}
              suppressExcelExport={this.state.suppressExcelExport}
              suppressCsvExport={this.state.suppressCsvExport}
              onFilterChanged={() => this.setState({ loadedData: [] })}
            />
          </div>
        </BlockWrapper>
      </>
    );
  }
}

export default compose(
  inject('MyTeamStore'),
  observer
)(TableWrapper);
