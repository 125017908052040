/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Store
import MyTeamStore from '@Stores/MyTeamStore';

export const MyTeamForms = {
  teamMemberCreateForm: {
    fields: {
      title: {
        value: '',
        error: null,
        rule: 'optional',
        type: 'select',
        res: 'TITLE'
      },
      firstName: {
        value: '',
        error: null,
        rule: 'required|max:128'
      },
      middleName: {
        value: '',
        error: null,
        rule: 'max:128'
      },
      lastName: {
        value: '',
        error: null,
        rule: 'max:128|required'
      },
      email: {
        value: '',
        error: null,
        rule: 'required|email'
      },
      phone: {
        value: '',
        error: null,
        rule: 'required|min:10'
      },
      dob: {
        value: null,
        error: null,
        rule: 'optional'
      },
      employeeId: {
        value: '',
        error: null,
        rule: 'optional'
      },
      jobTitle: {
        value: '',
        error: null,
        rule: 'optional',
        type: 'select',
        res: 'JOB_TITLE'
      },
      teamName: {
        value: '',
        error: null,
        rule: 'optional'
      },
      lineManager: {
        value: '',
        error: null,
        rule: 'optional|email'
      },
      gender: {
        value: '',
        error: null,
        rule: 'optional',
        type: 'select',
        res: 'GENDER'
      },
      country: {
        value: '',
        error: null,
        rule: 'optional'
      },
      region: {
        value: '',
        disabled: true,
        error: null,
        rule: 'optional'
      },
      city: {
        value: '',
        disabled: true,
        error: null,
        rule: 'optional'
      },
      officeAddress: {
        value: '',
        error: null,
        rule: 'optional'
      },
      jobGrade: {
        value: '',
        error: null,
        rule: 'optional',
        type: 'select',
        res: 'JOB_GRADE'
      },
      jobStatus: {
        value: '',
        error: null,
        rule: 'optional',
        type: 'select',
        res: 'JOB_STATUS'
      },
      employmentCategory: {
        value: '',
        error: null,
        rule: 'optional',
        type: 'select',
        res: 'EMPLOYMENT_CATEGORY'
      },
      sendCheckbox: {
        value: 'sendSms',
        rule: 'in:sendSms,doNotSendSms',
        onChange: e =>
          MyTeamStore.onFieldChange(
            'sendCheckbox',
            e.target.value,
            'teamMemberCreateForm'
          )
      }
    },
    meta: {
      isValid: false,
      error: null
    }
  }
};
