/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import moment from 'moment';

// Components
import { BarChart, FromToDateFilter } from '@CommonScene';
import CasesStatistics from '@DashboardsScene/scenes/COVID19Family/components/CasesStatistics';

// Utils
import { compose } from '@Utils';

// Constants
import { withRouter } from 'react-router-dom';
import CasesTable from '../components/CasesTable';

export class COVID19Family extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    AuthStore: PropTypes.object.isRequired,
    COVID19FamilyReportStore: PropTypes.object.isRequired,
    CommonStore: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      dateRange: {
        from: new Date(
          moment
            .utc(
              moment().add(
                moment()
                  .parseZone()
                  .utcOffset(),
                'minutes'
              )
            )
            .subtract(14, 'days')
            .startOf('day')
            .format()
        ).getTime(),
        to: new Date(
          moment
            .utc(
              moment().add(
                moment()
                  .parseZone()
                  .utcOffset(),
                'minutes'
              )
            )
            .format()
        ).getTime()
      }
    };
  }

  componentDidMount() {
    if (localStorage.getItem('dateRange')) {
      this.setState(
        {
          dateRange: JSON.parse(localStorage.getItem('dateRange'))
        },
        () => {
          this.fetchData();
        }
      );
    } else {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.dateRange !== this.state.dateRange) {
      this.fetchData();
    }
  }

  handleChangeDate = (date, key) => {
    let currentDate = date;
    if (!currentDate) {
      currentDate = key === 'from' ? 1546300800000 : Date.now();
    }
    this.setState({
      dateRange: { ...this.state.dateRange, [key]: currentDate }
    });
    localStorage.setItem(
      'dateRange',
      JSON.stringify({
        ...this.state.dateRange,
        [key]: currentDate
      })
    );
  };

  getDateRangeParams = () => {
    return {
      'testResult.fromDate': this.state.dateRange.from,
      'testResult.toDate': this.state.dateRange.to
    };
  };

  fetchData = () => {
    const {
      COVID19FamilyReportStore: { getCovidChartData, resetOffset }
    } = this.props;
    resetOffset();
    getCovidChartData(this.getDateRangeParams());
  };

  render() {
    const {
      COVID19FamilyReportStore: { chartData },
      CommonStore: { pending }
    } = this.props;
    return (
      <>
        <FromToDateFilter
          handleChangeDate={this.handleChangeDate}
          dateRange={this.state.dateRange}
        />
        <p />
        <BarChart
          data={chartData}
          title="COVID-19 Confirmed Family Daily Statistics"
          width={'100%'}
          height={'200'}
          chartType={'Bar'}
          pending={pending}
          colors={['#FE4848', 'blue', '#32CD32', '#FEC258', '#9B51E0']}
        />
        <CasesStatistics />
        <CasesTable
          dateRange={this.state.dateRange}
          title="COVID-19 Confirmed Family"
          type="FAMILY"
        />
      </>
    );
  }
}

export default compose(
  inject('AuthStore', 'COVID19FamilyReportStore', 'CommonStore'),
  withRouter,
  observer
)(COVID19Family);
