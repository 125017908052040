/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Material UI
import { Grid, withStyles, CircularProgress } from '@material-ui/core';

// Components
import { BlockWrapper } from '@CommonScene';

// Icons
import { Minus, CheckedCircle } from '@Utils/constans/icons';

// Styles
import styles from './styles';

class SurveyChartResponse extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    answered: PropTypes.number,
    notAnswered: PropTypes.number,
    pending: PropTypes.bool
  };

  render() {
    const { classes, answered, notAnswered, pending } = this.props;

    return (
      <div className={classes.responseWrapper}>
        <BlockWrapper
          withBottomMargin
          title="Answered"
          Icon={CheckedCircle}
          className={classes.boxhgt}
        >
          <Grid container className={classes.chartWrapper}>
            {pending ? <CircularProgress /> : answered}
          </Grid>
        </BlockWrapper>

        <BlockWrapper
          withBottomMargin
          title="Not Answered"
          Icon={Minus}
          className={classes.boxhgt}
        >
          <Grid container className={classes.chartWrapper}>
            {pending ? <CircularProgress /> : notAnswered}
          </Grid>
        </BlockWrapper>
      </div>
    );
  }
}

export default withStyles(styles)(SurveyChartResponse);
