/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

// Material UI
import { Grid, withStyles } from '@material-ui/core';

// Components
import {
  ContainerHeader,
  SpeedDialMenu,
  FooterButtons
} from '@CommonScene/MainContentWrapper/components';

// Utils
import { compose } from '@Utils';

// Styles
import styles from './styles';

const MainContentWrapper = ({
  classes,
  headerOptions,
  buttonOptions,
  speedDialMenuOptions,
  setQuery,
  queryValue,
  filterOptions,
  filterCounter,
  tabsOptions,
  children,
  buttonCentered
}) => (
  <Grid container>
    <Grid item xs={12} className={classes.wrapper}>
      <ContainerHeader
        options={headerOptions}
        setQuery={setQuery}
        queryValue={queryValue}
        filterOptions={filterOptions}
        filterCounter={filterCounter}
        tabsOptions={tabsOptions}
      />
      {children}
    </Grid>

    {buttonOptions && (
      <FooterButtons options={buttonOptions} centered={buttonCentered} />
    )}

    {speedDialMenuOptions && speedDialMenuOptions.isShown ? (
      <SpeedDialMenu options={speedDialMenuOptions} />
    ) : null}
  </Grid>
);

MainContentWrapper.propTypes = {
  filterCounter: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  headerOptions: PropTypes.object,
  buttonOptions: PropTypes.shape({
    isShown: PropTypes.bool,
    isDisabled: PropTypes.bool,
    text: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
      PropTypes.node,
      PropTypes.func
    ]),
    onClickHandler: PropTypes.func
  }),
  speedDialMenuOptions: PropTypes.object,
  classes: PropTypes.object.isRequired,
  queryValue: PropTypes.string,
  setQuery: PropTypes.func,
  filterOptions: PropTypes.object,
  tabsOptions: PropTypes.object,
  buttonCentered: PropTypes.bool
};

export default compose(
  withStyles(styles),
  observer
)(MainContentWrapper);
