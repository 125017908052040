// Core
import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';
import { Chart } from 'react-google-charts';

// Material UI
import { withStyles, Box, CircularProgress } from '@material-ui/core';

// Components
import { BlockWrapper } from '@CommonScene';

// Icons
import { Pie } from '@Utils/constans/icons';

// Utils
import { compose } from '@Utils';

// Styles
import styles from './styles';

class PieChart extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
    title: PropTypes.string,
    colors: PropTypes.array,
    CommonStore: PropTypes.object.isRequired
  };

  getLegendData = data => {
    const { classes } = this.props;
    const legendData = [];
    data.forEach((item, index) => {
      if (index > 0) {
        legendData.push({
          label: item[0],
          value: item[1],
          color: item[2]
        });
      }
    });
    return legendData.map(item => {
      return (
        <Box key={item.label} pl={4}>
          <span
            style={{ backgroundColor: item.color }}
            className={classes.legendDot}
          />
          <span className={classes.legendValue}> {item.value}</span>
          <Box className={classes.legendLabel}>{item.label}</Box>
        </Box>
      );
    });
  };

  render() {
    const {
      data,
      title,
      colors,
      CommonStore: { pending }
    } = this.props;
    return (
      <BlockWrapper withBottomMargin title={title} Icon={Pie}>
        {data.length ? (
          <Box display="flex" justifyContent="space-around">
            <Chart
              height={'250px'}
              chartType="PieChart"
              loader={<div>Loading Chart...</div>}
              data={data}
              options={{
                fontName: 'Roboto',
                legend: 'none',
                chartArea: { height: '200', width: '100%' },
                colors,
                sliceVisibilityThreshold: 0,
                pieSliceText: 'none'
              }}
            />
            <Box display="flex" alignItems="center">
              {this.getLegendData(data)}
            </Box>
          </Box>
        ) : (
          <Box display="flex">
            <Box m="auto">
              {pending ? <CircularProgress /> : <p>No data</p>}
            </Box>
          </Box>
        )}
      </BlockWrapper>
    );
  }
}

export default compose(
  inject('CommonStore'),
  withStyles(styles),
  observer
)(PieChart);
