// Core
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

// Material UI
import { Grid, Typography } from '@material-ui/core';

// Utils
import { compose } from '@Utils/index';
import { AbsentIcon } from '@Utils/constans/icons';
import { formatPhoneNumber } from '@Utils/formatting';

// Components
import FieldWrapper from '@CommonScene/FieldWrapper';

import useStyles from './styles';

const DetailsData = ({ form, justify }) => {
  const classes = useStyles();
  const formatDisplay = value => {
    return !value ? <AbsentIcon /> : value;
  };
  const formatPhone = value => {
    return formatPhoneNumber(value);
  };
  return (
    <Grid
      container
      className={classes.wrapper}
      direction="row"
      justify={justify}
      alignItems="center"
    >
      <Grid item className={classes.form}>
        <FieldWrapper
          label="First name"
          labelClassName={classes.label}
          childrenClassName={classes.formValueWrapper}
          className={classes.field}
        >
          <Typography variant="body2" className={classes.formValue}>
            {formatDisplay(form.fields.firstName.value)}
          </Typography>
        </FieldWrapper>
        <FieldWrapper
          label="Middle name"
          labelClassName={classes.label}
          childrenClassName={classes.formValueWrapper}
          className={classes.field}
        >
          <Typography variant="body2" className={classes.formValue}>
            {formatDisplay(form.fields.middleName.value)}
          </Typography>
        </FieldWrapper>
        <FieldWrapper
          label="Last name"
          labelClassName={classes.label}
          childrenClassName={classes.formValueWrapper}
          className={classes.field}
        >
          <Typography variant="body2" className={classes.formValue}>
            {formatDisplay(form.fields.lastName.value)}
          </Typography>
        </FieldWrapper>

        <FieldWrapper
          label="E-mail"
          labelClassName={classes.label}
          childrenClassName={classes.formValueWrapper}
          className={classes.field}
        >
          <Typography variant="body2" className={classes.formValue}>
            {formatDisplay(form.fields.email.value)}
          </Typography>
        </FieldWrapper>
        <FieldWrapper
          label="Phone"
          labelClassName={classes.label}
          childrenClassName={classes.formValueWrapper}
          className={classes.field}
        >
          <Typography variant="body2" className={classes.formValue}>
            {formatPhone(form.fields.phone.value)}
          </Typography>
        </FieldWrapper>
      </Grid>
    </Grid>
  );
};

DetailsData.propTypes = {
  form: PropTypes.object,
  justify: PropTypes.string
};

DetailsData.defaultProps = {
  justify: 'center'
};

export default compose(observer)(DetailsData);
