/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright Â© 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

// Material UI
import { Grid, Typography, withStyles } from '@material-ui/core';

// Components
import { DateFilter } from '@CommonScene';

// Utils
import { compose } from '@Utils';

// Styles
import styles from './styles';

export class FromToDateFilter extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    dateRange: PropTypes.object,
    handleChangeDate: PropTypes.func.isRequired,
    title: PropTypes.string
  };

  static defaultProps = {
    title: '',
    dateRange: undefined
  };

  state = {
    dateRange: {
      from: undefined,
      to: undefined
    }
  };

  static getDerivedStateFromProps(nextProps, nextState) {
    if (nextProps.dateRange) {
      return {
        dateRange: {
          ...nextState.dateRange,
          from: nextProps.dateRange.from
            ? moment(nextProps.dateRange.from)
            : '',
          to: nextProps.dateRange.to ? moment(nextProps.dateRange.to) : ''
        }
      };
    }
    return {
      dateRange: {
        from: undefined,
        to: undefined
      }
    };
  }

  handleChangeDate = (key, date) => {
    // Not fully tested but working solution for getting date in UTC format
    let formattedDate = moment
      .utc(date.add(date.parseZone().utcOffset(), 'minutes'))
      .format();
    if (key === 'to') {
      formattedDate = moment
        .utc(date.add(date.parseZone().utcOffset(), 'minutes'))
        .endOf('day')
        .format();
    }

    const convertedTime = new Date(formattedDate).getTime();

    this.setState(
      {
        dateRange: {
          ...this.state.dateRange,
          [key]: this.getDate(new Date(date.endOf('day').format()).getTime())
        }
      },
      this.props.handleChangeDate(convertedTime || '', key)
    );
  };

  getDate = date => (date ? moment(date) : '');

  render() {
    const { classes, title, t } = this.props;
    const { dateRange } = this.state;
    return (
      <Grid container alignItems="flex-end" className={classes.wrapper}>
        {title ? <Typography id="title">{`${title}:`}</Typography> : null}
        <DateFilter
          id="from"
          label={t('can.common.from')}
          handleChangeDate={date => {
            this.handleChangeDate('from', moment(date.format('YYYY-MM-DD')));
          }}
          clearDate={() => this.handleChangeDate('from')}
          maxDate={dateRange.to || moment().format()}
          value={
            dateRange.from &&
            dateRange.from.utc(
              dateRange.from.subtract(
                dateRange.from.parseZone().utcOffset(),
                'minutes'
              )
            )
          }
        />
        <DateFilter
          id="to"
          label={t('can.common.to')}
          handleChangeDate={date => this.handleChangeDate('to', date)}
          clearDate={() => this.handleChangeDate('to')}
          minDate={dateRange.from}
          disableFuture
          value={
            dateRange.to &&
            dateRange.to.utc(
              dateRange.to.subtract(
                dateRange.to.parseZone().utcOffset(),
                'minutes'
              )
            )
          }
        />
      </Grid>
    );
  }
}

export default compose(
  withTranslation(),
  withStyles(styles),
  observer
)(FromToDateFilter);
