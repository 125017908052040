/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

import has from 'lodash/has';
import i18n from 'i18next';

const listOfNames = {
  cardsNames: {
    SYSTEM_DECK: 'Master Wallet',
    TIN_PERFORMANCE: 'TIN Performance',
    NPI_PERFORMANCE: 'NPI Performance',
    TIN_REWARDS: 'TIN Rewards',
    TIN_REDEMPTION: 'TIN Redemption',
    REDEMPTION_MANAGEMENT: 'Redemption Management',
    TRANSPORTATION: 'Transportation',
    APPROVAL: 'Approval',
    CAN_SPONSOR_DECK: 'CAN Sponsorship',
    PAYMENT_ADMIN_DECK: 'Service Wallet'
  },
  permissionsNames: {
    USER_MANAGEMENT: 'Participant and Role Management',
    VIEW_HISTORY: 'View History',
    APPROVER: 'Approve Redemption',
    CAN_REDEEM: 'Available to Redeem',
    VIEW: 'View',
    ACCEPT_AND_REJECT: 'Accept and reject',
    BOOK_A_RIDE: 'Book a ride',
    BILLING_PERMISSION: 'CAN Billing',
    SERVICE_BILLING_PERMISSION: 'Service Billing'
  },
  rolesNames: {
    ACO_FINANCE: 'Finance',
    MW_ADMIN: 'Master Wallet Administrator',
    TIN_PERFORMANCE: 'Performance TIN',
    NPI_PERFORMANCE: 'Performance NPI',
    TIN_REWARDS: 'Rewards TIN',
    TIN_REDEMPTION: 'Redeem',
    NPI_REWARDS: 'Rewards NPI',
    SUPPORT_MW: 'Master Wallet Administrator',
    RIDER: 'Member',
    APPROVER: 'Care Coordinator',
    CAN_SPONSOR: 'CAN Sponsor',
    PAYMENT_ADMIN: 'Payment Admin'
  },
  sponsorNames: {
    TRANSPORTATION_PAYMENT: 'Transportation'
  },
  statuses: {
    Paid: i18n.t('can.common.statuses.completed'),
    SUSPENDED: i18n.t('can.common.statuses.blocked'),
    ARCHIVED: i18n.t('can.common.statuses.archived'),
    ASSIGNED_WALLET: i18n.t('can.common.statuses.active'),
    CREATED: i18n.t('can.common.statuses.new'),
    PREASSIGNED: i18n.t('can.common.statuses.new'),
    ACTIVE: i18n.t('can.common.statuses.active'),
    BLOCKED: i18n.t('can.common.statuses.blocked'),
    ASSIGNED_IDENTITY: i18n.t('can.common.statuses.assigned'),
    INVITATION_PENDING: i18n.t('can.common.statuses.invitationPending'),
    PENDING: i18n.t('can.common.statuses.invitationPending'),
    INVITATION_EXPIRED: i18n.t('can.common.statuses.invitationExpired'),
    EXPIRED: i18n.t('can.common.statuses.invitationExpired'),
    NEW_USER: 'New user',
    NEW: 'New'
  },
  nodeStatuses: {
    ASSIGNED_WALLET: i18n.t('can.common.nodeStatuses.connected'),
    CREATED: i18n.t('can.common.nodeStatuses.open'),
    ASSIGNED_IDENTITY: i18n.t('can.common.nodeStatuses.assigned')
  },
  nodesNames: {
    WALLET: i18n.t('can.common.nodes.wallet'),
    ADMIN: i18n.t('can.common.nodes.admin'),
    SPONSOR: i18n.t('can.common.nodes.sponsor'),
    SERVICE_PROVIDER: i18n.t('can.common.nodes.serviceProvider'),
    ENDPOINT: i18n.t('can.common.nodes.endpoint')
  },
  nodesTypes: {
    WALLET: i18n.t('can.common.nodesTypes.wallet')
  },
  // TODO: find a better naming
  targets: {
    NODE_COMPUTATION_ENDPOINT: 'Platform'
  },
  filterNames: {
    activity_types: 'activity types'
  },
  uploadStatuses: {
    STARTED: i18n.t('can.common.uploadStatuses.started'),
    FINISHED: i18n.t('can.common.uploadStatuses.finished'),
    FAILED: i18n.t('can.common.uploadStatuses.failed')
  }
};

export const translation = (value, names) => {
  const filteredNames = listOfNames[names];
  return has(filteredNames, value) ? filteredNames[value] : value;
};
