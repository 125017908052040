/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import moment from 'moment';

// Utils
import { compose } from '@Utils';
import { Person } from '@Utils/constans/icons';
import { formatDate } from '@Utils/formatting';
import { getMyTeamPath } from '@Utils/constans/paths';

// Material UI
import { Typography, withStyles } from '@material-ui/core';

import ExportButton from '@CommonScene/ExportButton';

// Component
import ContainerHeader from '@CommonScene/MainContentWrapper/components/ContainerHeader';
import { BlockWrapper } from '@CommonScene/';

// styles
import styles from './styles';
import './index.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

export class TableWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: [
        {
          headerName: 'Employee name',
          field: 'profile.fullName',
          sortable: true,
          filter: 'agTextColumnFilter',
          editable: false,
          resizable: true,
          colId: 'employeeName',
          width: 230,
          valueGetter: params => {
            return params.data &&
              params.data.profile &&
              params.data.profile.fullName
              ? params.data.profile.fullName
              : '-';
          }
        },
        {
          headerName: 'Employee e-mail',
          field: 'profile.email',
          sortable: true,
          filter: 'agTextColumnFilter',
          editable: false,
          resizable: true,
          width: 235,
          colId: 'email',
          valueGetter: params => {
            return params.data &&
              params.data.profile &&
              params.data.profile.email
              ? params.data.profile.email
              : '-';
          }
        },
        {
          headerName: 'Destination',
          field: 'trip.destination',
          sortable: true,
          filter: 'agTextColumnFilter',
          editable: false,
          resizable: true,
          width: 230,
          colId: 'destination',
          cellRendererFramework: params => {
            const rowData = params.data;
            const location = params.value;
            const { getCountryFlagsById } = this.props.ReportedTripsStore;
            if (
              rowData &&
              rowData.trip &&
              rowData.trip.details &&
              rowData.trip.details.city &&
              rowData.trip.details.city.icon
            ) {
              const flag = getCountryFlagsById(rowData.trip.details.city.icon);
              if (flag) {
                return (
                  <p className={props.classes.destinationWrapper}>
                    <img
                      src={flag}
                      width="24px"
                      height="16.5px"
                      style={{ borderRadius: '3px' }}
                    />
                    {location}
                  </p>
                );
              }
              return location;
            } else if (location) {
              return location;
            }
            return '';
          }
        },
        {
          headerName: 'From',
          field: 'trip.details.from',
          colId: 'fromDate',
          valueGetter: params => {
            return params.data &&
              params.data.trip &&
              params.data.trip.details &&
              params.data.trip.details.from
              ? formatDate(params.data.trip.details.from)
              : '-';
          },
          keyCreator(params) {
            return formatDate(params.value, 'MM/DD/YYYY');
          },
          editable: false,
          resizable: true,
          sortable: true,
          filter: 'agDateColumnFilter',
          width: 150,
          filterParams: {
            comparator(filterLocalDateAtMidnight, cellValue) {
              const dateValue = formatDate(cellValue);
              const dateAsString = moment(dateValue).format('DD/MM/YYYY');
              if (dateAsString === null) {
                return -1;
              }
              const dateParts = dateAsString.split('/');
              const cellDate = new Date(
                Number(dateParts[2]),
                Number(dateParts[1]) - 1,
                Number(dateParts[0])
              );

              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              }

              if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              }
              return 0;
            },
            browserDatePicker: true
          }
        },
        {
          headerName: 'To',
          field: 'trip.details.to',
          colId: 'toDate',
          valueGetter: params => {
            return params.data &&
              params.data.trip &&
              params.data.trip.details &&
              params.data.trip.details.to
              ? formatDate(params.data.trip.details.to)
              : '-';
          },
          keyCreator(params) {
            return formatDate(params.value, 'MM/DD/YYYY');
          },
          editable: false,
          resizable: true,
          sortable: true,
          filter: 'agDateColumnFilter',
          filterParams: {
            comparator(filterLocalDateAtMidnight, cellValue) {
              const dateValue = formatDate(cellValue);
              const dateAsString = moment(dateValue).format('DD/MM/YYYY');
              if (dateAsString == null) {
                return -1;
              }
              const dateParts = dateAsString.split('/');
              const cellDate = new Date(
                Number(dateParts[2]),
                Number(dateParts[1]) - 1,
                Number(dateParts[0])
              );

              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              }

              if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              }
              return 0;
            },
            browserDatePicker: true
          },
          width: 150
        },
        {
          headerName: '	Report Date',
          field: 'trip.statusAt',
          colId: 'statusAt',
          valueGetter: params => {
            return params.data && params.data.trip && params.data.trip.statusAt
              ? formatDate(params.data.trip.statusAt)
              : '-';
          },
          keyCreator(params) {
            return formatDate(params.value, 'MM/DD/YYYY');
          },
          editable: false,
          resizable: true,
          sortable: true,
          filter: 'agDateColumnFilter',
          filterParams: {
            comparator(filterLocalDateAtMidnight, cellValue) {
              const dateValue = formatDate(cellValue);
              const dateAsString = moment(dateValue).format('DD/MM/YYYY');
              if (dateAsString == null) {
                return -1;
              }
              const dateParts = dateAsString.split('/');
              const cellDate = new Date(
                Number(dateParts[2]),
                Number(dateParts[1]) - 1,
                Number(dateParts[0])
              );

              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              }

              if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              }
              return 0;
            },
            browserDatePicker: true
          },
          width: 150
        }
      ],
      rowData: [],
      loadedData: [],
      rowSelection: 'multiple',
      paginationPageSize: 25,
      pagination: true,
      suppressExcelExport: true,
      suppressCsvExport: true,
      defaultColDef: {
        flex: 1,
        resizable: true,
        enableValue: true,
        enableRowGroup: false,
        enablePivot: true,
        sortable: true,
        filter: true,
        editable: true,
        filterParams: {
          buttons: ['apply'],
          suppressAndOrCondition: true
        }
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressRowGroups: false
            }
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel'
          }
        ],
        multiSortKey: 'ctrl',
        defaultToolPanel: ''
      }
    };
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
    ReportedTripsStore: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  componentDidMount() {
    const {
      ReportedTripsStore: { getTrips }
    } = this.props;

    getTrips();
    const trips = this.props.ReportedTripsStore.trips;
    this.setState({ loadedData: trips });
  }

  componentWillUnmount() {
    const {
      ReportedTripsStore: { resetTripList, resetOffset }
    } = this.props;

    resetOffset();
    resetTripList();
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  clearAllFilter = () => {
    const AllFilter = [
      'employeeName',
      'email',
      'destination',
      'fromDate',
      'toDate',
      'statusAt'
    ];
    AllFilter.forEach(value => {
      const clearAll = this.gridApi.getFilterInstance(value);
      clearAll.setModel(null);
    });
    const {
      getTrips,
      resetOffset,
      resetTripList
    } = this.props.ReportedTripsStore;
    resetOffset();
    resetTripList();
    getTrips();
    const trips = this.props.ReportedTripsStore.trips;
    this.setState({ loadedData: trips });
  };

  updateActiveTab = tab => {
    const {
      updateActiveTab,
      getTrips,
      resetTripList,
      resetOffset,
      loading
    } = this.props.ReportedTripsStore;
    if (!loading) {
      updateActiveTab(tab);
      resetOffset();
      resetTripList();
      setTimeout(() => {
        getTrips();
      }, 500);
    }
  };

  tableTitle = () => {
    const {
      classes,
      ReportedTripsStore: { activeTab }
    } = this.props;
    const title = (
      <Typography className={classes.title}>
        <Person /> Reported Trips By People
      </Typography>
    );
    const options = {
      title
    };
    const tabs = [
      {
        title: 'Past',
        onClick: () => this.updateActiveTab('past'),
        isActive: activeTab === 'past'
      },
      {
        title: 'Upcoming',
        onClick: () => this.updateActiveTab('upcoming'),
        isActive: activeTab === 'upcoming'
      }
    ];
    return (
      <ContainerHeader
        classes={{ wrapper: classes.skipTopPadding }}
        options={options}
        tabsOptions={{ tabs }}
      />
    );
  };

  getCSVData = data => {
    return data.map(item => {
      return {
        'Employee Name': item.profile.fullName,
        'Employee E-mail': item.profile.email,
        Destination: item.trip.destination,
        From: formatDate(item.trip.details.from),
        To: formatDate(item.trip.details.to),
        'Reported Date': formatDate(item.trip.statusAt)
      };
    });
  };

  onFilterChanged = event => {
    const tripData = [];
    console.log(event);
    this.gridApi.forEachNodeAfterFilterAndSort(node => {
      tripData.push(node.data);
      this.setState({ loadedData: tripData });
    });
  };

  render() {
    const { trips } = this.props.ReportedTripsStore;
    const { loadedData } = this.state;
    const { history } = this.props;
    return (
      <BlockWrapper>
        <div className="ag-theme-alpine" style={{ width: '100%' }}>
          <button className="clear-all-dashboard" onClick={this.clearAllFilter}>
            Clear Filter
          </button>
          {loadedData.length > 0 && (
            <div className="export-btn-dashboard">
              <ExportButton
                count={loadedData.length}
                jsonData={this.getCSVData(loadedData)}
              />
            </div>
          )}
          <div>{this.tableTitle()}</div>

          <AgGridReact
            rowData={toJS(trips)}
            columnDefs={this.state.columnDefs}
            paginationPageSize={this.state.paginationPageSize}
            pagination={this.state.pagination}
            defaultColDef={this.state.defaultColDef}
            rowSelection={this.state.rowSelection}
            sideBar={this.state.sideBar}
            multiSortKey={this.state.multiSortKey}
            onGridReady={this.onGridReady}
            suppressExcelExport={this.state.suppressExcelExport}
            suppressCsvExport={this.state.suppressCsvExport}
            onFilterChanged={this.onFilterChanged}
            onSortChanged={this.onFilterChanged}
            suppressRowClickSelection
            onRowClicked={params => {
              if (params.data && params.event.target.textContent !== '') {
                history.push(
                  `${getMyTeamPath.teamMember()}/${
                    params.data.profile.phone
                  }?backLinkKey=travels`
                );
              }
            }}
            rowStyle={{ cursor: 'pointer' }}
          />
        </div>
      </BlockWrapper>
    );
  }
}

export default compose(
  withStyles(styles),
  inject('ReportedTripsStore'),
  observer
)(TableWrapper);
