/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';

// Utils
import { MoreResting } from '@Utils/constans/icons';
import { useStore } from '@Utils/hooks';

// Material UI
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

export default function DropDownMenu() {
  const {
    MyTeamStore: {
      resetMembersList,
      getTeamMembers,
      resendBatchInvitations,
      updateResendNotificationMsg,
      updateShowResendNotification,
      sendHealthReminderEvent,
      sendHAYFTReminderEvent,
      sendInvitationsForNewParticipants
    }
  } = useStore();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleReinviteNotJoined = () => {
    sendInvitationsForNewParticipants();
    resetMembersList();
    getTeamMembers();
    updateResendNotificationMsg(
      'The invitations were sent to all new participants'
    );
    updateShowResendNotification(true);
    handleClose();
  };
  const handleReinvitePending = () => {
    resendBatchInvitations(['PENDING'], true);
    resetMembersList();
    getTeamMembers();
    updateResendNotificationMsg(
      'The invitation was sent to all participants with pending status'
    );
    updateShowResendNotification(true);
    handleClose();
  };
  const handleReinviteAllExpired = () => {
    resendBatchInvitations(['EXPIRED'], true);
    resetMembersList();
    getTeamMembers();
    updateResendNotificationMsg(
      'The invitation was sent to all participants with expired status'
    );
    updateShowResendNotification(true);
    handleClose();
  };

  const handleExpiredPendingParticipants = () => {
    resendBatchInvitations(['PENDING', 'EXPIRED'], true);
    resetMembersList();
    getTeamMembers();
    updateResendNotificationMsg(
      "The invitation was sent to all participants who haven't joined yet"
    );
    updateShowResendNotification(true);
    handleClose();
  };

  const handleHealthCheckReminder = () => {
    sendHealthReminderEvent();
    handleClose();
  };
  const handleHayftReminder = () => {
    sendHAYFTReminderEvent();
    handleClose();
  };

  return (
    <div>
      <MoreResting
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleReinviteNotJoined}>
          Send invitations to all new participants
        </MenuItem>
        <MenuItem onClick={handleExpiredPendingParticipants}>
          Resend invitations to all pending and expired participants
        </MenuItem>
        <MenuItem onClick={handleReinvitePending}>
          Resend invitations to all pending participants
        </MenuItem>
        <MenuItem onClick={handleReinviteAllExpired}>
          Resend invitations to all expired participants
        </MenuItem>
        <MenuItem onClick={handleHealthCheckReminder}>
          Send Daily Health Check-Up Reminder
        </MenuItem>
        <MenuItem onClick={handleHayftReminder}>Send HAYFT Reminder</MenuItem>
      </Menu>
    </div>
  );
}
