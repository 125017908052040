/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import { observable, action, computed } from 'mobx';

// Providers
import {
  GroupProvider,
  RoleProvider,
  InvitationProvider,
  DeckProvider,
  NpsProvider,
  VaultProvider
} from '@Providers';

// Stores
import CommonStore from '@Stores/CommonStore';
import FilterStore from '@Stores/FilterStore';
import ValidationStore from '@Stores/ValidationStore';

// Utils
import { InvitationsForms as forms } from '@Assets/config/forms/invitations';
import { isArray, debounce } from 'lodash';
import { DELAY_REQUEST } from '@Utils/constans';

class InvitationStore extends ValidationStore {
  invitations = observable.array([]);

  participants = observable.array([]);

  filter = observable.array([], { deep: false });

  @observable errors = null;

  @observable query = '';

  @observable forms = forms;

  @observable order = 'desc';

  @observable orderBy = 'updatedAt';

  @observable page = 0;

  @observable rowsPerPage = 25;

  @observable lastPage = false;

  searchFilter = observable.array([], { deep: false });

  @observable totalItems = 0;

  filteredRolesTemplates = observable.array([], { deep: false });

  roleTemplates = observable.array([], { deep: false });

  customFields = observable.array([], { deep: false });

  filteredSuggestions = observable.array([], { deep: false });

  @observable adminGroup = null;

  decks = observable.array([], { deep: false });

  @observable isFieldsList = false;

  @observable financialGroup = {};

  @observable customQuery = {};

  @observable checkedAllRows = false;

  @observable currentParticipant = {};

  @computed get checkedInvitationAmount() {
    return this.participants.filter(participant => participant.checked).length;
  }

  @action('InvitationStore => updateCurrentParticipant')
  updateCurrentParticipant = participant => {
    this.currentParticipant = participant;
  };

  @action('InvitationStore => clearCurrentParticipant')
  clearCurrentParticipant = () => {
    this.currentParticipant = {};
  };

  @action('InvitationStore => toggleTableRowChecked')
  toggleTableRowChecked = rowId => {
    this.participants.forEach((participant, index) => {
      if (rowId === participant.phone) {
        this.participants[index].checked = !participant.checked;
      }
    });
    this.checkedAllRows = this.participants.every(
      participant => participant.checked
    );
  };

  @action('InvitationStore => clearCheckedTableRows')
  clearCheckedTableRows = () => {
    this.participants.forEach((participant, index) => {
      this.participants[index].checked = false;
    });
  };

  @action('InvitationStore => toggleAllTableRowChecked')
  toggleAllTableRowChecked = () => {
    this.participants.forEach((participant, index) => {
      this.participants[index].checked = !this.checkedAllRows;
    });
    this.checkedAllRows = !this.checkedAllRows;
  };

  @action('InvitationStore => clearAllTableRowChecked')
  clearAllTableRowChecked = () => {
    this.checkedAllRows = false;
  };

  @action('InvitationStore => _handleRequestError')
  _handleRequestError = err => {
    this.errors =
      (err.response && err.response.body && err.response.body.errors) || err;
    throw err;
  };

  @action('InvitationStore => setPageParams') setPageParams(
    lastPage,
    totalItems
  ) {
    this.lastPage = lastPage;
    this.totalItems = totalItems;
  }

  getParticipants = specialData => {
    let params = {
      page: this.page,
      size: this.rowsPerPage,
      sort: `${this.orderBy},${this.order}`,
      statuses: FilterStore.selectedFilters.Status,
      roles: FilterStore.selectedFilters.Roles
    };

    if (specialData === 'forCards') {
      params = { ...params, roles: 'Employee' };
    }

    if (this.query) {
      params.phone = `+${this.query.replace(/[^0-9]/g, '')}`;
    }

    CommonStore.setPending();
    return InvitationProvider.getParticipantList(params)
      .then(response => {
        if (specialData === 'forCards') {
          const requests = response.content.map(member => {
            return NpsProvider.getHolder({
              queryParams: { phone: member.phone }
            }).then(holder => {
              return {
                ...member,
                nodeId: holder.nodes.filter(node => node.role === 'Employee')[0]
                  .id
              };
            });
          });
          Promise.all(requests).then(mappedParticipants => {
            this._setParticipantList({
              ...response,
              content: mappedParticipants
            });
          });
        } else {
          const requests = response.content.map(member => {
            return VaultProvider.getEmployeeProfile(
              `phone[eq]=${encodeURIComponent(member.phone)}`
            ).then(profile => {
              const profileData = profile.data[0] || {};
              return {
                ...member,
                firstName: profileData.firstName,
                email: profileData.email
              };
            });
          });
          Promise.all(requests).then(mappedParticipants => {
            this._setParticipantList({
              ...response,
              content: mappedParticipants
            });
          });
        }
      })
      .finally(CommonStore.clearPending);
  };

  @action('InvitationStore => _setParticipantList')
  _setParticipantList = response => {
    this.lastPage = response.last;

    if (this.page === 0) {
      this.participants.replace(response.content);
      return;
    }

    const participants = [...this.participants, ...response.content];
    this.participants.replace(participants);
  };

  @action('InvitationStore => resetParticipantList')
  resetParticipantList = () => {
    this.participants.replace([]);
  };

  @action('InvitationStore => changePage') changePage = (
    page = this.page + 1
  ) => {
    this.page = page;
  };

  // TODO: del in future if not used
  getInvitations = (pageNumber = this.page, pageSize = this.rowsPerPage) => {
    CommonStore.setPending();
    let queryS = {
      page: pageNumber,
      size: pageSize
    };

    if (this.searchFilter.length) {
      queryS = { ...queryS, roleTemplateId: this.searchFilter };
    }
    if (this.query) {
      queryS = { ...queryS, phoneNumber: this.query.replace(/[^0-9+]/g, '') };
    }

    return InvitationProvider.getInvitations(queryS)
      .then(response => {
        this.setInvitations(response.content, response.total_elements);
        this.setPageParams(response.last, response.total_elements);
      })
      .catch(this._handleRequestError)
      .finally(CommonStore.clearPending);
  };

  handleRoleSelect = typeId => {
    const selectedRoles = [...this.searchFilter];
    const matchId = selectedRoles.indexOf(typeId);

    if (matchId >= 0) {
      selectedRoles.splice(matchId, 1);
    } else {
      selectedRoles.push(typeId);
    }
    this._updateSearchFilter(selectedRoles);
  };

  @action('InvitationStore => _updateSearchFilter')
  _updateSearchFilter = filter => {
    this.searchFilter.replace(filter);
  };

  @action('InvitationStore => setQuery') setQuery = query => {
    this.resetPage();
    this._updateSearchFilter([]);
    this.query = query;
    this.search();
  };

  @action('InvitationStore => clearQuery') clearQuery = () => {
    this.query = '';
    this._updateSearchFilter([]);
  };

  @action('InvitationStore => _resetOrder') _resetOrder = () => {
    this.order = 'desc';
  };

  reset = () => {
    this.clearQuery();
    this._resetOrder();
  };

  search = debounce(this.getParticipants, DELAY_REQUEST);

  @action('InvitationStore => _resetOrder') setOrder = () => {
    if (this.order === 'asc') {
      this.order = 'desc';
    } else {
      this.order = 'asc';
    }
    return this.updateParticipants();
  };

  @action('InvitationStore => updateParticipants') updateParticipants = () => {
    this.resetPage();
    this.resetParticipantList();
    return this.getParticipants();
  };

  @action('InvitationStore => updateInvitations') updateInvitations = () => {
    this.resetPage();
    this.invitations.replace([]);
    return this.getInvitations();
  };

  fetchRoleTemplates = () => {
    CommonStore.setPending();
    return RoleProvider.getRoles({ size: 3000 })
      .then(this._setRoleTemplates)
      .catch(this._handleRequestError)
      .finally(CommonStore.clearPending);
  };

  @action('InvitationStore => _setRoleTemplates')
  _setRoleTemplates = response => {
    this.roleTemplates.replace(response.content);
  };

  fetchFilteredRoles = () => {
    CommonStore.setPending();
    this._setFilteredRoleTemplates({ content: [] });
    return RoleProvider.getRoles({ size: 3000 })
      .then(this._setFilteredRoleTemplates)
      .catch(this._handleRequestError)
      .finally(CommonStore.clearPending);
  };

  @action('InvitationStore => _setFilteredRoleTemplates')
  _setFilteredRoleTemplates = response => {
    this.filteredRolesTemplates.replace(response.content);
  };

  @action('InvitationStore => setGroups') setGroups = customFields => {
    this.customFields.replace(customFields);
  };

  @action('InvitationStore => setInvitations') setInvitations = (
    invitations,
    total
  ) => {
    if (this.page === 0) {
      this.invitations.replace([]);
    }
    if (this.invitations.length === total) {
      return;
    }
    const newInvitations = [...this.invitations, ...invitations];

    this.invitations.replace(newInvitations);
  };

  inviteUser = () => {
    const {
      inviteCreateForm: {
        fields: {
          phone: { value: phone },
          role: { value: roleId },
          sendCheckbox: { value: sendSms }
        }
      }
    } = this.forms;
    const data = {
      phone: `+${phone}`,
      roles: [roleId],
      send_sms: sendSms === 'sendSms'
    };

    CommonStore.setPending();
    return InvitationProvider.createInvite(data).finally(() =>
      CommonStore.clearPending()
    );
  };

  clearPageAndForms = () => {
    this.resetForms();
    this.resetPage();
  };

  _baseInvite = ({ rowId, method }) => {
    CommonStore.setPending();

    const participant = this.currentParticipant.role_id
      ? this.currentParticipant
      : this.participants.find(invitation => rowId === invitation.phone);

    let payload = {
      phone: participant.phone
    };

    if (method === 'inviteUser') {
      payload = { ...payload, send_sms: true, roles: [participant.role_id] };
    } else {
      payload = { ...payload, role: participant.role_id };
    }

    return NpsProvider[method](payload)
      .then(this.updateParticipants)
      .catch(this._handleRequestError)
      .finally(CommonStore.clearPending);
  };

  resendInvitation = rowId => {
    return this._baseInvite({
      rowId,
      method: 'resendInvitation'
    });
  };

  sendInvitation = rowId => {
    return this._baseInvite({
      rowId,
      method: 'inviteUser'
    });
  };

  revokeInvitation = rowId => {
    return this._baseInvite({
      rowId,
      method: 'revokeInvitation'
    });
  };

  @action handleChangePage = page => {
    if (!this.lastPage) {
      this.page = page;
      this.getInvitations();
    }
  };

  getAllDecks = () => {
    CommonStore.setPending();
    return DeckProvider.getAllDecks()
      .then(this._getAllDecksHandler)
      .catch(this._handleRequestError)
      .finally(CommonStore.clearPending);
  };

  _getAllDecksHandler = decks => {
    if (isArray(decks)) {
      this.setDecks(decks);
    }
    if (decks.content && isArray(decks.content)) {
      this.setDecks(decks.content);
    }
  };

  @action('InvitationStore => setDecks') setDecks(decks) {
    const newDecks = decks.map((deck, i) => ({
      ...deck,
      key: `deck-${i}`,
      checked: false
    }));
    this.decks.replace(newDecks);
  }

  @action('InvitationStore => resetForms') resetForms = () => {
    this.forms = forms;
  };

  @action('InvitationStore => setPredefinedRole') setPredefinedRole(val) {
    this.forms.inviteCreateForm.fields.role.value = val;
  }

  @action('InvitationStore => resetPage') resetPage = () => {
    this.page = 0;
    this.rowsPerPage = 25;
  };

  getGroupByExternalId = externalId => {
    CommonStore.setPending();
    return GroupProvider.getGroupByExternalId(externalId)
      .then(this._setFinancialGroup)
      .catch(this._handleRequestError)
      .finally(CommonStore.clearPending);
  };

  @action('InvitationStore => _setFinancialGroup')
  _setFinancialGroup = response => {
    this.financialGroup = response;
  };
}

export default new InvitationStore();
