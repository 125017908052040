// Core
import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { toJS } from 'mobx';

// Utils
import { compose } from '@Utils';

// Components
import { ColumnChart, PieChart, FromToDateFilter } from '@CommonScene';
import { RiskStatusTable } from '@DashboardsScene/scenes/COVID19RiskStatus/components/RiskStatusTable';

export class COVID19RiskStatus extends React.Component {
  static propTypes = {
    RiskStatusStore: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      dateRange: {
        from: new Date(
          moment
            .utc(
              moment().add(
                moment()
                  .parseZone()
                  .utcOffset(),
                'minutes'
              )
            )
            .subtract(14, 'days')
            .startOf('day')
            .format()
        ).getTime(),
        to: new Date(
          moment
            .utc(
              moment().add(
                moment()
                  .parseZone()
                  .utcOffset(),
                'minutes'
              )
            )
            .format()
        ).getTime()
      },
      filterParams: {}
    };
  }

  componentDidMount() {
    if (localStorage.getItem('dateRange')) {
      this.setState(
        {
          dateRange: JSON.parse(localStorage.getItem('dateRange'))
        },
        () => {
          this.fetchChartData();
        }
      );
    } else {
      this.fetchChartData();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.dateRange !== this.state.dateRange ||
      JSON.stringify(prevState.filterParams) !==
        JSON.stringify(this.state.filterParams)
    ) {
      this.fetchChartData();
    }
  }

  fetchChartData = () => {
    const {
      RiskStatusStore: { getRiskStatusChartData, resetChartData }
    } = this.props;
    resetChartData();
    getRiskStatusChartData(this.getFilterParams());
  };

  getFilterParams = () => {
    return {
      'risk.fromDate': this.state.dateRange.from,
      'risk.toDate': this.state.dateRange.to,
      ...this.state.filterParams
    };
  };

  handleChangeDate = (date, key) => {
    let currentDate = date;
    if (!currentDate) {
      currentDate = key === 'from' ? 1546300800000 : Date.now();
    }
    this.setState({
      dateRange: { ...this.state.dateRange, [key]: currentDate }
    });
    localStorage.setItem(
      'dateRange',
      JSON.stringify({
        ...this.state.dateRange,
        [key]: currentDate
      })
    );
  };

  setFilterParams = filterParams => {
    this.setState({ filterParams });
  };

  getColor = text => {
    switch (text) {
      case 'Extremely high':
        return '#FE4848';
      case 'High':
        return '#FFA91E';
      case 'Unknown':
        return '#E9E2F4';
      case 'Quarantine':
        return '#9B51E0';
      case 'Low':
        return '#6FC960';
      default:
        return text;
    }
  };

  applyStylingToColumnChart = styledStackedBartData => {
    const colors = new Set();
    if (styledStackedBartData.length > 0) {
      styledStackedBartData[0].forEach((item, index) => {
        if (index !== 0) {
          styledStackedBartData[0].splice(index * 2 + (index - 1), 0, {
            role: 'annotation'
          });
          styledStackedBartData[0].splice(index * 2 + (index - 1) + 1, 0, {
            role: 'style'
          });
        }
      });

      styledStackedBartData.forEach((items, index) => {
        if (index !== 0) {
          items.forEach((item, dataIndex) => {
            const color = this.getColor(
              styledStackedBartData[0][dataIndex * 2 + (dataIndex - 1) - 1]
            );
            if (dataIndex !== 0) {
              items.splice(dataIndex * 2 + (dataIndex - 1), 0, null);
              items.splice(dataIndex * 2 + (dataIndex - 1) + 1, 0, color);
              colors.add(color);
            }
          });
        }
      });
    }
    return { styledStackedBartData, colors };
  };

  applyStylingToPieChart = styledPieChart => {
    const pichartColors = new Set();
    styledPieChart.forEach((item, index) => {
      if (index === 0) {
        styledPieChart[index].push({ role: 'style' });
      } else {
        const color = this.getColor(styledPieChart[index][0]);
        styledPieChart[index].push(color);
        pichartColors.add(color);
      }
    });
    return { styledPieChart, pichartColors };
  };

  render() {
    const {
      RiskStatusStore: { piChartData, pichartDataDate, stackbartData }
    } = this.props;

    const { styledStackedBartData, colors } = this.applyStylingToColumnChart(
      toJS(stackbartData)
    );

    const { styledPieChart, pichartColors } = this.applyStylingToPieChart(
      toJS(piChartData)
    );

    return (
      <>
        <FromToDateFilter
          handleChangeDate={this.handleChangeDate}
          dateRange={this.state.dateRange}
        />
        <p />
        <PieChart
          data={styledPieChart}
          colors={[...pichartColors]}
          title={
            pichartDataDate
              ? `Overall COVID-19 Risk Status Distribution  (${pichartDataDate})`
              : `Overall COVID-19 Risk Status Distribution`
          }
        />
        <p />
        <ColumnChart
          data={styledStackedBartData}
          colors={[...colors]}
          title="COVID-19 Risk Status Daily Statistics"
        />
        <RiskStatusTable
          dateRange={this.state.dateRange}
          setFilterParams={this.setFilterParams}
          {...this.props}
        />
      </>
    );
  }
}

export default compose(inject('RiskStatusStore')(observer(COVID19RiskStatus)));
