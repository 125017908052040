/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';

// Icons
import { Person } from '@Utils/constans/icons';

// styles
import './styles.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

// Utils
import { compose } from '@Utils';
import { formatDate } from '@Utils/formatting';
import { getMyTeamPath } from '@Utils/constans/paths';

import {
  getDashBordFilterParams,
  getDashboardSortParams
} from '@Utils/ag-grid';

import ExportButton from '@CommonScene/ExportButton';

// Components
import { BlockWrapper } from '@CommonScene';

import { Grid, withStyles } from '@material-ui/core';

import { VaultProvider } from '@Providers';

import styles from './styles';

export class CasesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: [
        {
          headerName: 'Full Name',
          field: 'profile.fullName',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            apply: true,
            newRowsAction: 'keep',
            closeOnApply: true,
            filterOptions: ['contains'],
            suppressAndOrCondition: true
          },
          editable: false,
          resizable: true,
          colId: 'fullName',
          width: 180,
          valueGetter: params => {
            return params.data &&
              params.data.profile &&
              params.data.profile.fullName
              ? params.data.profile.fullName
              : '-';
          }
        },
        {
          headerName: 'Working availability',
          field: 'workAbility',
          sortable: true,
          editable: false,
          resizable: true,
          menuTabs: ['generalMenuTab', 'columnsMenuTab'],
          colId: 'workAbility',
          valueGetter: params => {
            return params.data && params.data.workAbility
              ? params.data.workAbility
              : '-';
          }
        },
        {
          headerName: 'Team/Dept.',
          field: 'profile.teamName',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            apply: true,
            newRowsAction: 'keep',
            closeOnApply: true,
            filterOptions: ['equals'],
            suppressAndOrCondition: true
          },
          editable: false,
          resizable: true,
          width: 150,
          colId: 'team',
          valueGetter: params => {
            return params.data &&
              params.data.profile &&
              params.data.profile.teamName
              ? params.data.profile.teamName
              : '-';
          }
        },
        {
          headerName: 'Office',
          field: 'profile.officeAddress',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            apply: true,
            newRowsAction: 'keep',
            closeOnApply: true,
            filterOptions: ['equals'],
            suppressAndOrCondition: true
          },
          editable: false,
          resizable: true,
          colId: 'office',
          valueGetter: params => {
            return params.data &&
              params.data.profile &&
              params.data.profile.officeAddress
              ? params.data.profile.officeAddress
              : '-';
          }
        },
        {
          headerName: 'Country',
          field: 'profile.address.city.country',
          sortable: true,
          editable: false,
          filter: 'agTextColumnFilter',
          filterParams: {
            apply: true,
            newRowsAction: 'keep',
            closeOnApply: true,
            filterOptions: ['equals'],
            suppressAndOrCondition: true
          },
          resizable: true,
          colId: 'country',
          width: 180,
          hide: true,
          valueGetter: params => {
            return params.data &&
              params.data.profile &&
              params.data.profile.address &&
              params.data.profile.address.city &&
              params.data.profile.address.city.country
              ? params.data.profile.address.city.country
              : '-';
          }
        },
        {
          headerName: 'Region',
          field: 'profile.address.city.region',
          sortable: true,
          editable: false,
          filter: 'agTextColumnFilter',
          filterParams: {
            apply: true,
            newRowsAction: 'keep',
            closeOnApply: true,
            filterOptions: ['equals'],
            suppressAndOrCondition: true
          },
          resizable: true,
          colId: 'region',
          width: 180,
          hide: true,
          valueGetter: params => {
            return params.data &&
              params.data.profile &&
              params.data.profile.address &&
              params.data.profile.address.city &&
              params.data.profile.address.city.region
              ? params.data.profile.address.city.region
              : '-';
          }
        },
        {
          headerName: 'Test Date',
          field: 'testDate',
          valueGetter: params => {
            return params.data && params.data.testDate
              ? formatDate(params.data.testDate)
              : '-';
          },
          keyCreator(params) {
            return formatDate(params.value, 'MM/DD/YYYY');
          },
          editable: false,
          resizable: true,
          sortable: true,
          colId: 'testDate',
          menuTabs: ['generalMenuTab', 'columnsMenuTab'],
          width: 150
        },
        {
          headerName: 'Reported Date',
          field: 'statusAt',
          valueGetter: params => {
            return params.data && params.data.statusAt
              ? formatDate(params.data.statusAt)
              : '-';
          },
          keyCreator(params) {
            return formatDate(params.value, 'MM/DD/YYYY');
          },
          editable: false,
          resizable: true,
          sortable: true,
          menuTabs: ['generalMenuTab', 'columnsMenuTab'],
          colId: 'statusAt'
        }
      ],
      rowSelection: 'multiple',
      rowData: [],
      paginationPageSize: 25,
      cacheBlockSize: 25,
      pagination: true,
      rowModelType: 'serverSide',
      maxBlocksInCache: 1,
      suppressExcelExport: true,
      suppressCsvExport: true,
      loadedData: [],
      defaultColDef: {
        flex: 1,
        resizable: true,
        enableValue: true,
        enableRowGroup: false,
        enablePivot: true,
        sortable: true,
        filter: true,
        editable: true
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressRowGroups: false
            }
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel'
          }
        ],
        multiSortKey: 'ctrl',
        defaultToolPanel: ''
      }
    };
  }

  static propTypes = {
    store: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    dateRange: PropTypes.object,
    COVID19ReportStore: PropTypes.object.isRequired,
    COVID19FamilyReportStore: PropTypes.object.isRequired,
    location: PropTypes.object,
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  componentDidUpdate(prevProps) {
    if (prevProps.dateRange !== this.props.dateRange && this.gridApi) {
      this.gridApi.purgeServerSideCache();
      this.setState({ loadedData: [] });
    }
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
    params.api.setServerSideDatasource(this.serverSideDatasource());
  };

  serverSideDatasource = () => {
    return {
      getRows: params => {
        const filterParams = getDashBordFilterParams(
          params.request.filterModel
        );
        const sortParams = getDashboardSortParams(params.request.sortModel);
        const queryParams = {
          offset: params.request.startRow,
          limit: params.request.endRow - params.request.startRow,
          sort: sortParams || 'statusAt%2Cdesc',
          'testResult.fromDate': this.props.dateRange.from,
          'testResult.toDate': this.props.dateRange.to,
          'testResult.type': this.props.type
        };

        const param =
          Object.keys(queryParams)
            .map(key => `${key}=${queryParams[key]}`)
            .join('&') + filterParams;

        const chartParams = Object.keys(params.request.filterModel).reduce(
          (acc, key) => {
            if (
              key === 'fullName' ||
              key === 'team' ||
              key === 'office' ||
              key === 'country'
            ) {
              acc[`profile.${key}`] = params.request.filterModel[key].filter;
            }
            return acc;
          },
          {}
        );

        const { COVID19ReportStore, COVID19FamilyReportStore } = this.props;

        const currentPathname = this.props.location.pathname;

        // FIXME: Bad temporary solution. Need to remove path dependencies in future
        if (currentPathname === '/dashboards') {
          COVID19ReportStore.resetOffset();
          COVID19ReportStore.getCovidChartData({
            ...chartParams,
            'testResult.fromDate': this.props.dateRange.from,
            'testResult.toDate': this.props.dateRange.to
          });
        } else {
          COVID19FamilyReportStore.resetOffset();
          COVID19FamilyReportStore.getCovidChartData({
            ...chartParams,
            'testResult.fromDate': this.props.dateRange.from,
            'testResult.toDate': this.props.dateRange.to
          });
        }

        return VaultProvider.getCovidTestResultOverallReport(param).then(
          profilesResponse => {
            this.setLoadedData(profilesResponse.data);
            if (profilesResponse.data.length > 0) {
              params.successCallback(
                profilesResponse.data,
                profilesResponse.pagination.total
              );
            } else {
              params.successCallback(profilesResponse.data, 0);
              params.api.showNoRowsOverlay();
            }
          }
        );
      }
    };
  };

  clearAllFilter = () => {
    this.gridApi.setFilterModel({});
    this.gridApi.setSortModel({});
    this.setState({ loadedData: [] });
  };

  setLoadedData = data => {
    if (this.state.loadedData.length > 0) {
      const allreadyLoadedData = [...this.state.loadedData];
      data.forEach(newItem => {
        const exists = allreadyLoadedData.find(
          item => item.profile.email === newItem.profile.email
        );
        if (!exists) {
          allreadyLoadedData.push(newItem);
        }
      });
      this.setState({ loadedData: allreadyLoadedData });
    } else {
      this.setState({ loadedData: data });
    }
  };

  getCSVData = data => {
    return data.map(item => {
      return {
        'Full Name': `${item.profile.firstName}  ${item.profile.lastName}`,
        'Working availability': item.workAbility,
        'Team/Dept.': item.profile && item.profile.teamName,
        Office: item.profile && item.profile.officeAddress,
        Country: item.profile.address && item.profile.address.city.country,
        'Test Date': formatDate(item.testDate, 'MM/DD/YYYY'),
        'Reported Date': formatDate(item.statusAt, 'MM/DD/YYYY')
      };
    });
  };

  render() {
    const { title, classes, history } = this.props;
    const { loadedData } = this.state;
    return (
      <>
        <BlockWrapper>
          <div className="ag-theme-alpine">
            <div className="agTableHeaderContent">
              <Person />
              <h1 className="agTableHeader">{title}</h1>
              <button
                className="clear-all-dashboard"
                onClick={this.clearAllFilter}
              >
                Clear Filter
              </button>
              {loadedData.length > 0 && (
                <div className="export-btn-dashboard">
                  <ExportButton
                    count={loadedData.length}
                    jsonData={this.getCSVData(loadedData)}
                  />
                </div>
              )}
            </div>
          </div>
          <Grid container className={classes.tableWrapper}>
            <div className="ag-theme-alpine" style={{ width: '100%' }}>
              <AgGridReact
                columnDefs={this.state.columnDefs}
                paginationPageSize={this.state.paginationPageSize}
                pagination={this.state.pagination}
                defaultColDef={this.state.defaultColDef}
                rowSelection={this.state.rowSelection}
                sideBar={this.state.sideBar}
                multiSortKey={this.state.multiSortKey}
                onGridReady={this.onGridReady}
                rowModelType={this.state.rowModelType}
                maxBlocksInCache={this.state.maxBlocksInCache}
                cacheBlockSize={this.state.cacheBlockSize}
                suppressExcelExport={this.state.suppressExcelExport}
                suppressCsvExport={this.state.suppressCsvExport}
                onFilterChanged={() => this.setState({ loadedData: [] })}
                suppressRowClickSelection
                onRowClicked={params => {
                  if (params.data && params.event.target.textContent !== '') {
                    history.push(
                      `${getMyTeamPath.teamMember()}/${
                        params.data.profile.phone
                      }?backLinkKey=${
                        this.props.type === 'SELF' ? 'covidSelf' : 'covidFamily'
                      }`
                    );
                  }
                }}
                rowStyle={{ cursor: 'pointer' }}
              />
            </div>
          </Grid>
        </BlockWrapper>
      </>
    );
  }
}

export default compose(
  withStyles(styles),
  inject('COVID19ReportStore', 'COVID19FamilyReportStore'),
  withRouter,
  observer
)(CasesTable);
