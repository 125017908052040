/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

// Material UI
import { Box } from '@material-ui/core';

// Components
import { SelectOptions, TextField } from '@CommonScene';
import FieldWrapper from '@CommonScene/FieldWrapper';

// Utils
import { compose } from '@Utils';

// Styles
import useStyles from './styles';

const QuestionBlock = ({ index, questions, formConfig, onChangeCallback }) => {
  const classes = useStyles();

  const handleOnChange = (e, fieldType) => {
    const { name, value } = e.target;
    formConfig.onFieldChange(name, value, formConfig.name);
    if (fieldType === 'select') {
      formConfig.markSelectedQuestion(value, index);
    }
    if (onChangeCallback) {
      onChangeCallback();
    }
  };

  const isItemDisabled = item => {
    return item.selected;
  };

  const hasError = formConfig.fields[`answer_${index}`].error !== null;
  return (
    <Box>
      <SelectOptions
        name={`question_${index}`}
        items={questions}
        disableItemCallback={isItemDisabled}
        value={formConfig.fields[`question_${index}`].value}
        onChange={e => handleOnChange(e, 'select')}
        label={`Question ${index}`}
        className={classes.fieldWrapper}
        selectClassName={classes.select}
      />
      <FieldWrapper
        label=""
        className={!hasError ? classes.fieldWrapper : null}
      >
        <TextField
          name={`answer_${index}`}
          onChange={handleOnChange}
          value={formConfig.fields[`answer_${index}`].value}
          error={formConfig.fields[`answer_${index}`].error}
        />
      </FieldWrapper>
    </Box>
  );
};

QuestionBlock.propTypes = {
  index: PropTypes.number.isRequired,
  questions: PropTypes.array.isRequired,
  formConfig: PropTypes.object.isRequired,
  onChangeCallback: PropTypes.func
};

export default compose(observer)(QuestionBlock);
