/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import crypto from 'crypto';
import hdKey from 'ethereumjs-wallet/hdkey';
import Wallet from 'ethereumjs-wallet';
import { generateMnemonic as genMnemonic, mnemonicToSeed } from 'bip39';

const ALGO = 'aes-256-cbc';
const IV_LENGTH = 16; // For AES, this is always 16

export function generateMnemonic() {
  return genMnemonic();
}

export function convertToSha256(text) {
  const hash = crypto.createHash('sha256');
  hash.update(text);
  return hash.digest().toString('hex');
}

export function normalizePassword(hash) {
  return hash.toLowerCase().substr(0, 32);
}

function getWallet(mnemonic) {
  return mnemonicToSeed(mnemonic).then(seed =>
    hdKey
      .fromMasterSeed(seed)
      .derivePath("m/44'/60'/0'/0/0")
      .getWallet()
  );
}

export function transformMnemonicToPrivateKey(mnemonic) {
  return getWallet(mnemonic).then(wallet =>
    wallet.getPrivateKeyString().substring(2)
  );
}

export function transformMnemonicToPublicKey(mnemonic) {
  const wallet = getWallet(mnemonic);

  return wallet.getPublicKeyString().substring(2);
}

export function getBlockchainAddress(privateKey) {
  const privateKeyBuffer = Buffer.from(privateKey, 'hex');
  const newWallet = Wallet.fromPrivateKey(privateKeyBuffer);
  return newWallet.getAddressString();
}

export function encrypt(key, normalizedPin) {
  const iv = crypto.randomBytes(IV_LENGTH);
  const cipher = crypto.createCipheriv(ALGO, Buffer.from(normalizedPin), iv);
  let encrypted = cipher.update(key);

  encrypted = Buffer.concat([encrypted, cipher.final()]);

  return iv.toString('hex') + encrypted.toString('hex');
}

export function decrypt(key, normalizedPin) {
  const tBuf = Buffer.from(key, 'hex');
  const iv = Buffer.alloc(IV_LENGTH);
  const encryptedText = Buffer.alloc(tBuf.length - IV_LENGTH);

  tBuf.copy(iv, 0, 0, IV_LENGTH);
  tBuf.copy(encryptedText, 0, IV_LENGTH, tBuf.length);

  const decipher = crypto.createDecipheriv(
    ALGO,
    Buffer.from(normalizedPin),
    iv
  );
  let decrypted = decipher.update(encryptedText);

  decrypted = Buffer.concat([decrypted, decipher.final()]);

  return decrypted.toString();
}
