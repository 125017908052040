/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import { Grid } from '@material-ui/core';

// Components
import { IconDownloadGray } from '@Utils/constans/icons';
import { CustomTooltip } from '@CommonScene';

// Utils
import { useStore } from '@Utils/hooks';
import shortid from 'shortid';
import classNames from 'classnames';

// Styles
import useStyles from './styles';

const LogActions = ({ rowId, currentStatus, fileName }) => {
  const classes = useStyles();
  const {
    ImportLogStore: { downloadImportLogFile }
  } = useStore();

  const handleDownload = () => {
    const fileGuid = rowId.fileGuid;
    downloadImportLogFile(fileGuid, fileName);
  };
  const menuItems = [
    {
      name: 'Download',
      currentStatus: 'FINISHED',
      icon: <IconDownloadGray />,
      handleClick: handleDownload
    }
  ];

  const filterMenuItems = () => {
    const downloadSheets = ['FINISHED'];
    if (downloadSheets.includes(currentStatus)) {
      return [menuItems[0]];
    }
    return [];
  };

  const handleItemClick = (event, handler) => {
    event.stopPropagation();
    handler();
    // extraId used for specific id for redirection
  };

  return (
    <Grid container justify="flex-end">
      {filterMenuItems().length
        ? filterMenuItems().map(item => (
            <CustomTooltip key={shortid.generate()} label={item.name}>
              <span
                className={classNames(classes.actionIconWrapper, 'action-item')}
                onClick={e =>
                  handleItemClick(e, item.handleClick, item.isRedirect)
                }
              >
                {item.icon}
              </span>
            </CustomTooltip>
          ))
        : null}
    </Grid>
  );
};

LogActions.propTypes = {
  rowId: PropTypes.number.isRequired,
  currentStatus: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired
};

LogActions.defaultProps = {};

export default LogActions;
