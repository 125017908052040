/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import {
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  IconButton,
  Typography
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

// Utils
import { compose } from '@Utils';

// Styles
import styles from './styles';

const Licenses = ({ classes, label }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <>
      <div>
        <span onClick={() => setIsOpen(true)}>{label}</span>
      </div>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle className={classes.header} id="form-dialog-title">
          {`Licenses`}
        </DialogTitle>
        <IconButton
          id="cancel-icon"
          classes={{ root: classes.close }}
          onClick={() => setIsOpen(false)}
        >
          <Close />
        </IconButton>
        <DialogContent>
          <DialogContentText>
            <Typography variant="body2" className={classes.bodyText}>
              For proper functioning of Travel Tracker in Team.Care Network,
              Basic World Cities Database, (
              <a
                href="https://simplemaps.com/data/world-cities"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.bodyText}
              >
                https://simplemaps.com/data/world-cities
              </a>
              ) provided by Pareto Software, LLC under the Creative Commons
              Attribution 4.0 license as described at&nbsp;
              <a
                href="https://creativecommons.org/licenses/by/4.0/"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.bodyText}
              >
                https://creativecommons.org/licenses/by/4.0/
              </a>
              , was copied, turned into JSON format and uploaded to Solve.Care
              data service.
            </Typography>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

Licenses.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.any.isRequired
};

export default compose(withStyles(styles))(Licenses);
