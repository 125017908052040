/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { toJS } from 'mobx';

// Material UI
import { AppBar, Tabs, Tab, Typography, Grid } from '@material-ui/core';

// Components
import HeaderTitle from '@AuthScene/scenes/common/HeaderTitle';
import ResetPasswordButtons from '@AuthScene/scenes/ResetPassword/components/ResetPasswordButtons';
import TabWrap from '@AuthScene/scenes/ResetPassword/components/TabWrap';
import { TextField } from '@CommonScene';
import QuestionsBlocks from '@AuthScene/scenes/SignUp/components/RecoveryQuestions/components/QuestionsBlocks';
import ErrorSection from '@AuthScene/scenes/common/ErrorSection';

// Utils
import { useStore } from '@Utils/hooks';
import { compose, serializeQuestions } from '@Utils';
import { getPublicPath, getMyTeamPath } from '@Utils/constans/paths';
import {
  transformMnemonicToPrivateKey,
  normalizePassword,
  convertToSha256,
  encrypt,
  decrypt,
  getBlockchainAddress
} from '@Utils/cryptographic';

// Styles
import useStyles from './styles';

function getTabProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
    disableRipple: true
  };
}

const AccountRecovery = ({ history }) => {
  const { t } = useTranslation();
  const {
    AuthStore: {
      onFieldChange,
      forms,
      updateContainer,
      getContainer,
      recoveryQuestionsIds,
      recoveryQuestions,
      resetStore,
      login,
      errors,
      clearErrorMessage
    }
  } = useStore();

  const [tab, setTab] = useState(0);
  const [recoveryQuestionsErrors, setRecoveryQuestionsErrors] = useState(null);
  const classes = useStyles();

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const recoveryForms = ['recoveryFromAnswers', 'recoveryFromMnemonic'];

  const onChange = e => {
    if (e.target.name === 'phrase') {
      clearErrorMessage();
    }
    onFieldChange(e.target.name, e.target.value, recoveryForms[tab]);
  };

  const clearErrors = () => {
    setRecoveryQuestionsErrors(null);
    clearErrorMessage();
  };

  const handleBack = () => {
    clearErrors();
    resetStore();
    history.replace(getPublicPath.login());
  };

  const storePK = containerData => {
    const PK = decrypt(
      containerData.container,
      normalizePassword(
        convertToSha256(forms.resetPassword.fields.password.value)
      )
    );
    window.localStorage.setItem('PK', PK);
    resetStore();
    clearErrors();
    history.replace(getMyTeamPath.myTeam());
  };

  let _errors = errors;
  if (_errors === 'Recovery phrase does not belong to this account') {
    _errors = 'You have entered a wrong recovery phrase';
  }
  const handleSubmitForm = e => {
    e.preventDefault();
    const payload = {
      app_version: 'default_app_version',
      enc_version: 'default_enc_version',
      type: 'DEFAULT',
      challenges: toJS(recoveryQuestionsIds),
      container: ''
    };
    const passwordPassPhrase = normalizePassword(
      convertToSha256(forms.resetPassword.fields.password.value)
    );
    if (tab === 0) {
      const recoveryQuestionsFormFields = forms.recoveryFromAnswers.fields;

      const serializedQestionsString = serializeQuestions(
        recoveryQuestionsFormFields,
        recoveryQuestions
      );

      getContainer('RESTORE').then(containerData => {
        const questionsPassPhrase = normalizePassword(
          convertToSha256(serializedQestionsString)
        );

        try {
          const privateKey = decrypt(
            containerData.container,
            questionsPassPhrase
          );
          const container = encrypt(privateKey, passwordPassPhrase);
          payload.container = container;

          updateContainer(payload).then(storePK);
        } catch (error) {
          setRecoveryQuestionsErrors(
            'Selected recovery questions and/or entered answers are wrong.'
          );
        }
      });
    } else if (tab === 1) {
      transformMnemonicToPrivateKey(
        forms.recoveryFromMnemonic.fields.phrase.value
      ).then(privateKey => {
        payload.container = encrypt(privateKey, passwordPassPhrase);
        login({
          password: forms.resetPassword.fields.password.value,
          blockchainAddress: getBlockchainAddress(privateKey),
          hasMnemonic: true
        }).then(() => {
          updateContainer(payload).then(storePK);
        });
      });
    }
  };

  const clearRecoveryQuestionsErrors = () => {
    setRecoveryQuestionsErrors(null);
  };

  return (
    <form onSubmit={handleSubmitForm} className={classes.form}>
      <HeaderTitle>{t('auth.resetPassword.stage3.heading')}</HeaderTitle>

      <Typography classes={{ body1: classes.subheader }}>
        {t('auth.resetPassword.stage3.description')}
      </Typography>

      <AppBar position="static" classes={{ colorPrimary: classes.appBar }}>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          classes={{
            root: classes.rootTabs,
            flexContainer: classes.tabs,
            indicator: classes.indicator
          }}
        >
          <Tab
            label={t('auth.resetPassword.stage3.firstTab')}
            classes={{ root: classes.tab, selected: classes.tabSelected }}
            {...getTabProps(0)}
          />
          <Tab
            label={t('auth.resetPassword.stage3.secondTab')}
            classes={{ root: classes.tab, selected: classes.tabSelected }}
            {...getTabProps(1)}
          />
        </Tabs>
      </AppBar>

      <Grid
        container
        direction="column"
        className={classes.fieldsWrapper}
        justify="space-between"
      >
        <Grid item>
          <TabWrap tab={tab} index={0}>
            <Grid className={classes.questionsBlock}>
              <ErrorSection errors={recoveryQuestionsErrors} />
              <QuestionsBlocks
                formName="recoveryFromAnswers"
                onChangeCallback={clearRecoveryQuestionsErrors}
              />
            </Grid>
          </TabWrap>
          <TabWrap tab={tab} index={1}>
            <TextField
              placeholder="Enter your recovery phrase here..."
              value={forms.recoveryFromMnemonic.fields.phrase.value}
              multiline
              rows="2"
              name="phrase"
              onChange={onChange}
              error={_errors !== null}
            />
            <ErrorSection errors={_errors} textOnly noMargin />
          </TabWrap>
        </Grid>
        <Grid item>
          <ResetPasswordButtons
            backBtnText={'Login'}
            nextBtnText={t('common.submit')}
            backBtnOnclickHandler={handleBack}
            primaryBtnDisabled={!forms[recoveryForms[tab]].meta.isValid}
          />
        </Grid>
      </Grid>
    </form>
  );
};

AccountRecovery.propTypes = {
  history: PropTypes.object
};

export default compose(
  withRouter,
  observer
)(AccountRecovery);
