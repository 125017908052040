/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Material UI
import { Grid, withStyles, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

// Components
import { BlockWrapper } from '@CommonScene';

// Utils
import { compose } from '@Utils';

// Styles
import styles from './styles';

const Loader = withStyles(styles)(({ classes, ...props }) => (
  <Skeleton className={classes.loader} {...props} />
));

const GroupCaseWidget = ({ classes, label, data = [], isLoading = false }) => {
  const { t } = useTranslation();

  const total = React.useMemo(() => {
    return data.reduce((sum, { value }) => sum + value, 0);
  }, [data]);

  return (
    <BlockWrapper className={classes.container}>
      <Grid container>
        <Grid
          container
          item
          direction="row"
          justify={'space-between'}
          className={classes.header}
        >
          <Typography className={classes.label}>{label}</Typography>
          <Typography className={classes.label}>Cases</Typography>
        </Grid>

        <Grid container item className={classes.dataContainer}>
          {isLoading &&
            new Array(4).fill().map((_, i) => (
              <Grid
                key={i}
                container
                item
                direction="row"
                justify={'space-between'}
                className={classes.item}
                alignItems={'center'}
              >
                <Loader width={150} />
                <Loader width={50} />
              </Grid>
            ))}

          {!isLoading && !data.length && (
            <Grid container justify={'center'}>
              <Typography className={(classes.value, classes.empty)}>
                {t('common.noRecords')}
              </Typography>
            </Grid>
          )}

          {!isLoading &&
            data.map((item, i) => (
              <Grid
                key={i}
                container
                item
                direction="row"
                justify={'space-between'}
                className={classes.item}
                alignItems={'center'}
              >
                <Typography className={classes.value}>{item.label}</Typography>
                <Typography className={classes.value}>{item.value}</Typography>
              </Grid>
            ))}
        </Grid>
        <Grid
          container
          item
          direction="row"
          justify={'space-between'}
          className={classes.footer}
        >
          <Typography className={classes.label}>Total</Typography>
          {isLoading ? (
            <Loader width={50} />
          ) : (
            <Typography className={classes.label}>
              {!isLoading && !data.length ? '-' : total}
            </Typography>
          )}
        </Grid>
      </Grid>
    </BlockWrapper>
  );
};

GroupCaseWidget.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool
};

export default compose(withStyles(styles))(GroupCaseWidget);
