/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import shortid from 'shortid';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// Material UI
import { Grid, List, withStyles } from '@material-ui/core';

// Utils
import { compose } from '@Utils';
import { getParsedCookies } from '@Utils/formatting';
import { menuSections } from '@Utils/constans/privateRoutes';
import { getMyTeamPath } from '@Utils/constans/paths';

// Components
import MenuSectionComponent from './MenuSection';

// Styles
import styles from './styles';

export class Sidebar extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    currentPath: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired
  };

  state = {
    logoUrl: `/public/img/${process.env.LOGO_URL || 'Logo-ACN.svg'}`
  };

  getSections = () => {
    const permissionNames = getParsedCookies().permissionNames || [];
    const sections = [];

    // TODO: Complicated solution - refactor it
    menuSections.forEach(currentSection => {
      const section = currentSection;
      const modifiedSection = {
        title: section.title,
        icon: section.icon
      };
      let filteredRoutes;
      if (section.routes) {
        filteredRoutes = section.routes.filter(route =>
          route.roles.some(role => permissionNames.includes(role))
        );
      } else {
        filteredRoutes =
          section.route.roles.some(role => permissionNames.includes(role)) &&
          section.route;
      }
      if (filteredRoutes.length) {
        sections.push({
          ...modifiedSection,
          routes: filteredRoutes
        });
      } else {
        sections.push({
          ...modifiedSection,
          path: filteredRoutes.path
        });
      }
    });

    return sections;
  };

  validateImageSrc = () => {
    const img = new Image();
    img.src = this.state.logoUrl;

    // set default logo if image not found
    img.onerror = () => {
      this.setState({ logoUrl: '/public/img/Logo-ACN.svg' });
    };
  };

  render() {
    const { classes, currentPath, t } = this.props;
    const sections = this.getSections();

    this.validateImageSrc();

    return (
      <Grid item xs={3} md={2} className={classes.wrapper}>
        <Grid
          container
          direction="column"
          justify="space-between"
          wrap="nowrap"
          className={classes.innerSidebar}
        >
          <Grid item className={classes.mainContentBlock}>
            <div className={classes.logoBlock}>
              <Link to={getMyTeamPath.myTeam()}>
                <img src={this.state.logoUrl} alt="Logo" />
              </Link>
            </div>
            <List component="nav" className={classes.list}>
              {sections.map(menuSection => (
                <MenuSectionComponent
                  key={shortid.generate()}
                  section={menuSection}
                  currentPath={currentPath}
                />
              ))}
            </List>
          </Grid>
          <Grid item>
            <p className={classes.footerText}>{t('footer.slogan')}</p>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default compose(
  withStyles(styles),
  withTranslation(),
  observer
)(Sidebar);
