// Core
import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { toJS } from 'mobx';
import moment from 'moment';

// Material UI
import { withStyles } from '@material-ui/core';

// Utils
import { compose } from '@Utils';

// Components
import { BarChart, SurveyChartResponse, FromToDateFilter } from '@CommonScene';
import QuestionsTable from '@DashboardsScene/components/QuestionsTable';

// Constants
import styles from './styles';

export class EmployeesWellBeing extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    QuestionStore: PropTypes.object.isRequired,
    chartTitle: PropTypes.string,
    tableTitle: PropTypes.string,
    listDataFetchParam: PropTypes.string,
    CommonStore: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      dateRange: {
        from: new Date(
          moment
            .utc(
              moment().add(
                moment()
                  .parseZone()
                  .utcOffset(),
                'minutes'
              )
            )
            .subtract(14, 'days')
            .startOf('day')
            .format()
        ).getTime(),
        to: new Date(
          moment
            .utc(
              moment().add(
                moment()
                  .parseZone()
                  .utcOffset(),
                'minutes'
              )
            )
            .format()
        ).getTime()
      },
      filterParams: ''
    };
  }

  componentDidMount() {
    if (localStorage.getItem('dateRange')) {
      this.setState(
        {
          dateRange: JSON.parse(localStorage.getItem('dateRange'))
        },
        () => {
          this.fetchSummaryData();
        }
      );
    } else {
      this.fetchSummaryData();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.dateRange !== this.state.dateRange ||
      prevState.filterParams !== this.state.filterParams
    ) {
      this.fetchSummaryData();
    }
  }

  componentWillUnmount() {
    const {
      QuestionStore: { resetSummaryData }
    } = this.props;
    resetSummaryData();
  }

  fetchSummaryData = () => {
    const {
      QuestionStore: { getSummaryData, resetSummaryData },
      listDataFetchParam
    } = this.props;
    resetSummaryData();
    getSummaryData(listDataFetchParam, this.getFilterParams());
  };

  handleChangeDate = (date, key) => {
    let currentDate = date;
    if (!currentDate) {
      currentDate = key === 'from' ? 1546300800000 : Date.now();
    }
    this.setState({
      dateRange: { ...this.state.dateRange, [key]: currentDate }
    });
    localStorage.setItem(
      'dateRange',
      JSON.stringify({
        ...this.state.dateRange,
        [key]: currentDate
      })
    );
  };

  getFilterParams = () => {
    return {
      'question.fromDate': this.state.dateRange.from,
      'question.toDate': this.state.dateRange.to,
      ...this.state.filterParams
    };
  };

  setFilterParams = filterParams => {
    this.setState({ filterParams });
  };

  getColor = text => {
    switch (text) {
      case 'Good':
        return '#6FC960';
      case 'Neutral':
        return '#FFA91E';
      case 'Not So good':
      case 'Disagree':
        return '#9B51E0';
      case 'Yes, working from home':
      case 'Yes':
        return '#3D8EED';
      case 'Yes, working from office':
        return '#4657ED';
      case 'I am not working':
      case 'No':
        return '#FE4848';
      case 'My job requires it':
        return '#F35598';
      case 'My choice':
        return '#6C14BF';
      case 'Agree':
        return '#6FC960';
      default:
        return '#9B51E0';
    }
  };

  applyStylingToChart = chartData => {
    const colors = new Set();
    if (chartData.length > 0) {
      chartData[0].forEach((item, index) => {
        if (index !== 0) {
          chartData[0].splice(index * 2 + (index - 1), 0, {
            role: 'annotation'
          });
          chartData[0].splice(index * 2 + (index - 1) + 1, 0, {
            role: 'style'
          });
        }
      });

      chartData.forEach((items, index) => {
        if (index !== 0) {
          items.forEach((item, dataIndex) => {
            if (dataIndex !== 0) {
              items.splice(
                dataIndex * 2 + (dataIndex - 1),
                0,
                items[dataIndex * 2 + (dataIndex - 1) - 1] > 0
                  ? items[dataIndex * 2 + (dataIndex - 1) - 1]
                  : null
              );
              items.splice(
                dataIndex * 2 + (dataIndex - 1) + 1,
                0,
                this.getColor(chartData[0][dataIndex * 2 + (dataIndex - 1) - 1])
              );
              colors.add(
                this.getColor(chartData[0][dataIndex * 2 + (dataIndex - 1) - 1])
              );
            }
          });
        }
      });
    }
    return { chartData, colors };
  };

  render() {
    const {
      classes,
      QuestionStore: { statusGroup, summaryData },
      CommonStore: { pending }
    } = this.props;

    const { chartData, colors } = this.applyStylingToChart(toJS(summaryData));
    return (
      <>
        <FromToDateFilter
          handleChangeDate={this.handleChangeDate}
          dateRange={this.state.dateRange}
        />
        <p />
        <div className={classes.barChartWrapper}>
          <BarChart
            data={chartData}
            title={this.props.chartTitle}
            width={'100%'}
            height={'250px'}
            chartType={'BarChart'}
            pending={pending}
            colors={[...colors]}
          />
        </div>
        <SurveyChartResponse
          answered={toJS(statusGroup).ANSWERED}
          notAnswered={toJS(statusGroup).PENDING}
          pending={pending}
        />
        <QuestionsTable
          listDataFetchParam={this.props.listDataFetchParam}
          tableTitle={this.props.tableTitle}
          dateRange={this.state.dateRange}
          setFilterParams={this.setFilterParams}
          {...this.props}
        />
      </>
    );
  }
}

export default withStyles(styles)(
  compose(inject('QuestionStore', 'CommonStore')(observer(EmployeesWellBeing)))
);
