/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

// Material UI
import { withStyles, Grid } from '@material-ui/core';

// Components
import HeaderTitle from '@AuthScene/scenes/common/HeaderTitle';
import ResetPasswordButtons from '@AuthScene/scenes/ResetPassword/components/ResetPasswordButtons';
import PasswordFields from '@AuthScene/scenes/common/PasswordFields';

// Utils
import { compose } from '@Utils';
import { getPublicPath } from '@Utils/constans/paths';

// Styles
import styles from './styles';

export class ResetPasswordForm extends Component {
  static propTypes = {
    AuthStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired
  };

  componentDidMount() {
    const {
      AuthStore: { clearInputFields }
    } = this.props;

    clearInputFields('resetPassword', ['password', 'passwordConfirm']);
  }

  goToLoginPage = () => {
    const {
      AuthStore: { logout },
      history
    } = this.props;

    logout();
    history.replace(getPublicPath.login());
  };

  handleSubmitForm = e => {
    e.preventDefault();

    const {
      AuthStore: { authData, resetExpiredPassword }
    } = this.props;

    if (authData.expiredPassword) {
      return resetExpiredPassword().then(this.goToLoginPage);
    }

    return this.props.AuthStore.resetPassword().then(() => {
      this.props.AuthStore.handleChangeForm(4);
    });
  };

  // handleBack = () => {
  //   const {
  //     AuthStore: { authData, handleChangeForm }
  //   } = this.props;

  //   if (authData.expiredPassword) {
  //     this.goToLoginPage();
  //   } else {
  //     handleChangeForm(2);
  //   }
  // };

  render() {
    const {
      classes,
      AuthStore: { forms, errors, onFieldChange },
      t
    } = this.props;
    const isPrimaryBtnDisabled =
      !forms.resetPassword.fields.password.value.length ||
      forms.resetPassword.fields.passwordConfirm.value !==
        forms.resetPassword.fields.password.value;

    return (
      <form onSubmit={this.handleSubmitForm} className={classes.wrapper}>
        <HeaderTitle>{t('auth.resetPassword.stage4.heading')}</HeaderTitle>

        <Grid container direction="column" className={classes.fieldsWrapper}>
          <PasswordFields
            errors={errors}
            passwordValue={forms.resetPassword.fields.password.value}
            confirmValue={forms.resetPassword.fields.passwordConfirm.value}
            onFieldChange={onFieldChange}
          />
        </Grid>

        <ResetPasswordButtons
          backBtnText={t('auth.login.loginButton')}
          nextBtnText={t('common.save')}
          backBtnOnclickHandler={this.goToLoginPage}
          primaryBtnDisabled={isPrimaryBtnDisabled}
        />
      </form>
    );
  }
}

export default compose(
  withStyles(styles),
  withTranslation(),
  inject('AuthStore'),
  withRouter,
  observer
)(ResetPasswordForm);
