/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

// Components
import { MainContentWrapper } from '@CommonScene';
import COVID19 from '@DashboardsScene/scenes/COVID19/container';
import COVID19Family from '@DashboardsScene/scenes/COVID19Family/container';
import COVID19RiskStatus from '@DashboardsScene/scenes/COVID19RiskStatus/container';
import ReportedTrips from '@DashboardsScene/scenes/ReportedTrips/container';
import HAYFT from '@DashboardsScene/scenes/HAYFT/container';

// Utils
import { compose } from '@Utils';
import { getReportPath } from '@Utils/constans/paths';

// Constants
import { Route, Switch, withRouter } from 'react-router-dom';

export class Dashboards extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
  };

  getHeaderOptions() {
    return '';
  }

  getTabsOptions() {
    return {
      tabs: [
        {
          title: 'Employee COVID-19 tests',
          isActive: this.props.location.pathname === getReportPath.covid19(),
          onClick: () => {
            this.props.history.push({
              pathname: getReportPath.covid19()
            });
          }
        },
        {
          title: 'Family COVID-19 tests',
          isActive:
            this.props.location.pathname === getReportPath.covid19Family(),
          onClick: () => {
            this.props.history.push({
              pathname: getReportPath.covid19Family()
            });
          }
        },
        {
          title: 'Travel',
          isActive: this.props.location.pathname === getReportPath.trips(),
          onClick: () => {
            this.props.history.push({
              pathname: getReportPath.trips()
            });
          }
        },
        {
          title: 'COVID-19 Risk status',
          isActive:
            this.props.location.pathname === getReportPath.covid19RiskStatus(),
          onClick: () => {
            this.props.history.push({
              pathname: getReportPath.covid19RiskStatus()
            });
          }
        },
        {
          title: 'COVID-19 Travel risk',
          isActive: this.props.location.pathname === getReportPath.travelRisk(),
          onClick: () => {
            this.props.history.push({
              pathname: getReportPath.travelRisk()
            });
          }
        },
        {
          title: 'Technology',
          isActive:
            this.props.location.pathname ===
            getReportPath.technicalChallenges(),
          onClick: () => {
            this.props.history.push({
              pathname: getReportPath.technicalChallenges()
            });
          }
        },
        {
          title: 'Employee wellbeing',
          isActive:
            this.props.location.pathname === getReportPath.employessWellBeing(),
          onClick: () => {
            this.props.history.push({
              pathname: getReportPath.employessWellBeing()
            });
          }
        }
      ]
    };
  }

  render() {
    return (
      <MainContentWrapper
        headerOptions={this.getHeaderOptions()}
        tabsOptions={this.getTabsOptions()}
      >
        <Switch>
          <Route path={getReportPath.covid19()} exact component={COVID19} />
          <Route
            path={getReportPath.covid19Family()}
            exact
            component={COVID19Family}
          />
          <Route
            path={getReportPath.covid19RiskStatus()}
            exact
            component={COVID19RiskStatus}
          />
          <Route path={getReportPath.trips()} exact component={ReportedTrips} />

          <Route
            path={getReportPath.travelRisk()}
            exact
            render={props => (
              <HAYFT
                tableTitle="COVID-19 Travel Risk Details"
                chartTitle="COVID-19 Travel Risk Summary"
                listDataFetchParam="TRAVEL_RISK"
                {...props}
              />
            )}
          />
          <Route
            path={getReportPath.technicalChallenges()}
            exact
            render={props => (
              <HAYFT
                tableTitle="Technology/Technical Challenges Details"
                chartTitle="Technology/Technical Challenges Summary"
                listDataFetchParam="TECHNICAL_CHALLENGES"
                {...props}
              />
            )}
          />
          <Route
            path={getReportPath.employessWellBeing()}
            exact
            render={props => (
              <HAYFT
                tableTitle="Employee Wellbeing Details"
                chartTitle="Employee Wellbeing Summary"
                listDataFetchParam="WELLBEING"
                {...props}
              />
            )}
          />
        </Switch>
      </MainContentWrapper>
    );
  }
}

export default compose(
  withRouter,
  observer
)(Dashboards);
