// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';

// Components
import { BlockWrapper } from '@CommonScene';

// Utils
import { compose } from '@Utils';
import { getMyTeamPath } from '@Utils/constans/paths';

import { Grid } from '@material-ui/core';

// Icons
import { Person } from '@Utils/constans/icons';

import ExportButton from '@CommonScene/ExportButton';

// styles
import './styles.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { QuestionProvider } from '@Providers/';
import { formatDate } from '@Utils/formatting';

class QuestionsTable extends Component {
  static propTypes = {
    QuestionStore: PropTypes.object.isRequired,
    listDataFetchParam: PropTypes.string,
    tableTitle: PropTypes.string,
    dateRange: PropTypes.object,
    setFilterParams: PropTypes.func,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      defaultColDef: {
        flex: 1,
        resizable: true,
        enableValue: true,
        enableRowGroup: false,
        enablePivot: true,
        sortable: true,
        filter: true,
        editable: false,
        filterParams: {
          filterOptions: ['equals'],
          buttons: ['apply'],
          suppressAndOrCondition: true,
          closeOnApply: true
        }
      },
      columnDefs: [
        {
          headerName: 'Full Name',
          field: 'name',
          sortable: true,
          filter: 'agTextColumnFilter',
          valueGetter: params => {
            return params.data && params.data.name ? params.data.name : '-';
          }
        },
        {
          headerName: 'Question',
          field: 'questionText',
          sortable: false,
          filter: 'agSetColumnFilter',
          filterParams: {
            values: params => {
              params.success(
                this.props.QuestionStore.summaryData
                  .slice(1)
                  .map(item => item[0])
              );
            }
          },
          valueGetter: params => {
            return params.data && params.data.questionText
              ? params.data.questionText
              : '-';
          }
        },
        {
          headerName: 'Answer',
          field: 'answerText',
          sortable: false,
          filter: 'agSetColumnFilter',
          filterParams: {
            values: params => {
              params.success(
                toJS(this.props.QuestionStore.summaryData[0].slice(1))
              );
            }
          },
          valueGetter: params => {
            return params.data && params.data.answerText
              ? params.data.answerText
              : '-';
          }
        },
        {
          headerName: 'Team/Dept.',
          field: 'teamName',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            apply: true,
            newRowsAction: 'keep',
            closeOnApply: true,
            filterOptions: ['equals'],
            suppressAndOrCondition: true
          },
          editable: false,
          resizable: true,
          width: 150,
          colId: 'team',
          valueGetter: params => {
            return params.data && params.data.teamName
              ? params.data.teamName
              : '-';
          }
        },
        {
          headerName: 'Office',
          field: 'office',
          sortable: true,
          filter: 'agTextColumnFilter',
          valueGetter: params => {
            return params.data && params.data.office ? params.data.office : '-';
          }
        },
        {
          headerName: 'Date',
          field: 'date',
          sortable: true,
          menuTabs: ['generalMenuTab', 'columnsMenuTab'],
          valueGetter: params => {
            return params.data && params.data.date ? params.data.date : '-';
          }
        }
      ],
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressRowGroups: false
            }
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel'
          }
        ],
        defaultToolPanel: ''
      },
      allColumns: true,
      groupHeaderHeight: 75,
      pagination: true,
      paginationPageSize: 25,
      cacheBlockSize: 25,
      maxBlocksInCache: 1,
      rowModelType: 'serverSide',
      suppressExcelExport: true,
      suppressCsvExport: true,
      loadedData: []
    };
  }

  componentDidMount() {
    const {
      QuestionStore: { getQuestionsAndAnswers }
    } = this.props;
    getQuestionsAndAnswers();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dateRange !== this.props.dateRange && this.gridApi) {
      this.gridApi.purgeServerSideCache();
      this.setState({ loadedData: [] });
    }
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
    params.api.setServerSideDatasource(this.serverSideDatasource());
  };

  serverSideDatasource = () => {
    return {
      getRows: params => {
        QuestionProvider.getListData(this.getFetchParams(params)).then(
          response => {
            const rows = this.setListData(response.data);
            this.setLoadedData(rows);
            if (rows.length > 0) {
              params.successCallback(rows, response.pagination.total);
            } else {
              params.successCallback(rows, 0);
              params.api.showNoRowsOverlay();
            }
          }
        );
      }
    };
  };

  getFetchParams = params => {
    const queryParams = {
      offset: params.request.startRow,
      limit: params.request.endRow - params.request.startRow,
      'question.category': this.props.listDataFetchParam,
      'question.fromDate': this.props.dateRange.from,
      'question.toDate': this.props.dateRange.to
    };
    const filterParams = this.getFilterParams(params.request.filterModel);
    const sortParam = this.getSortParams(params.request.sortModel);
    return (
      Object.keys(queryParams)
        .map(key => `${key}=${queryParams[key]}`)
        .join('&') +
      filterParams +
      sortParam
    );
  };

  getFilterParams = filterModel => {
    let filterParams = '';
    const filterObject = {};
    Object.keys(filterModel).forEach(item => {
      let filterKey = '';
      let filterValue = filterModel[item].filter;
      if (item === 'questionText' || item === 'answerText') {
        filterValue = this.getCodes(filterModel[item].values, item);
      }

      switch (item) {
        case 'name':
          filterKey = 'profile.fullName';
          break;
        case 'date':
          filterKey = 'audit.createdAt';
          break;
        case 'office':
          filterKey = 'profile.office';
          break;
        case 'team':
          filterKey = 'profile.team';
          break;
        case 'questionText':
          filterKey = 'question.code';
          break;
        case 'answerText':
          filterKey = 'question.answer';
          break;
        default:
          break;
      }
      filterParams += `&${filterKey}=${filterValue}`;
      filterObject[filterKey] = filterValue;
    });
    this.props.setFilterParams(filterObject);
    return filterParams;
  };

  getCodes = (values, type) => {
    const {
      QuestionStore: { questionsAndAnswers }
    } = this.props;
    let code;
    if (type === 'questionText') {
      code = toJS(questionsAndAnswers)
        .filter(item => {
          return values.includes(item.questionText);
        })
        .map(question => question.code);
    }
    if (type === 'answerText') {
      code = [];
      toJS(questionsAndAnswers).forEach(item => {
        item.answers.forEach(answer => {
          if (values.includes(answer.name)) {
            code.push(answer.code);
          }
        });
      });
    }
    return code;
  };

  getSortParams = sortModel => {
    let sortParams = '';
    if (sortModel[0]) {
      const colId = sortModel[0].colId;
      let sortKey = '';
      switch (colId) {
        case 'name':
          sortKey = 'profile.firstName';
          break;
        case 'date':
          sortKey = 'audit.createdAt';
          break;
        case 'office':
          sortKey = 'profile.officeAddress';
          break;
        case 'team':
          sortKey = 'profile.team';
          break;
        case 'questionText':
          sortKey = 'question.code';
          break;
        case 'answerText':
          sortKey = 'question.answer';
          break;
        default:
          break;
      }
      sortParams = `&sort=${sortKey},${sortModel[0].sort}`;
    }
    return sortParams;
  };

  setListData = data => {
    const rows = [];
    const {
      QuestionStore: { questionsAndAnswers }
    } = this.props;
    data.forEach(item => {
      item.questions.forEach(question => {
        let listData = {};
        const guid = item.guid;
        listData = {
          name: `${item.profile.firstName} ${item.profile.lastName}`,
          teamName: item.profile.teamName,
          office: item.profile.officeAddress,
          date: formatDate(item.audit.updatedAt),
          phone: item.profile.phone
        };
        const questionObject = toJS(questionsAndAnswers).filter(
          questionsAndAnswer => questionsAndAnswer.code === question.ref.sd.code
        )[0];
        let answerText = [];
        if (question.answer) {
          answerText = questionObject.answers.filter(
            answer => answer.code === question.answer
          );
        }
        listData = {
          ...listData,
          questionText: questionObject.questionText,
          answerText: answerText[0] ? answerText[0].name : '',
          guid: `${guid}${questionObject.questionText}`
        };
        rows.push(listData);
      });
    });
    return rows;
  };

  clearAllFilter = () => {
    this.gridApi.setFilterModel({});
    this.gridApi.setSortModel({});
  };

  setLoadedData = data => {
    if (this.state.loadedData.length > 0) {
      const allreadyLoadedData = [...this.state.loadedData];
      data.forEach(newItem => {
        const exists = allreadyLoadedData.find(
          item => item.guid === newItem.guid
        );

        if (!exists) {
          allreadyLoadedData.push(newItem);
        }
      });
      this.setState({ loadedData: allreadyLoadedData });
    } else {
      this.setState({ loadedData: data });
    }
  };

  getCSVData = data => {
    return data.map(item => {
      return {
        Name: item.name,
        Question: item.questionText,
        Answer: item.answerText,
        Office: item.office,
        Team: item.teamName,
        Date: formatDate(item.date, 'MM/DD/YYYY')
      };
    });
  };

  getBackUrlKey = title => {
    switch (title) {
      case 'COVID-19 Travel Risk Details':
        return 'travelRisk';
      case 'Technology/Technical Challenges Details':
        return 'technology';
      case 'Employee Wellbeing Details':
        return 'wellbeing';
      default:
        return '';
    }
  };

  render() {
    const { history } = this.props;
    const { loadedData } = this.state;
    return (
      <>
        <BlockWrapper>
          <div className="ag-theme-alpine">
            <div className="agTableHeaderContent">
              <Person />
              <h1 className="agTableHeader">{this.props.tableTitle}</h1>
              <button
                className="clear-all-dashboard"
                onClick={this.clearAllFilter}
              >
                Clear Filter
              </button>
              {loadedData.length > 0 && (
                <div className="export-btn-dashboard">
                  <ExportButton
                    count={loadedData.length}
                    jsonData={this.getCSVData(loadedData)}
                  />
                </div>
              )}
            </div>
          </div>
          <Grid container className="chartWrapper">
            <div className="ag-theme-alpine" style={{ width: '100%' }}>
              <br />
              <AgGridReact
                onGridReady={this.onGridReady}
                paginationPageSize={this.state.paginationPageSize}
                pagination={this.state.pagination}
                suppressRowClickSelection
                suppressCellSelection
                rowStyle={{ cursor: 'pointer' }}
                frameworkComponents={this.state.frameworkComponents}
                columnDefs={this.state.columnDefs}
                defaultColDef={this.state.defaultColDef}
                sideBar={this.state.sideBar}
                groupHeaderHeight={this.state.groupHeaderHeight}
                allColumns={this.state.allColumns}
                cacheBlockSize={this.state.cacheBlockSize}
                maxBlocksInCache={this.state.maxBlocksInCache}
                rowModelType={this.state.rowModelType}
                suppressExcelExport={this.state.suppressExcelExport}
                suppressCsvExport={this.state.suppressCsvExport}
                onFilterChanged={() => this.setState({ loadedData: [] })}
                onRowClicked={params => {
                  if (params.data && params.event.target.textContent !== '') {
                    history.push(
                      `${getMyTeamPath.teamMember()}/${
                        params.data.phone
                      }?backLinkKey=${this.getBackUrlKey(
                        this.props.tableTitle
                      )}`
                    );
                  }
                }}
              />
            </div>
          </Grid>
        </BlockWrapper>
      </>
    );
  }
}
export default compose(
  inject('QuestionStore'),
  observer
)(QuestionsTable);
