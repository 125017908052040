/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

const inputRegExpRule = 'required|min:6';

const passwordsRules = {
  fields: {
    password: {
      value: '',
      error: null,
      rule: [
        'required',
        'between:8,40',
        'regex:/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\u0024%\u005e&\u002a])(?=.{8,})/'
      ]
    },
    passwordConfirm: {
      value: '',
      error: null,
      rule: 'required|same:password'
    }
  },
  meta: {
    isValid: false,
    error: null
  }
};

export const AuthForms = {
  signInForm: {
    fields: {
      login: {
        value: '',
        error: null,
        rule: inputRegExpRule
      },
      password: {
        value: '',
        error: null,
        rule: 'required'
      }
    },
    meta: {
      isValid: false,
      error: null
    }
  },
  resetPasswordPhone: {
    fields: {
      phone: {
        value: '',
        error: null,
        rule: inputRegExpRule
      }
    },
    meta: {
      isValid: false,
      error: null
    }
  },
  resetPasswordCode: {
    fields: {
      code: {
        value: '',
        error: null,
        rule: inputRegExpRule
      }
    },
    meta: {
      isValid: false,
      error: null
    }
  },
  resetPassword: Object.assign({}, passwordsRules),
  updatePassword: {
    fields: {
      oldPassword: {
        value: '',
        error: null,
        rule: ['required']
      },
      password: {
        value: '',
        error: null,
        rule: [
          'required',
          'between:8,40',
          'regex:/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\u0024%\u005e&\u002a])(?=.{8,})/'
        ]
      },
      passwordConfirm: {
        value: '',
        error: null,
        rule: 'required|same:password'
      }
    },
    meta: {
      isValid: false,
      error: null
    }
  },
  enterPassword: Object.assign({}, passwordsRules),
  recoveryQuestionsForm: {
    fields: {
      question_1: {
        value: '',
        error: null,
        rule: 'required'
      },
      answer_1: {
        value: '',
        error: null,
        rule: 'required'
      },
      question_2: {
        value: '',
        error: null,
        rule: 'required'
      },
      answer_2: {
        value: '',
        error: null,
        rule: 'required'
      },
      question_3: {
        value: '',
        error: null,
        rule: 'required'
      },
      answer_3: {
        value: '',
        error: null,
        rule: 'required'
      }
    },
    meta: {
      isValid: false,
      error: null
    }
  },
  recoveryFromAnswers: {
    fields: {
      question_1: {
        value: '',
        error: null,
        rule: 'required'
      },
      answer_1: {
        value: '',
        error: null,
        rule: 'required'
      },
      question_2: {
        value: '',
        error: null,
        rule: 'required'
      },
      answer_2: {
        value: '',
        error: null,
        rule: 'required'
      },
      question_3: {
        value: '',
        error: null,
        rule: 'required'
      },
      answer_3: {
        value: '',
        error: null,
        rule: 'required'
      }
    },
    meta: {
      isValid: false,
      error: null
    }
  },
  recoveryFromMnemonic: {
    fields: {
      phrase: {
        value: '',
        error: null,
        rule: 'required'
      }
    },
    meta: {
      isValid: false,
      error: null
    }
  },
  invitationVerification: {
    fields: {
      phone: {
        value: '',
        error: null,
        rule: 'required|min:10'
      },
      invitationCode: {
        value: '',
        error: null,
        rule: 'required'
      }
    },
    meta: {
      isValid: false,
      error: null
    }
  },
  adminEditForm: {
    fields: {
      firstName: {
        label: '1',
        name: 'firstName',
        value: '',
        error: null,
        rule: 'required|max:40'
      },
      lastName: {
        label: '2',
        name: 'lastName',
        value: '',
        error: null,
        rule: 'max:40'
      },
      middleName: {
        label: '3',
        name: 'middleName',
        value: '',
        error: null,
        rule: 'max:40'
      },
      email: {
        label: '4',
        name: 'email',
        value: '',
        error: null,
        rule: 'email'
      },
      phone: {
        label: '5',
        name: 'phone',
        value: '',
        error: null,
        rule: 'required|min:10'
      }
    },
    meta: {
      isValid: false,
      error: null
    }
  }
};
