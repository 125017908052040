/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

const styles = theme => ({
  inputStyles: {
    width: '100% !important',
    color: '#2F0A6B',
    borderRadius: `${theme.spacing()}px !important`,
    borderColor: '#E9E2F4 !important',
    height: 'auto !important',
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    paddingLeft: '63px !important',
    lineHeight: '15px'
  },
  buttonStyles: {
    backgroundColor: '#fff !important',
    borderColor: '#E9E2F4 !important',
    borderRadius: `${theme.spacing()}px 0 0 ${theme.spacing()}px !important`,
    paddingLeft: `${theme.spacing()}px !important`,
    paddingRight: `${theme.spacing()}px !important`,
    '& .arrow': {
      borderTop: '4px solid #A095AE !important'
    }
  },
  disabledState: {
    backgroundColor: '#F3F3F3 !important',
    color: '#A095AE !important'
  },
  errorState: {
    borderColor: '#FE4848 !important'
  },
  errorMessage: {
    color: '#f44336',
    fontSize: '0.75rem',
    position: 'absolute',
    bottom: '-20px'
  }
});

export default styles;
