// Validators
import validator from 'validator';
import { PhoneNumberUtil } from 'google-libphonenumber';
import moment from 'moment';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { toXML } from 'jstoxml';
import { formatDate } from './formatting';

class ValidateRowsConditions {
  constructor() {
    this.errorText = '';
  }

  static updateError = (key, errorsArray, errorText) => {
    errorsArray.map(error => {
      const newError = error;
      if (error.field === key) {
        newError.errorsTexts += errorText;
      }
      return newError;
    });
    return errorsArray;
  };

  static addError = (key, errorsArray, errorText) => {
    errorsArray.push({
      field: key,
      errorsTexts: errorText
    });
    return errorsArray;
  };

  // Conditions
  static setError = (key, errorsArray, errorText) => {
    const existingErrors = errorsArray.find(error => error.field === key);
    return existingErrors
      ? this.updateError(key, errorsArray, errorText)
      : this.addError(key, errorsArray, errorText);
  };

  static fieldRequired = (val, key, errorsArray, title) => {
    if (!val) {
      this.errorText = `The ${title} is required. `;
      return this.setError(key, errorsArray, this.errorText);
    }
    return errorsArray;
  };

  static fieldMaxLength = (val, key, errorsArray, title, maxLength) => {
    if (typeof val === 'string' && val.length > maxLength) {
      this.errorText = `Maximum ${title} length over 128 characters. `;
      return this.setError(key, errorsArray, this.errorText);
    }
    return errorsArray;
  };

  static email = (val, key, errorsArray) => {
    if (!validator.isEmail(val)) {
      this.errorText = `Invalid email. `;
      return this.setError(key, errorsArray, this.errorText);
    }
    return errorsArray;
  };

  static phone = (val, key, errorsArray) => {
    let isValidPhone;
    try {
      const phoneUtil = PhoneNumberUtil.getInstance();
      isValidPhone = phoneUtil.isValidNumber(
        phoneUtil.parse(JSON.stringify(val))
      );
    } catch (e) {
      isValidPhone = false;
    }
    if (!isValidPhone) {
      this.errorText = `Invalid phone number. `;
      return this.setError(key, errorsArray, this.errorText);
    }
    return errorsArray;
  };

  static date = (val, key, errorsArray) => {
    const givenDate = new Date(val);
    const currentDate = new Date();
    const isValidDate =
      givenDate < currentDate && moment(val, 'YYYY-MM-DD', true).isValid();
    if (!isValidDate) {
      this.errorText = `Date of birth format: YYYY-MM-DD. `;
      return this.setError(key, errorsArray, this.errorText);
    }
    return errorsArray;
  };

  static country = (val, key, errorsArray, countries) => {
    if (!(countries.indexOf(val.trim()) > 0)) {
      this.errorText = `Invalid country. `;
      return this.setError(key, errorsArray, this.errorText);
    }
    return errorsArray;
  };

  static existingValidRowByVal = (
    val,
    key,
    errorsArray,
    title,
    validRowsInCSV,
    id
  ) => {
    const hasValidRow =
      validRowsInCSV.filter(
        existingValidRow =>
          existingValidRow[key] === val && existingValidRow.id !== id
      ).length > 0;
    if (hasValidRow) {
      this.errorText = `Team member with the same ${title} already exists. `;
      return this.setError(key, errorsArray, this.errorText);
    }
    return errorsArray;
  };
}

export const ValidateRowsInit = (row, countries, validRowsInCSV) => {
  let colName;
  const errorsArray = [];
  const isRequiredLocation = Boolean(row.city || row.country || row.region);

  if (typeof row === 'object' && row !== null) {
    Object.keys(row).forEach(key => {
      switch (key) {
        case 'firstname':
          colName = '"First name"';
          ValidateRowsConditions.fieldRequired(
            row[key],
            key,
            errorsArray,
            colName
          );
          ValidateRowsConditions.fieldMaxLength(
            row[key],
            key,
            errorsArray,
            colName,
            128
          );
          break;
        case 'middlename':
          colName = '"Middle name"';
          ValidateRowsConditions.fieldMaxLength(
            row[key],
            key,
            errorsArray,
            colName,
            128
          );
          break;
        case 'lastname':
          colName = '"Last name"';
          ValidateRowsConditions.fieldRequired(
            row[key],
            key,
            errorsArray,
            colName
          );
          ValidateRowsConditions.fieldMaxLength(
            row[key],
            key,
            errorsArray,
            colName,
            128
          );
          break;
        case 'email':
          colName = '"Email"';
          ValidateRowsConditions.fieldRequired(
            row[key],
            key,
            errorsArray,
            colName
          );
          ValidateRowsConditions.email(row[key], key, errorsArray);
          ValidateRowsConditions.existingValidRowByVal(
            row[key],
            key,
            errorsArray,
            colName,
            validRowsInCSV,
            row.id
          );
          break;
        case 'manageremail':
          if (row[key]) {
            ValidateRowsConditions.email(row[key], key, errorsArray);
          }
          break;
        case 'mobilephone':
          colName = '"Mobile phone"';
          ValidateRowsConditions.fieldRequired(
            row[key],
            key,
            errorsArray,
            colName
          );
          ValidateRowsConditions.phone(row[key], key, errorsArray);
          ValidateRowsConditions.existingValidRowByVal(
            row[key],
            key,
            errorsArray,
            colName,
            validRowsInCSV,
            row.id
          );
          break;
        case 'city':
          colName = '"Location"';
          if (isRequiredLocation) {
            ValidateRowsConditions.fieldRequired(
              row[key],
              key,
              errorsArray,
              colName
            );
          }
          break;
        case 'country':
          colName = '"Location"';
          if (isRequiredLocation) {
            ValidateRowsConditions.fieldRequired(
              row[key],
              key,
              errorsArray,
              colName
            );
          }
          if (row[key]) {
            ValidateRowsConditions.country(
              row[key],
              key,
              errorsArray,
              countries
            );
          }
          break;
        case 'dob':
          if (row[key]) {
            ValidateRowsConditions.date(row[key], key, errorsArray);
          }
          break;
        default:
      }
    });
  }
  return errorsArray;
};

// Returns filter params
export const getFilterParams = filterModelData => {
  let params = '';
  const nestedFields = [
    'title',
    'jobTitle',
    'gender',
    'jobGrade',
    'jobStatus',
    'employmentCategory'
  ];
  const filterData = Object.keys(filterModelData).map(key => {
    if (
      filterModelData[key].filterType === 'text' &&
      !nestedFields.includes(key)
    ) {
      switch (filterModelData[key].type) {
        case 'contains':
          params = `&${key === 'fullName' ? 'firstName' : key}[like]=${
            filterModelData[key].filter[0] === '+'
              ? filterModelData[key].filter.substring(1)
              : filterModelData[key].filter
          }`;
          break;

        case 'equals':
          params = `&${key === 'fullName' ? 'firstName' : key}[eq]=${
            filterModelData[key].filter
          }`;
          break;
        default:
          params = '';
          break;
      }
    } else {
      switch (filterModelData[key].type) {
        case 'contains':
          params = `&${key}.name[like]=${filterModelData[key].filter}`;
          break;
        case 'equals':
          params = `&${key}.name[eq]=${filterModelData[key].filter}`;
          break;
        default:
          params = '';
          break;
      }
    }

    if (filterModelData[key].filterType === 'date') {
      const date = new Date(filterModelData[key].dateFrom);
      const dateInMilliSecondFormat = date.getTime();
      const paramData = key === 'status_updated_at' ? 'audit.updatedAt' : key;

      switch (filterModelData[key].type) {
        case 'equals':
          params = `&${paramData}[eq]=${dateInMilliSecondFormat}`;
          break;
        case 'greaterThan':
          params = `&${paramData}[gt]=${dateInMilliSecondFormat}`;
          break;
        case 'lessThan':
          params = `&${paramData}[lt]=${dateInMilliSecondFormat}`;
          break;
        default:
          params = '';
          break;
      }
    }

    if (filterModelData[key].filterType === 'set') {
      params = `&${key}[in]=${filterModelData[key].values.map(value =>
        value.toUpperCase()
      )}`;
    }

    return params;
  });
  return filterData.join('');
};

// Returns sort params
export const getSortParams = sortModelData => {
  const nestedFields = [
    'title',
    'jobTitle',
    'gender',
    'jobGrade',
    'jobStatus',
    'employmentCategory'
  ];
  let param = '';
  if (sortModelData.length > 0) {
    if (sortModelData[0].colId === 'status_updated_at') {
      param = `audit.updatedAt,${sortModelData[0].sort.toUpperCase()}`;
    } else if (nestedFields.includes(sortModelData[0].colId)) {
      param = `${
        sortModelData[0].colId
      }.name,${sortModelData[0].sort.toUpperCase()}`;
    } else {
      param = `${
        sortModelData[0].colId === 'fullName'
          ? 'firstName'
          : sortModelData[0].colId
      },${sortModelData[0].sort.toUpperCase()}`;
    }
  }

  return param;
};

// Returns filter params for Test overall Dashboard
export const getDashBordFilterParams = filterModelData => {
  let params = '';
  const filterData = Object.keys(filterModelData).map(key => {
    if (filterModelData[key].filterType === 'text') {
      switch (filterModelData[key].type) {
        case 'equals':
          params = `&profile.${key}=${filterModelData[key].filter}`;
          break;
        case 'contains':
          params = `&profile.${key}=${filterModelData[key].filter}`;
          break;
        default:
          params = '';
          break;
      }
    }

    if (filterModelData[key].filterType === 'date') {
      const date = new Date(filterModelData[key].dateFrom);
      const dateInMilliSecondFormat = date.getTime();
      switch (filterModelData[key].type) {
        case 'equals':
          params = `&${key}[eq]=${dateInMilliSecondFormat}`;
          break;
        case 'greaterThan':
          params = `&${key}[gt]=${dateInMilliSecondFormat}`;
          break;
        case 'lessThan':
          params = `&${key}[lt]=${dateInMilliSecondFormat}`;
          break;
        default:
          params = '';
          break;
      }
    }
    return params;
  });
  return filterData.join('');
};

// Returns sort params for Test overall Dashboard
export const getDashboardSortParams = sortModelData => {
  let param = '';
  if (sortModelData.length > 0) {
    const colId = sortModelData[0].colId;
    const getSortingParamName = name => {
      switch (name) {
        case 'team':
          return 'teamName';
        case 'office':
          return 'officeAddress';
        case 'country':
          return 'address.city.country';
        default:
          return colId;
      }
    };
    if (
      colId === 'testResult.fromDate' ||
      colId === 'testResult.toDate' ||
      colId === 'workAbility' ||
      colId === 'testDate' ||
      colId === 'statusAt' ||
      colId === 'status' ||
      colId === 'date'
    ) {
      param = `${getSortingParamName(
        colId
      )},${sortModelData[0].sort.toUpperCase()}`;
    } else {
      param = `profile.${getSortingParamName(
        colId
      )},${sortModelData[0].sort.toUpperCase()}`;
    }
  }

  return param;
};

export const exportAsCSV = JSONData => {
  const arrData =
    typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData;
  let CSV = '';
  let row = '';

  Object.keys(arrData[0]).forEach(item => {
    row += `${item},`;
  });

  row = row.slice(0, -1);
  CSV += `${row}\r\n`;

  arrData.forEach(data => {
    row = '';
    Object.keys(arrData[0]).forEach(item => {
      row += `"${data[item] ? data[item] : ''}",`;
    });
    row.slice(0, row.length - 1);
    CSV += `${row}\r\n`;
  });

  if (CSV === '') {
    return;
  }

  const fileName = `Table-Export - ${formatDate(new Date())}`;
  const uri = `data:text/csv;charset=utf-8,${escape(CSV)}`;
  const link = document.createElement('a');
  link.href = uri;
  link.style = 'visibility:hidden';
  link.download = `${fileName}.csv`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const exportAsExcel = JSONData => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const ws = XLSX.utils.json_to_sheet(JSONData);
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, `Table-Export${fileExtension}`);
};

export const exportAsXML = jsonData => {
  const xmlData = toXML(jsonData);
  const fileName = `Table-Export - ${formatDate(new Date())}`;
  const a = document.createElement('a');
  a.download = fileName;
  a.href = URL.createObjectURL(
    new File([xmlData], fileName, { type: 'text/xml' })
  );
  a.click();
};
