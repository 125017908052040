// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Prompt } from 'react-router-dom';
import * as _ from 'lodash';

import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';

// Utils
import { compose } from '@Utils';
import { getMyTeamPath } from '@Utils/constans/paths';
import { ValidateRowsInit } from '@Utils/ag-grid';

// AG Grid Table
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

// Components
import InfoIcon from '@CommonScene/modals/common/InfoIcon';
import BulkUploadRowDetails from './BulkUploadRowDetails';
import ColRender from './ColRender';

// Styles
import './index.css';

class BulkDataDetails extends Component {
  static propTypes = {
    MyTeamStore: PropTypes.object.isRequired,
    CommonStore: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      field: [
        'title',
        'firstname',
        'middlename',
        'lastname',
        'email',
        'mobilephone',
        'dob',
        'employeeid',
        'jobtitle',
        'team',
        'manageremail',
        'gender',
        'country',
        'region',
        'city',
        'workaddress',
        'jobgrade',
        'jobstatus',
        'employmentcategory',
        'status',
        'errors'
      ],
      errorRows: [],
      successRows: [],
      inviteStatus: false,
      emailError: [],
      columnDefs: [
        {
          headerName: 'Title',
          field: 'title',
          sortable: true,
          editable: true,
          cellRendererFramework: params => {
            return params.data && params.data.title ? params.data.title : '-';
          }
        },
        {
          headerName: 'First name*',
          field: 'firstname',
          sortable: true,
          editable: true,
          cellRendererFramework: params => {
            return <ColRender params={params} field="firstname" />;
          }
        },
        {
          headerName: 'Middle name',
          field: 'middlename',
          sortable: true,
          editable: true,
          cellRendererFramework: params => {
            return <ColRender params={params} field="middlename" />;
          }
        },
        {
          headerName: 'Last name*',
          field: 'lastname',
          sortable: true,
          editable: true,
          cellRendererFramework: params => {
            return <ColRender params={params} field="lastname" />;
          }
        },
        {
          headerName: 'Email*',
          field: 'email',
          sortable: true,
          editable: true,
          cellRendererFramework: params => {
            return <ColRender params={params} field="email" />;
          }
        },
        {
          headerName: 'Mobile Phone*',
          field: 'mobilephone',
          sortable: true,
          editable: true,
          cellRendererFramework: params => {
            return <ColRender params={params} field="mobilephone" />;
          }
        },
        {
          headerName: 'Date of Birth',
          field: 'dob',
          sortable: true,
          editable: true,
          cellRendererFramework: params => {
            return <ColRender params={params} field="dob" />;
          }
        },
        {
          headerName: 'Employee ID',
          field: 'employeeid',
          sortable: true,
          editable: true,
          cellRendererFramework: params => {
            return <ColRender params={params} field="employeeid" />;
          }
        },
        {
          headerName: 'Job title',
          field: 'jobtitle',
          sortable: true,
          editable: true,
          cellRendererFramework: params => {
            return <ColRender params={params} field="jobtitle" />;
          }
        },
        {
          headerName: 'Team/Dept.',
          field: 'team',
          sortable: true,
          editable: true,
          cellRendererFramework: params => {
            return <ColRender params={params} field="team" />;
          }
        },
        {
          headerName: 'Line manager email',
          field: 'manageremail',
          sortable: true,
          editable: true,
          cellRendererFramework: params => {
            return <ColRender params={params} field="manageremail" />;
          }
        },
        {
          headerName: 'Gender',
          field: 'gender',
          sortable: true,
          editable: true,
          cellRendererFramework: params => {
            return <ColRender params={params} field="gender" />;
          }
        },
        {
          headerName: 'Country',
          field: 'country',
          sortable: true,
          editable: true,
          cellRendererFramework: params => {
            return <ColRender params={params} field="country" />;
          }
        },
        {
          headerName: 'Region/Area',
          field: 'region',
          sortable: true,
          editable: true,
          cellRendererFramework: params => {
            return <ColRender params={params} field="region" />;
          }
        },
        {
          headerName: 'City',
          field: 'city',
          sortable: true,
          editable: true,
          cellRendererFramework: params => {
            return <ColRender params={params} field="city" />;
          }
        },
        {
          headerName: 'Office',
          field: 'workaddress',
          sortable: true,
          editable: true,
          cellRendererFramework: params => {
            return <ColRender params={params} field="workaddress" />;
          }
        },
        {
          headerName: 'Job grade',
          field: 'jobgrade',
          sortable: true,
          editable: true,
          cellRendererFramework: params => {
            return <ColRender params={params} field="jobgrade" />;
          }
        },
        {
          headerName: 'Job Status',
          field: 'jobstatus',
          sortable: true,
          editable: true,
          cellRendererFramework: params => {
            return <ColRender params={params} field="jobstatus" />;
          }
        },
        {
          headerName: 'Employment categoryids',
          field: 'employmentcategory',
          sortable: true,
          editable: true,
          cellRendererFramework: params => {
            return <ColRender params={params} field="employmentcategory" />;
          }
        }
      ],
      active: 'success',
      defaultColDef: {
        minWidth: 100,
        resizable: true,
        enableValue: true,
        enableRowGroup: true,
        enablePivot: true,
        sortable: true,
        filter: true,
        onCellValueChanged: () => {
          this.setState({
            isTableDataUpdated: true
          });
        }
      },

      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressRowGroups: false
            }
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel'
          }
        ],
        defaultToolPanel: ''
      },
      allColumns: true,
      multiSortKey: 'ctrl',
      groupHeaderHeight: 75,
      mandatoryFields: ['firstname', 'lastname', 'email', 'mobilephone'],
      titleFields: ['Mr', 'Mrs', 'Ms', 'Dr'],
      genderFields: ['Male', 'Female', 'Others'],
      employmentCategoryField: ['Employee', 'Contractor'],
      pagination: true,
      rowModelType: 'serverSide',
      cacheBlockSize: 20,
      maxBlocksInCache: 1,
      paginationPageSize: 20,
      overlayLoadingTemplate:
        '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',
      isTableDataUpdated: false,
      lastLocation: null,
      confirmedNavigation: false
    };
  }

  componentDidMount() {
    const {
      MyTeamStore: {
        excelObject,
        validateData,
        invalidRowsInCSV,
        validRowsInCSV
      },
      history
    } = this.props;

    const response = toJS(excelObject);
    if (response) {
      let dataRows = response.rows.filter(data => data.length); // Remove empty rows from csv
      dataRows = dataRows.splice(2, dataRows.length); // Remove title rows from csv
      if (dataRows.length > 0) {
        const tableData = this.getTableDataJSON(dataRows);
        validateData(tableData);
        const activeTab =
          toJS(invalidRowsInCSV).length && !toJS(validRowsInCSV).length
            ? 'error'
            : 'success';
        this.setState({
          active: activeTab,
          errorRows: [...toJS(invalidRowsInCSV)],
          successRows: [...toJS(validRowsInCSV)]
        });
      }
    } else {
      history.push('/my-team/new-team-member/bulk');
    }
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  setSuccessRowFields = (params, errorsArray) => {
    const newParams = params;
    newParams.errors = errorsArray;
    if (!errorsArray.length) {
      // Row is not having error, push to success
      if (this.state.active === 'error') {
        const newErrorRows = this.state.errorRows.filter(
          row => row.id !== newParams.id
        );
        this.setState({
          errorRows: newErrorRows,
          successRows: [...this.state.successRows, { ...newParams }]
        });
      }
    } else {
      // Row is having error, push to error and remove from success
      const newErrorRows = [...this.state.errorRows];
      const newSuccessRows = [...this.state.successRows];
      if (newErrorRows.length > 0) {
        const actualItem = this.state.errorRows.filter(
          item => item.id === newParams.id
        );
        const index = newErrorRows.indexOf(actualItem[0]);
        if (index !== -1) {
          newErrorRows.splice(index, 1, { ...newParams });
        } else {
          // if error is made for an success row
          newErrorRows.push({ ...newParams });
        }
      } else {
        newErrorRows.push({ ...newParams });
      }

      const itemInSuccess = newSuccessRows.filter(
        item => item.id === newParams.id
      );
      const indexOfSuccess = newSuccessRows.indexOf(itemInSuccess[0]);
      if (indexOfSuccess !== -1) {
        newSuccessRows.splice(indexOfSuccess, 1);
        this.setState({ successRows: newSuccessRows });
      }
      this.setState({ errorRows: newErrorRows });
    }
  };

  getTableDataJSON = rows => {
    const arrayToLoadRows = [];
    let dataArray = [];
    rows.map((row, key) => {
      const b = {};
      _.map(row, (fieldValue, i) => {
        b[this.state.field[i]] = fieldValue || '';
        return b;
      });
      b.id = key;
      arrayToLoadRows.push(b);
      return arrayToLoadRows;
    });

    if (arrayToLoadRows.length > 0) {
      dataArray = arrayToLoadRows.filter(
        value => Object.keys(value).length > 1
      );
    }
    return dataArray;
  };

  onInviteClick = () => {
    const params = {
      suppressExcelExport: true
    };
    const {
      MyTeamStore: {
        uploadedFileName,
        createBulkNewMembers,
        sendEmployeeUploadStartedEvent,
        sendInvitationSMS
      },
      history
    } = this.props;
    const fileData = this.gridApi.getDataAsCsv(params);
    let file = uploadedFileName.split('.');
    file = `${file[0]}.csv`;
    // Add an empty row since backend will skip first two rows as per template
    const finalCsv = fileData.replace('\n', '\n\n');
    createBulkNewMembers(btoa(finalCsv), file).then(response => {
      sendEmployeeUploadStartedEvent(response.guid, sendInvitationSMS).then(
        () => {
          history.push('/my-team/import-log');
        }
      );
    });
  };

  onCellValueChanged = event => {
    const {
      MyTeamStore: { countries }
    } = this.props;
    const errorsArray = ValidateRowsInit(
      event.data,
      toJS(countries),
      this.state.successRows
    );
    this.setSuccessRowFields(event.data, errorsArray);
  };

  componentWillUnmount() {
    const {
      MyTeamStore: { resetExcelData }
    } = this.props;
    resetExcelData();
    this.setState({
      isTableDataUpdated: false,
      lastLocation: null,
      confirmedNavigation: false
    });
  }

  setGridDataType = type => {
    this.setState({ active: type });
  };

  render() {
    return (
      <>
        <BulkUploadRowDetails
          onInviteClick={this.onInviteClick}
          successRowCount={this.state.successRows.length}
          errorRowCount={this.state.errorRows.length}
          setGridDataType={this.setGridDataType}
          disableInvite={this.state.active === 'error'}
          sendInvitationSMS={this.props.MyTeamStore.sendInvitationSMS}
        />
        <div className="ag-theme-alpine" style={{ width: '100%' }}>
          <AgGridReact
            rowStyle={{ cursor: 'pointer' }}
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            sideBar={this.state.sideBar}
            valueCache={this.state.valueCache}
            onGridReady={this.onGridReady}
            multiSortKey={this.state.multiSortKey}
            onCellValueChanged={this.onCellValueChanged}
            pagination={this.state.pagination}
            rowData={
              this.state.active === 'success'
                ? this.state.successRows
                : this.state.errorRows
            }
            cacheBlockSize={this.state.cacheBlockSize}
            maxBlocksInCache={this.state.maxBlocksInCache}
            paginationPageSize={this.state.paginationPageSize}
            overlayLoadingTemplate={this.state.overlayLoadingTemplate}
          />
        </div>
        <>
          <Prompt
            when={this.state.isTableDataUpdated}
            message={location => {
              const importLogPath = getMyTeamPath.importLog();
              if (
                !this.state.confirmedNavigation &&
                location.pathname !== importLogPath
              ) {
                this.setState({
                  lastLocation: location.pathname
                });
                this.props.CommonStore.setModalOptions({
                  modalName: 'ModalWarning',
                  modalProps: {
                    handleConfirmAction: () => {
                      this.setState(
                        {
                          confirmedNavigation: true
                        },
                        () => {
                          this.props.history.push(this.state.lastLocation);
                        }
                      );
                    },
                    handleCancelAction: () => {},
                    borderColor: 'yellowBorder',
                    Icon: InfoIcon,
                    cancelBtn: 'no',
                    confirmBtn: 'yes',
                    title: 'Confirm cancellation',
                    text:
                      'Your changes will be discarded. Are you sure you want to proceed?'
                  }
                });
                return false;
              }
              return true;
            }}
          />
        </>
      </>
    );
  }
}

BulkDataDetails.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default compose(
  inject('MyTeamStore', 'CommonStore'),
  withRouter,
  observer
)(BulkDataDetails);
