/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

export default theme => ({
  blockHeader: {
    color: '#2F0A6B',
    fontWeight: 500,
    lineHeight: `19px`,
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  linkText: {
    color: '#9B84E0',
    textDecoration: 'underline',
    margin: `${theme.spacing(1)}px 0`,
    cursor: 'pointer',
    display: 'inline-block'
  },
  label: {
    color: '#A095AE',
    lineHeight: `19px`,
    width: 150
  },
  placeholder: {
    color: '#2F0A6B',
    fontWeight: 600,
    lineHeight: '22px',
    fontSize: 19,
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.154em'
  },
  pencil: {
    stroke: '#A095AE',
    '&:hover': {
      stroke: '#5A5CAB'
    }
  },
  button: {
    textTransform: 'upperCase'
  },
  cancelBtn: {
    marginRight: theme.spacing(2)
  },
  btnWrapper: {
    marginBottom: theme.spacing(2)
  }
});
