/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import { Grid, Typography, withStyles } from '@material-ui/core';

// Utils
import classNames from 'classnames';
import { compose } from '@Utils';
import {
  IconInformation,
  IconError,
  IconCancel,
  IconSuccess
} from '@Utils/constans/icons';

// Styles
import styles from './styles';

const Notification = ({
  classes,
  hasError,
  label,
  errorLabel,
  onClose,
  isHidden,
  hasSuccess,
  successLabel
}) => {
  if (isHidden) {
    return null;
  }

  const renderNotification = () => {
    if (hasError) {
      return (
        <>
          <IconError />
          <Typography variant="body1" className={classes.label}>
            {errorLabel}
          </Typography>
          <IconCancel onClick={onClose} className={classes.cancelIcon} />
        </>
      );
    } else if (hasSuccess) {
      return (
        <>
          <IconSuccess />
          <Typography variant="body1" className={classes.label}>
            {successLabel}
          </Typography>
          <IconCancel onClick={onClose} className={classes.cancelIcon} />
        </>
      );
    }
    return (
      <>
        <IconInformation />
        <Typography variant="body1" className={classes.label}>
          {label}
        </Typography>
      </>
    );
  };

  return (
    <Grid
      container
      className={classNames(classes.wrapper, {
        [classes.error]: hasError,
        [classes.success]: hasSuccess
      })}
    >
      {renderNotification()}
    </Grid>
  );
};

Notification.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  hasError: PropTypes.bool,
  isHidden: PropTypes.bool,
  errorLabel: PropTypes.string,
  successLabel: PropTypes.string,
  hasSuccess: PropTypes.bool
};

export default compose(withStyles(styles))(Notification);
