// Core
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import classnames from 'classnames';

// Material UI
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CircularProgress, FormControl, TextField } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';

// Components
import FieldWrapper from '@CommonScene/FieldWrapper';

import useStyles from './styles';

const AutoCompleteSelect = ({
  value,
  items,
  name,
  label,
  idField,
  labelField,
  onChange,
  onInputChange,
  loading,
  disabled,
  labelClassName,
  childrenClassName,
  fieldWrapperClass
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const onSelectChange = (event, optionValue) => {
    onChange(name, optionValue);
  };

  return (
    <FieldWrapper
      label={label}
      labelClassName={labelClassName}
      childrenClassName={childrenClassName}
      className={fieldWrapperClass}
    >
      <FormControl
        className={classnames([
          classes.formControl,
          disabled || loading ? classes.disabledState : null
        ])}
        disabled={disabled}
      >
        <Autocomplete
          id="autocomplete-select"
          className={classes.select}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          popupIcon={<KeyboardArrowDown />}
          getOptionSelected={(option, val) => option[idField] === val[idField]}
          getOptionLabel={option => (!option ? '' : option[labelField])}
          options={items || []}
          value={value}
          onChange={onSelectChange}
          onInputChange={(event, val) => onInputChange(val)}
          disabled={disabled || loading}
          classes={{ option: classes.primaryColor }}
          renderInput={params => (
            <TextField
              {...params}
              InputProps={{
                classes: {
                  input: classes.primaryColor,
                  underline: classes.overrideUnderline
                },
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : (
                      params.InputProps.endAdornment
                    )}
                  </React.Fragment>
                )
              }}
            />
          )}
        />
      </FormControl>
    </FieldWrapper>
  );
};

AutoCompleteSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  idField: PropTypes.string,
  labelField: PropTypes.string,
  value: PropTypes.oneOf([PropTypes.object, PropTypes.string]),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  items: PropTypes.array,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  labelClassName: PropTypes.string,
  childrenClassName: PropTypes.string,
  fieldWrapperClass: PropTypes.string
};

AutoCompleteSelect.defaultProps = {
  idField: 'id',
  labelField: 'name',
  onInputChange: () => {}
};

export default observer(AutoCompleteSelect);
