// Core
import { action, observable, runInAction, toJS } from 'mobx';

// Stores
import CommonStore from '@Stores/CommonStore';

// Providers
import { QuestionProvider, StaticDataProvider } from '@Providers/';

class QuestionStore {
  @observable summaryData = [];

  @observable questionsAndAnswers = [];

  @observable questions = [];

  @observable answers = [];

  @observable statusGroup = {};

  @action('QuestionStore => getQuestionsAndAnswers')
  getQuestionsAndAnswers = () => {
    return StaticDataProvider.getStaticDataList(
      'STAY_CONNECTED_QUESTIONS'
    ).then(response => {
      runInAction(() => {
        this.questionsAndAnswers = response.data;
      });
      let answers = [];
      const questions = response.data.map(question => {
        question.answers.map(answer => {
          answers = answers.includes(answer.name)
            ? answers
            : [...answers, answer.name];
          return answers;
        });
        return question.questionText;
      });
      Promise.all([questions, answers]).then(res =>
        this._setQuestionsAndAnswers(res)
      );
    });
  };

  @action('QuestionStore => _setQuestionsAndAnswers')
  _setQuestionsAndAnswers = res => {
    this.questions = res[0];
    this.answers = res[1];
  };

  @action('QuestionStore => getSummaryData')
  getSummaryData = (category, dateRangeParams) => {
    CommonStore.setPending();
    const queryParams = {
      'question.category': category,
      sort: 'question.code',
      ...dateRangeParams
    };
    return QuestionProvider.getSummaryData(queryParams)
      .then(resposnse => {
        runInAction(() => {
          this.summaryData = this.processSummaryData(resposnse.answersGroup);
          this.statusGroup = resposnse.statusGroup;
        });
      })
      .finally(CommonStore.clearPending);
  };

  processSummaryData = answersGroup => {
    // Get unique answer codes
    const uniqueAnswerCodes = new Set();
    answersGroup.forEach(item => {
      item.answers.forEach(answer => {
        uniqueAnswerCodes.add(answer.answer);
      });
    });

    // Get all answers
    const answers = [];
    toJS(this.questionsAndAnswers).forEach(item => {
      item.answers.forEach(answer => {
        if ([...uniqueAnswerCodes].indexOf(answer.code) !== -1) {
          answers.push({
            answerCode: answer.code,
            answerText: answer.name
          });
        }
      });
    });

    // All questions
    const questions = [];
    answersGroup.forEach(item => {
      toJS(this.questionsAndAnswers).forEach(question => {
        if (question.code === item.question.code) {
          item.answers.forEach(answer => {
            questions.push({
              questionText: question.questionText,
              answerCode: answer.answer,
              count: answer.count,
              questionCode: question.code
            });
          });
        }
      });
    });

    // Combine question and answers
    const combined = [];
    questions.forEach(question => {
      answers.forEach(answer => {
        if (question.answerCode === answer.answerCode) {
          combined.push({
            questionText: question.questionText,
            answerText: answer.answerText,
            answerCode: answer.answerCode,
            count: question.count,
            questionCode: question.questionCode
          });
        }
      });
    });

    // Sort array by question code
    const sortedCombined = combined
      .slice()
      .sort((a, b) =>
        Number(a.questionCode.substring(2) - b.questionCode.substring(2))
      );

    // Get unique answer and questions
    const uniqueAnswers = new Set();
    const uniqueQuestions = new Set();
    sortedCombined.forEach(item => {
      uniqueAnswers.add(item.answerText);
      uniqueQuestions.add(item.questionText);
    });

    // Get count of answers for each questions, format the data for google chart
    const questionsArray = [];
    [...uniqueQuestions].forEach(item => {
      const itemInCobined = sortedCombined.filter(
        data => data.questionText === item
      );
      const answerCountArray = [itemInCobined[0].questionText].concat(
        Array([...uniqueAnswers].length).fill(0)
      );
      [...uniqueAnswers].forEach((answer, answerIndex) => {
        itemInCobined.forEach(comb => {
          if (comb.answerText === answer) {
            answerCountArray[answerIndex + 1] = comb.count;
          }
        });
      });
      questionsArray.push(answerCountArray);
    });

    return [['Question(s)', ...uniqueAnswers], ...questionsArray];
  };

  @action('QuestionStore => resetSummaryData')
  resetSummaryData = () => {
    this.summaryData = [];
    this.statusGroup = {};
  };
}

export default new QuestionStore();
