/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import classnames from 'classnames';
import { withTranslation } from 'react-i18next';

// Material UI
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Component
import Loader from '@CommonScene/UploadBox/Loader';

// Utils
import { compose } from '@Utils';
import {
  IconUpload,
  IllustrationDocument,
  IconCancel,
  IconDone
} from '@Utils/constans/icons';

// Styles
import useStyles from './styles';

const UploadBox = ({
  className,
  instructions = [],
  fileExts = [],
  onFile,
  onReset,
  error,
  selectedFile,
  isLoading,
  t
}) => {
  const classes = useStyles();

  const resetState = React.useCallback(() => {
    if (onReset) {
      onReset();
    }
  }, []);

  const onDrop = React.useCallback(([file]) => {
    if (!file) {
      return false;
    }

    const pattern = fileExts.map(ext => `(\\${ext}$)`).join('|');
    const regex = new RegExp(pattern, 'i');

    if (!regex.test(file.name)) {
      return false;
    }

    resetState();

    if (onFile) {
      onFile(file);
    }

    return null;
  }, []);

  const removeSelectedFile = React.useCallback(e => {
    e.stopPropagation();
    resetState();
  }, []);

  return (
    <Dropzone
      onDrop={onDrop}
      multiple={false}
      accept={fileExts}
      disabled={Boolean(selectedFile)}
    >
      {({ getRootProps, getInputProps }) => (
        <Grid
          className={className}
          classes={{ root: classes.wrapperRoot }}
          container
          direction="column"
          justify="center"
        >
          <Grid
            container
            item
            classes={{
              root: classnames(classes.uploadBox, {
                [classes.fileSelected]: Boolean(selectedFile)
              })
            }}
            alignItems="center"
            direction="row"
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <Grid container item alignItems="center" xs>
              <IllustrationDocument />
              <Grid
                item
                direction="row"
                xs
                className={classes.fileNameContainer}
              >
                {selectedFile ? (
                  <>
                    <Typography
                      noWrap
                      className={classes.label}
                      variant="body2"
                    >
                      {selectedFile.name}
                    </Typography>
                    {isLoading && <Loader />}
                    {selectedFile && !isLoading && !error && (
                      <IconDone className={classes.iconDone} />
                    )}
                  </>
                ) : (
                  <Typography className={classes.label} variant="body2">
                    {t('common.dragAndDrop.label')}
                  </Typography>
                )}
              </Grid>
            </Grid>

            {selectedFile ? (
              <Grid
                container
                item
                className={classes.iconWrapper}
                justify="center"
                alignItems="center"
                onClick={removeSelectedFile}
              >
                <IconCancel className={classes.closeIcon} />
              </Grid>
            ) : (
              <Grid
                container
                item
                className={classes.iconWrapper}
                justify="center"
                alignItems="center"
              >
                <IconUpload />
              </Grid>
            )}
          </Grid>
          {Boolean(error) && (
            <Grid container>
              <Typography className={classes.error} variant="caption">
                {error}
              </Typography>
            </Grid>
          )}
          {!selectedFile && Boolean(instructions.length) && (
            <Grid
              container
              item
              direction="column"
              className={classes.instructionWrapper}
            >
              <Typography className={classes.instruction} variant="caption">
                {t('common.dragAndDrop.instructionHeader')}
              </Typography>
              <>
                {instructions.map((instruction, i) => (
                  <Typography
                    className={classes.instruction}
                    key={i}
                    variant="caption"
                  >
                    &bull; {instruction}
                  </Typography>
                ))}
              </>
            </Grid>
          )}
        </Grid>
      )}
    </Dropzone>
  );
};

UploadBox.propTypes = {
  instructions: PropTypes.array,
  className: PropTypes.string,
  t: PropTypes.func,
  fileExts: PropTypes.array,
  onFile: PropTypes.func,
  onReset: PropTypes.func,
  error: PropTypes.string,
  selectedFile: PropTypes.any,
  isLoading: PropTypes.bool
};

export default compose(withTranslation())(UploadBox);
