/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import { observable, action } from 'mobx';

// Stores
import ValidationStore from '@Stores/ValidationStore';

// Configs
import { AuthForms as forms } from '@Assets/config/forms/auth';

// Utils
import {
  generateMnemonic,
  convertToSha256,
  normalizePassword,
  transformMnemonicToPrivateKey,
  encrypt
} from '@Utils/cryptographic';

// Providers
import { AuthProvider } from '@Providers';

const STEPS_AMOUNT = 5;

export class SignUpStore extends ValidationStore {
  @observable forms = forms;

  @observable errors = null;

  stepsAmount = STEPS_AMOUNT;

  @observable step = 0;

  @observable mnemonicPhrase = '';

  @observable normalizedPassword = '';

  @observable encryptedContainer = '';

  @observable serializedQestions = '';

  @action setErrorMessage = errorText => {
    this.errors = errorText;
  };

  @action clearErrorMessage = () => {
    this.errors = null;
  };

  @action _setStep = step => {
    this.step = step;
  };

  @action resetSteps = () => {
    this.step = 0;
  };

  setNextStep = () => {
    if (this.step === STEPS_AMOUNT - 1) {
      return;
    }

    this._setStep(this.step + 1);
  };

  setPrevStep = () => {
    if (this.step === 0) {
      return;
    }

    this._setStep(this.step - 1);
  };

  @action generateMnemonicPhrase = () => {
    this.mnemonicPhrase = generateMnemonic();
  };

  @action normalizePassword = password => {
    if (!password) {
      return;
    }

    this.normalizedPassword = normalizePassword(convertToSha256(password));
  };

  generatePrivateKey = () => {
    return transformMnemonicToPrivateKey(this.mnemonicPhrase);
  };

  @action createEncryptedContainer = () => {
    this.generatePrivateKey().then(privateKey => {
      this.encryptedContainer = encrypt(privateKey, this.normalizedPassword);
    });
  };

  @action setSerializedQestions = serializedQestions => {
    this.serializedQestions = serializedQestions;
  };

  @action verifyInvitation = formData => {
    return AuthProvider.verifyInvitation(formData);
  };

  @action resetStore = () => {
    this.forms = forms;
    this.step = 0;
    this.mnemonicPhrase = '';
    this.serializedQestions = '';
    this.encryptedContainer = '';
    this.normalizedPassword = '';
    this.errors = null;
    window.localStorage.removeItem('tempT');
  };
}

export default new SignUpStore();
