import XLSX from 'xlsx';

export const readXLSX = (file, { sheetRows = 0 } = {}) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = e => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: 'binary', sheetRows });

      const sheetsArr = workbook.SheetNames.map(sheetName => {
        const rows = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
          header: 1
        });

        return rows;
      });

      resolve(sheetsArr);
    };

    reader.onerror = reject;

    reader.readAsBinaryString(file);
  });
