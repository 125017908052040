/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';

// Material UI
import { withStyles } from '@material-ui/core/styles';

// Components
import FieldWrapper from '@CommonScene/FieldWrapper';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

// Utils
import { compose } from '@Utils';
// import { validationKeys } from '@Utils/constans';

// Styles
import styles from './styles';

export class PhoneNumberField extends React.Component {
  static propTypes = {
    value: PropTypes.string,
    classes: PropTypes.object,
    onChange: PropTypes.func,
    error: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    t: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    labelClassName: PropTypes.string,
    childrenClassName: PropTypes.string,
    fieldWrapperClass: PropTypes.string
  };

  static defaultProps = {
    onChange: () => {
      // Stub function
    },
    error: null,
    placeholder: '',
    label: ''
  };

  render() {
    const {
      label,
      error,
      classes,
      onChange,
      value,
      disabled,
      t,
      labelClassName,
      childrenClassName,
      fieldWrapperClass,
      ...other
    } = this.props;
    const restProps = Object.assign({}, other);
    // delete unnecessary props
    delete restProps.tReady;
    delete restProps.i18n;

    return (
      <FieldWrapper
        label={label}
        className={fieldWrapperClass}
        labelClassName={labelClassName}
        childrenClassName={childrenClassName}
      >
        <PhoneInput
          inputClass={classNames([
            classes.inputStyles,
            error ? classes.errorState : null,
            disabled ? classes.disabledState : null
          ])}
          buttonClass={classNames([
            classes.buttonStyles,
            error ? classes.errorState : null
          ])}
          country={'us'}
          onChange={onChange}
          value={value}
          disabled={disabled}
        />
        <div className={classes.errorMessage}>{error}</div>
      </FieldWrapper>
    );
  }
}

export default compose(
  withStyles(styles),
  withTranslation()
)(PhoneNumberField);
