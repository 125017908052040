/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright Â© 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import { Grid, withStyles, Typography } from '@material-ui/core';

// Components
import { BlockWrapper } from '@CommonScene';
import Licenses from '@ProfileManagementScene/scenes/Profile/components/Licenses';

// Utils
import { compose } from '@Utils';

// Styles
import styles from './styles';

const LegalAndPrivacy = ({ classes }) => {
  const items = React.useMemo(
    () => [
      {
        label: 'Terms & Conditions',
        url:
          'https://solve.care/docs/team-care-network-terms-and-conditions-of-use/team-care-network-terms-and-conditions-of-use-1588758837.pdf',
        target: '_blank'
      },
      {
        label: 'Privacy Policy',
        url:
          'https://solve.care/docs/solve-care-website-privacy-policy/solve-care-website-privacy-policy-1588693964.pdf',
        target: '_blank'
      },
      {
        label: 'Licenses',
        Component: Licenses
      }
    ],
    []
  );

  return (
    <BlockWrapper className={classes.wrapper}>
      <Grid container direction="column">
        <Typography variant="body1" className={classes.blockHeader}>
          {'Legal & Privacy'}
        </Typography>

        <Grid container item direction="column">
          {items.map(({ label, Component, onClick, url, target }, i) => {
            const labelElement = (
              <a
                key={i}
                onClick={onClick}
                className={classes.linkText}
                href={url}
                target={target}
              >
                {label}
              </a>
            );

            if (!Component) {
              return labelElement;
            }

            return <Component key={i} label={labelElement} />;
          })}
        </Grid>
      </Grid>
    </BlockWrapper>
  );
};

LegalAndPrivacy.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(withStyles(styles))(LegalAndPrivacy);
