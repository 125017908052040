/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import moment from 'moment';

// Stores
import HAYFTStore from '@Stores/HAYFTStore';

import { formatDate } from '@Utils/formatting';

// Components
import StatusChip from '@CommonScene/materialUIComponents/statusChip';

// Utils
import { compose } from '@Utils';
import { getFilterParams, getSortParams } from '@Utils/ag-grid';
import ExportButton from '@CommonScene/ExportButton';

// Providers
import { VaultProvider } from '@Providers';

// styles
import './styles.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { Box } from '@material-ui/core';

let data = [];

export class Members extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedRowData: [],
      columnDefs: [
        {
          headerName: 'First Name',
          field: 'firstName',
          colId: 'firstName',
          checkboxSelection: true,
          headerCheckboxSelection: true,
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            apply: true,
            newRowsAction: 'keep',
            closeOnApply: true,
            filterOptions: ['contains', 'equals'],
            suppressAndOrCondition: true
          },
          resizable: true,
          valueGetter: params => {
            return params.data && params.data.firstName
              ? params.data.firstName
              : '-';
          }
        },
        {
          headerName: 'Last Name',
          field: 'lastName',
          colId: 'lastName',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            apply: true,
            newRowsAction: 'keep',
            closeOnApply: true,
            filterOptions: ['contains', 'equals'],
            suppressAndOrCondition: true
          },
          resizable: true,
          valueGetter: params => {
            return params.data && params.data.lastName
              ? params.data.lastName
              : '-';
          }
        },
        {
          headerName: 'Middle Name',
          field: 'middleName',
          colId: 'middleName',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            apply: true,
            newRowsAction: 'keep',
            closeOnApply: true,
            filterOptions: ['contains', 'equals'],
            suppressAndOrCondition: true
          },
          hide: true,
          resizable: true,
          valueGetter: params => {
            return params.data && params.data.middleName
              ? params.data.middleName
              : '-';
          }
        },
        {
          headerName: 'Title',
          field: 'title.name',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            apply: true,
            newRowsAction: 'keep',
            closeOnApply: true,
            filterOptions: ['contains', 'equals'],
            suppressAndOrCondition: true
          },
          hide: true,
          resizable: true,
          colId: 'title',
          valueGetter: params => {
            return params.data && params.data.title && params.data.title.name
              ? params.data.title.name
              : '-';
          }
        },
        {
          headerName: 'Employee Id',
          field: 'employeeId',
          colId: 'employeeId',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            apply: true,
            newRowsAction: 'keep',
            closeOnApply: true,
            filterOptions: ['contains', 'equals'],
            suppressAndOrCondition: true
          },
          hide: true,
          resizable: true,
          valueGetter: params => {
            return params.data && params.data.employeeId
              ? params.data.employeeId
              : '-';
          }
        },
        {
          headerName: 'Country',
          field: 'address.city.country',
          colId: 'address.city.country',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            apply: true,
            newRowsAction: 'keep',
            closeOnApply: true,
            filterOptions: ['contains', 'equals'],
            suppressAndOrCondition: true
          },
          hide: true,
          resizable: true,
          valueGetter: params => {
            return params.data &&
              params.data.address &&
              params.data.address.city &&
              params.data.address.city.country
              ? params.data.address.city.country
              : '-';
          }
        },
        {
          headerName: 'Region',
          field: 'address.city.region',
          colId: 'address.city.region',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            apply: true,
            newRowsAction: 'keep',
            closeOnApply: true,
            filterOptions: ['contains', 'equals'],
            suppressAndOrCondition: true
          },
          hide: true,
          resizable: true,
          valueGetter: params => {
            return params.data &&
              params.data.address &&
              params.data.address.city &&
              params.data.address.city.region
              ? params.data.address.city.region
              : '-';
          }
        },
        {
          headerName: 'City',
          field: 'address.city.name',
          colId: 'address.city.name',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            apply: true,
            newRowsAction: 'keep',
            closeOnApply: true,
            filterOptions: ['contains', 'equals'],
            suppressAndOrCondition: true
          },
          hide: true,
          resizable: true,
          valueGetter: params => {
            return params.data &&
              params.data.address &&
              params.data.address.city &&
              params.data.address.city.name
              ? params.data.address.city.name
              : '-';
          }
        },
        {
          headerName: 'Job Title',
          field: 'jobTitle.name',
          colId: 'jobTitle',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            apply: true,
            newRowsAction: 'keep',
            closeOnApply: true,
            filterOptions: ['contains', 'equals'],
            suppressAndOrCondition: true
          },
          hide: true,
          resizable: true,
          valueGetter: params => {
            return params.data &&
              params.data.jobTitle &&
              params.data.jobTitle.name
              ? params.data.jobTitle.name
              : '-';
          }
        },
        {
          headerName: 'Line Manager',
          field: 'lineManager',
          colId: 'lineManager',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            apply: true,
            newRowsAction: 'keep',
            closeOnApply: true,
            filterOptions: ['contains', 'equals'],
            suppressAndOrCondition: true
          },
          hide: true,
          resizable: true,
          valueGetter: params => {
            return params.data && params.data.lineManager
              ? params.data.lineManager
              : '-';
          }
        },
        {
          headerName: 'Gender',
          field: 'gender.name',
          colId: 'gender',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            apply: true,
            newRowsAction: 'keep',
            closeOnApply: true,
            filterOptions: ['contains', 'equals'],
            suppressAndOrCondition: true
          },
          hide: true,
          resizable: true,
          valueGetter: params => {
            return params.data && params.gender && params.gender.name
              ? params.gender.name
              : '-';
          }
        },
        {
          headerName: 'Job Grade',
          field: 'jobGrade.name',
          colId: 'jobGrade',
          sortable: false,
          hide: true,
          resizable: true,
          valueGetter: params => {
            return params.data && params.jobGrade && params.jobGrade.name
              ? params.jobGrade.name
              : '-';
          }
        },
        {
          headerName: 'Last Modified',
          field: 'status_updated_at',
          colId: 'status_updated_at',
          sortable: true,
          hide: true,
          valueGetter: params => {
            return params.data && params.data.dob
              ? formatDate(params.data.dob)
              : '-';
          },
          filter: 'agDateColumnFilter',
          filterParams: {
            comparator(filterLocalDateAtMidnight, cellValue) {
              const dateValue = formatDate(cellValue);
              const dateAsString = moment(dateValue).format('DD/MM/YYYY');
              if (dateAsString === null) {
                return -1;
              }
              const dateParts = dateAsString.split('/');
              const cellDate = new Date(
                Number(dateParts[2]),
                Number(dateParts[1]) - 1,
                Number(dateParts[0])
              );

              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              }
              if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              }
              return 0;
            },
            browserDatePicker: true,
            apply: true,
            newRowsAction: 'keep',
            closeOnApply: true,
            filterOptions: ['greaterThan', 'lessThan'],
            suppressAndOrCondition: true
          },
          resizable: true
        },
        {
          headerName: 'Employment Category',
          field: 'employmentCategory.name',
          colId: 'employmentCategory',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            apply: true,
            newRowsAction: 'keep',
            closeOnApply: true,
            filterOptions: ['contains', 'equals'],
            suppressAndOrCondition: true
          },
          hide: true,
          resizable: true,
          valueGetter: params => {
            return params.data &&
              params.data.employmentCategory &&
              params.data.employmentCategory.name
              ? params.data.employmentCategory.name
              : '-';
          }
        },
        {
          headerName: 'DOB',
          field: 'dob',
          colId: 'dob',
          sortable: true,
          valueGetter: params => {
            return params.data && params.data.dob
              ? formatDate(params.data.dob)
              : '-';
          },
          filter: 'agDateColumnFilter',
          filterParams: {
            comparator(filterLocalDateAtMidnight, cellValue) {
              const dateValue = formatDate(cellValue);
              const dateAsString = moment(dateValue).format('DD/MM/YYYY');
              if (dateAsString === null) {
                return -1;
              }
              const dateParts = dateAsString.split('/');
              const cellDate = new Date(
                Number(dateParts[2]),
                Number(dateParts[1]) - 1,
                Number(dateParts[0])
              );

              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              }
              if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              }
              return 0;
            },
            browserDatePicker: true,
            apply: true,
            newRowsAction: 'keep',
            closeOnApply: true,
            filterOptions: ['greaterThan', 'lessThan'],
            suppressAndOrCondition: true
          },
          hide: true,
          resizable: true
        },
        {
          headerName: 'Phone',
          field: 'phone',
          colId: 'phone',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            apply: true,
            newRowsAction: 'keep',
            closeOnApply: true,
            filterOptions: ['contains', 'equals'],
            suppressAndOrCondition: true
          },
          resizable: true,
          valueGetter: params => {
            return params.data && params.data.phone ? params.data.phone : '-';
          }
        },
        {
          headerName: 'E-mail',
          field: 'email',
          colId: 'email',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            apply: true,
            newRowsAction: 'keep',
            closeOnApply: true,
            filterOptions: ['contains', 'equals'],
            suppressAndOrCondition: true
          },
          resizable: true,
          valueGetter: params => {
            return params.data && params.data.email ? params.data.email : '-';
          }
        },
        {
          headerName: 'Team/Dept.',
          field: 'teamName',
          colId: 'teamName',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            apply: true,
            newRowsAction: 'keep',
            closeOnApply: true,
            filterOptions: ['contains', 'equals'],
            suppressAndOrCondition: true
          },
          resizable: true,
          valueGetter: params => {
            return params.data && params.data.teamName
              ? params.data.teamName
              : '-';
          }
        },
        {
          headerName: 'Job Status',
          field: 'jobStatus.name',
          colId: 'jobStatus',
          sortable: true,
          hide: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            apply: true,
            newRowsAction: 'keep',
            closeOnApply: true,
            filterOptions: ['contains', 'equals'],
            suppressAndOrCondition: true
          },
          resizable: true,
          valueGetter: params => {
            return params.data &&
              params.data.jobStatus &&
              params.data.jobStatus.name
              ? params.data.jobStatus.name
              : '-';
          }
        },
        {
          headerName: 'Office',
          field: 'officeAddress',
          colId: 'officeAddress',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            apply: true,
            newRowsAction: 'keep',
            closeOnApply: true,
            filterOptions: ['contains', 'equals'],
            suppressAndOrCondition: true
          },
          resizable: true,
          valueGetter: params => {
            return params.data && params.data.officeAddress
              ? params.data.officeAddress
              : '-';
          }
        },
        {
          headerName: 'Status',
          field: 'status',
          resizable: true,
          filter: 'agSetColumnFilter',
          filterParams: {
            values: ['New', 'Active', 'Pending', 'Expired'],
            apply: true,
            newRowsAction: 'keep',
            closeOnApply: true
          },
          width: 180,
          sortable: false,
          cellRenderer: 'statusChipRenderer',
          cellRendererParams: params => {
            return { text: params.data ? params.data.status : params.value };
          },
          colId: 'status'
        }
      ],
      defaultColDef: {
        flex: 1,
        minWidth: 100,
        resizable: true,
        sortable: true,
        filter: true,
        enableRowGroup: false
      },
      frameworkComponents: {
        statusChipRenderer: StatusChip
      },
      paginationPageSize: 25,
      cacheBlockSize: 25,
      pagination: true,
      rowModelType: 'serverSide',
      maxBlocksInCache: 1,
      rowSelection: 'multiple',
      multiSortKey: 'ctrl',
      suppressExcelExport: true,
      suppressCsvExport: true,
      rowData: [],
      checkbox: false,
      totalPageSize: 0,
      recordSelected: 0,
      loadedData: [],
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressRowGroups: false
            }
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel'
          }
        ],
        defaultToolPanel: ''
      }
    };
  }

  static propTypes = {
    HAYFTStore: PropTypes.object.isRequired
  };

  componentDidMount() {
    data = [];
  }

  componentWillUnmount() {
    const {
      HAYFTStore: { resetProfilesList, resetOffset }
    } = this.props;
    resetOffset();
    resetProfilesList();
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.setServerSideDatasource(this.serverSideDatasource());
  };

  serverSideDatasource = () => {
    return {
      getRows: params => {
        const filterParams = getFilterParams(params.request.filterModel);
        const sortParams = getSortParams(params.request.sortModel);
        const param = `sort=${sortParams || 'audit.updatedAt%2Cdesc'}&offset=${
          params.request.startRow
        }&limit=${params.request.endRow -
          params.request.startRow}&node.nodeId[gt]=0${filterParams.replace(
          /%20/g,
          ' '
        )}`;
        return VaultProvider.getProfiles(param).then(profilesResponse => {
          this.setState({ totalPageSize: profilesResponse.pagination.total });
          this.setLoadedData(profilesResponse.data);
          profilesResponse.data.forEach(item =>
            !profilesResponse.data.includes(item) ? data.push(item) : ''
          );
          if (profilesResponse.data.length > 0) {
            params.successCallback(
              profilesResponse.data,
              profilesResponse.pagination.total
            );
          } else {
            params.successCallback(profilesResponse.data, 0);
          }
          if (this.state.checkbox) {
            this.gridApi.forEachNode(node => {
              node.setSelected(true);
            });
          }
        });
      }
    };
  };

  onRowSelected = event => {
    if (event.node.selected === true) {
      const temp = Object.assign({}, event.node.data);
      temp.checked = true;
      data[event.node.rowIndex] = temp;
      HAYFTStore.alterProfile(data);
      this.setState(
        {
          checkbox: this.gridApi.getSelectedRows().length % 25 === 0
        },
        () => {
          if (
            !this.state.checkbox &&
            this.gridApi.getSelectedRows().length % 25 === 0
          ) {
            this.gridApi.deselectAll();
          }
        }
      );
    } else {
      const temp = Object.assign({}, event.node.data);
      temp.checked = false;
      data[event.node.rowIndex] = temp;
      HAYFTStore.alterProfile(data);
      this.setState({ checkbox: false }, () => {
        if (
          !this.state.checkbox &&
          this.gridApi.getSelectedRows().length % 25 === 0
        ) {
          this.gridApi.deselectAll();
        }
      });
    }
    this.setState({ recordSelected: this.gridApi.getSelectedRows().length });
  };

  clearAllFilter = () => {
    this.gridApi.setFilterModel({});
    this.gridApi.setSortModel({});
    this.gridApi.setServerSideDatasource(this.serverSideDatasource());
  };

  headerSelection = event => {
    this.gridApi.forEachNode(node => {
      if (event.target.checked === true) {
        this.setState({ checkbox: true });
        node.setSelected(true);
      } else {
        this.setState({ checkbox: false });
        node.setSelected(false);
      }
    });
  };

  setLoadedData = records => {
    if (this.state.loadedData.length > 0) {
      const allreadyLoadedData = [...this.state.loadedData];
      records.forEach(newItem => {
        const exists = allreadyLoadedData.find(
          item => item.guid === newItem.guid
        );

        if (!exists) {
          allreadyLoadedData.push(newItem);
        }
      });
      this.setState({ loadedData: allreadyLoadedData });
    } else {
      this.setState({ loadedData: records });
    }
  };

  getCSVData = records => {
    return records.map(item => {
      return {
        Title: item.title && item.title.name,
        'Full Name': `${item.firstName}  ${item.lastName}`,
        'Middle Name': item.middleName,
        'Last Name': item.lastName,
        'First Name': item.firstName,
        Phone: item.phone,
        'E-mail': item.email,
        'Employee Id': item.employeeId,
        Country: item.address && item.address.city.country,
        Region: item.address && item.address.city.region,
        City: item.address && item.address.city.name,
        'Job Title': item.jobTitle && item.jobTitle.name,
        'Line Manager': item.lineManager,
        Gender: item.gender && item.gender.name,
        'Job Grade': item.jobGrade && item.jobGrade.name,
        'Last Modified': formatDate(item.status_updated_at),
        'Employment Category':
          item.employmentCategory && item.employmentCategory.name,
        DOB: formatDate(item.dob, 'MM/DD/YYYY'),
        Office: item.officeAddress,
        Team: item.teamName,
        Status: item.status
      };
    });
  };

  render() {
    const { loadedData } = this.state;
    return (
      <>
        <div className="filterAll">
          <button className="clear-all-myteam" onClick={this.clearAllFilter}>
            Clear Filter
          </button>
          {loadedData.length > 0 && (
            <ExportButton
              count={loadedData.length}
              jsonData={this.getCSVData(loadedData)}
            />
          )}
        </div>
        <Box display="flex" justifyContent="space-between">
          <div className="paraentSelectedRows">
            <label className="selectAll">
              <input
                type="checkbox"
                onChange={event => this.headerSelection(event)}
                checked={this.state.checkbox}
              />
              Select All
            </label>
            <p className="selectedRowsHayft">
              <b>
                {this.state.recordSelected} of {this.state.totalPageSize}{' '}
                selected{' '}
              </b>
            </p>
          </div>
        </Box>

        <div className="ag-theme-alpine" style={{ width: '100%' }}>
          <AgGridReact
            rowStyle={{ cursor: 'pointer' }}
            frameworkComponents={this.state.frameworkComponents}
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            onGridReady={this.onGridReady}
            sideBar={this.state.sideBar}
            paginationPageSize={this.state.paginationPageSize}
            pagination={this.state.pagination}
            cacheBlockSize={this.state.cacheBlockSize}
            rowSelection={this.state.rowSelection}
            suppressRowClickSelection
            suppressCellSelection
            onRowSelected={this.onRowSelected}
            rowModelType={this.state.rowModelType}
            maxBlocksInCache={this.state.maxBlocksInCache}
            suppressExcelExport={this.state.suppressExcelExport}
            suppressCsvExport={this.state.suppressCsvExport}
            onFilterChanged={() => this.setState({ loadedData: [] })}
          />
          <br />
        </div>
      </>
    );
  }
}
export default compose(
  inject('HAYFTStore'),
  observer
)(Members);
