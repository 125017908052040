/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright Â© 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import { withTranslation } from 'react-i18next';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { FormControl, Input } from '@material-ui/core';

// Components
import FieldWrapper from '@CommonScene/FieldWrapper';

// Utils
import { compose } from '@Utils';
import { validationKeys } from '@Utils/constans';

// Styles
import styles from './styles';

export class PhoneNumberField extends React.Component {
  static propTypes = {
    value: PropTypes.string,
    classes: PropTypes.object,
    onChange: PropTypes.func,
    error: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    mask: PropTypes.array,
    placeholderChar: PropTypes.string,
    t: PropTypes.func.isRequired,
    showMask: PropTypes.bool
  };

  static defaultProps = {
    onChange: () => {
      // Stub function
    },
    error: null,
    placeholder: '',
    label: '',
    mask: [
      '+',
      /\d/,
      '(',
      /\d/,
      /\d/,
      /\d/,
      ')',
      '-',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d?/
    ],
    placeholderChar: '\u2000'
  };

  renderMaskInput = props => {
    const { inputRef, ...other } = props;
    return (
      <MaskedInput
        ref={ref => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={this.props.mask}
        placeholderChar={this.props.placeholderChar}
        showMask={this.props.showMask}
        {...other}
      />
    );
  };

  render() {
    const { label, error, classes, onChange, t, ...other } = this.props;
    // fix for 'React does not recognize the `tReady` prop on a DOM element...'
    const restProps = Object.assign({}, other);
    delete restProps.tReady;
    delete restProps.i18n;

    const input = (
      <FormControl
        error={!!error}
        id="t1"
        className={classes.formControl}
        aria-describedby="input-component"
      >
        <Input
          id="input-wrapper"
          type="text"
          classes={{ input: classes.inputRoot }}
          inputComponent={this.renderMaskInput}
          onChange={onChange}
          {...restProps}
        />
      </FormControl>
    );

    return label ? (
      <FieldWrapper label={label} className={classes.margin}>
        {input}
        {error ? (
          <span className={classes.errorText}>{t(validationKeys[error])}</span>
        ) : null}
      </FieldWrapper>
    ) : (
      input
    );
  }
}

export default compose(
  withStyles(styles),
  withTranslation()
)(PhoneNumberField);
