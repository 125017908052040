import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  popover: {
    pointerEvents: 'none',
    margin: '10px 0 0 -10px'
  },
  paper: {
    padding: theme.spacing(1)
  }
}));

const ColRender = props => {
  const classes = useStyles();
  const { params, field } = props;
  const { value, data } = params;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const rowErrors = data.errors;
  const colErrors = rowErrors
    ? rowErrors.filter(rowError => rowError.field === field)
    : [];
  const open = Boolean(anchorEl);
  const handlePopoverOpen = event => setAnchorEl(event.currentTarget);
  const handlePopoverClose = () => setAnchorEl(null);
  return (
    <>
      <span onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
        {colErrors.length ? <span className="errorIcon">!</span> : null}
      </span>
      {value}
      {colErrors.length ? (
        <Popover
          id="mouse-over-popover"
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
          className={classes.popover}
          classes={{ paper: classes.paper }}
        >
          {colErrors.map(colError => colError.errorsTexts)}
        </Popover>
      ) : null}
    </>
  );
};
ColRender.propTypes = {
  params: PropTypes.object,
  field: PropTypes.oneOfType([PropTypes.string, PropTypes.undefined])
};
export default ColRender;
