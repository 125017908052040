/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { useEffect } from 'react';
import { observer, inject, PropTypes } from 'mobx-react';

// Material UI
import { Grid } from '@material-ui/core';

// Components
import QuestionBlock from '@AuthScene/scenes/SignUp/components/RecoveryQuestions/components/QuestionsBlocks/components/QuestionBlock';

// Utils
import { compose } from '@Utils';

// Stores
import { useStore } from '@Utils/hooks';

const Questions = ({ formName, onChangeCallback }) => {
  const {
    AuthStore: {
      getRecoveryQuestions,
      recoveryQuestions,
      forms,
      onFieldChange,
      markSelectedQuestion
    }
  } = useStore();

  useEffect(() => {
    getRecoveryQuestions();
  }, []);

  const getFormConfig = () => {
    return {
      onFieldChange,
      markSelectedQuestion,
      name: formName || 'recoveryQuestionsForm',
      fields: forms[formName || 'recoveryQuestionsForm'].fields,
      isValid: forms[formName || 'recoveryQuestionsForm'].meta.isValid
    };
  };

  return (
    <Grid>
      <QuestionBlock
        formConfig={getFormConfig()}
        index={1}
        questions={recoveryQuestions}
        onChangeCallback={onChangeCallback}
      />
      <QuestionBlock
        formConfig={getFormConfig()}
        index={2}
        questions={recoveryQuestions}
        onChangeCallback={onChangeCallback}
      />
      <QuestionBlock
        formConfig={getFormConfig()}
        index={3}
        questions={recoveryQuestions}
        onChangeCallback={onChangeCallback}
      />
    </Grid>
  );
};

Questions.propTypes = {
  formName: PropTypes.string,
  onChangeCallback: PropTypes.func
};

export default compose(
  inject('SignUpStore'),
  observer
)(Questions);
