/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import { action, observable, runInAction, toJS } from 'mobx';
// Stores
import ValidationStore from '@Stores/ValidationStore';
// Providers
import {
  FileManagerProvider,
  InvitationProvider,
  NpsProvider,
  StaticDataProvider,
  VaultProvider,
  WalletsProvider
} from '@Providers';
// Stores
import CommonStore from '@Stores/CommonStore';
// Utils
import { MyTeamForms as forms } from '@Assets/config/forms/myTeam';
import { generateEventBody, getSignedEvent } from '@Utils/blockchain';
import { generateGUID, getResourcePayload } from '@Utils';
import { getParsedCookies } from '@Utils/formatting';
import { readXLSX } from '@Utils/xlsx';
import moment from 'moment';
import { ValidateRowsInit } from '@Utils/ag-grid';
import { BULK_UPLOAD_STATUS } from '@Utils/constans';
import debounce from 'lodash/debounce';

class MyTeamStore extends ValidationStore {
  @observable forms = forms;

  members = observable.array([]);

  @observable offset = 0;

  @observable limit = 50;

  @observable lastPage = false;

  @observable bulkTemplateHeaders = [];

  @observable bulkTemplateDownloadURL = null;

  bulkTemplateFileName = 'EMPLOYEE_LOAD_TEMPLATE.xlsx';

  @observable latestUploadHistory = null;

  @observable newUpload = false;

  @observable showResendNotification = false;

  @observable isHiddenLogNotification = true;

  @observable resendNotificationMsg = '';

  // temporary solution for notification
  @observable lastClosedUploadNotification = localStorage.getItem(
    'lastClosedUploadNotification'
  );

  @observable checkedAllRows = false;

  @observable checkedCount = 0;

  @observable checkedPendingExpiredCount = 0;

  @observable hasNextPage = true;

  @observable checkedNewStatusCount = 0;

  @observable invalidRowsInCSV = [];

  @observable validRowsInCSV = [];

  @observable sendInvitationSMS = true;

  @observable countries = [];

  @observable countryCode = '';

  @action('MyTeamStore => setCountryCode') setCountryCode = code => {
    this.countryCode = code;
  };

  searchWithDebounce = debounce(q => {
    this._getStaticData('CITY', 'city', {
      version: 1,
      'country.ref.sd.code[eq]': this.countryCode,
      limit: 300,
      'name[like]': q
    }).then(response => {
      this.updateFieldProp('city', 'items', response.data);
    });
  }, 50);

  @action('MyTeamStore => setQuery') setQuery = query => {
    // TODO: set query value if needed
    this.searchWithDebounce(query);
  };

  @action('MyTeamStore => setNewUpload') setNewUpload = state => {
    this.newUpload = state;
  };

  @action('MyTeamStore => clearForms')
  clearForms = () => {
    this.forms = forms;
  };

  @action('MyTeamStore => createNewMember')
  createNewMember = (data, isExisted = false) => {
    const tenantId = getParsedCookies().tenant;
    const inviteNow = data.sendCheckbox.value === 'sendSms';
    const formData = isExisted ? data : this.createNewMemberPayload(data);

    let payload = {
      guid: generateGUID(),
      inviteNow,
      employeeProfile: formData
    };

    return WalletsProvider.getNode(tenantId)
      .then(nodeResponse => {
        WalletsProvider.getPlatformProperties().then(
          platformPropertiesReponse => {
            if (formData.send_sms) {
              NpsProvider.inviteUser({ ...formData, roles: [3] }).then(() => {
                NpsProvider.getHolder({
                  queryParams: { phone: formData.phone }
                }).then(response => {
                  payload = {
                    ...payload,
                    node: {
                      nodeId: `${response.nodes[0].id}`,
                      scAddress: response.nodes[0].smart_contract_address
                    }
                  };

                  delete payload.employeeProfile.send_sms;

                  const event = generateEventBody({
                    eventCode: 'EMPLOYEE.PROFILE.LOADED',
                    payload,
                    scAddress: nodeResponse.smart_contract_address,
                    tenant: tenantId
                  });
                  WalletsProvider.sendEvent(
                    getSignedEvent(
                      event,
                      platformPropertiesReponse.event_ledger_address
                    )
                  );
                });
              });
            } else {
              delete payload.employeeProfile.send_sms;
              const event = generateEventBody({
                eventCode: 'EMPLOYEE.PROFILE.LOADED',
                payload,
                scAddress: nodeResponse.smart_contract_address,
                tenant: tenantId
              });

              WalletsProvider.sendEvent(
                getSignedEvent(
                  event,
                  platformPropertiesReponse.event_ledger_address
                )
              );
            }
          }
        );
      })
      .catch(this._handleRequestError);
  };

  @action('MyTeamStore => getTeamMembers')
  getTeamMembers = () => {
    CommonStore.setPending();
    const query = {
      sort: 'audit.updatedAt%2Cdesc',
      offset: this.offset,
      limit: this.limit
    };

    return VaultProvider.getProfiles({ query }).then(profilesResponse => {
      const profiles = profilesResponse.data;
      if (!profilesResponse.pagination.hasNext) {
        this.reachedLastPage();
      }
      const requests = profiles.map(profile => {
        return InvitationProvider.getParticipantList({
          q: profile.phone
        }).then(participantsResponse => {
          const content = participantsResponse.content[0] || {};
          return {
            ...profile,
            status: content.status,
            status_updated_at: content.last_updated,
            role_id: content.role && content.role.id,
            id: content.id
          };
        });
      });
      Promise.all(requests)
        .then(mappedMembers => {
          this._setMembersList({ ...profilesResponse, data: mappedMembers });
        })
        .finally(CommonStore.clearPending);
    });
  };

  @action('MyTeamStore => _setMembersList')
  _setMembersList = response => {
    this.lastPage = !response.pagination.hasNext;

    if (this.offset === 0) {
      this.members.replace(response.data);
      return;
    }

    const members = [...this.members, ...response.data];
    this.members.replace(members);
  };

  @action('MyTeamStore => setMemberData') setMemberData = members => {
    this.members.replace(members);
  };

  @action('MyTeamStore => changeOffset') changeOffset = () => {
    this.offset = this.offset + this.limit;
  };

  @action('MyTeamStore => resetMembersList')
  resetMembersList = () => {
    this.resetOffset();
    this.checkedAllRows = false;
    this.checkedCount = 0;
    this.checkedPendingExpiredCount = 0;
    this.checkedNewStatusCount = 0;
    this.members.replace([]);
  };

  @action('MyTeamStore => resetOffset') resetOffset = () => {
    this.offset = 0;
  };

  @action('MyTeamStore => setBulkTemplateHeaders')
  setBulkTemplateHeaders = headers => {
    this.bulkTemplateHeaders = headers;
  };

  @action('MyTeamStore => bulkTemplateDownloadURL')
  setBulkTemplateDownloadURL = url => {
    this.bulkTemplateDownloadURL = url;
  };

  @action('MyTeamStore => closeUploadNotification')
  closeUploadNotification = () => {
    const guid = this.latestUploadHistory.guid;
    this.lastClosedUploadNotification = guid;
    // this.updateLogNotificationVisibility();
    this.isHiddenLogNotification = true;
    localStorage.setItem('lastClosedUploadNotification', guid);
  };

  @action('MyTeamStore => setLatestUploadHistory')
  setLatestUploadHistory = history => {
    this.latestUploadHistory = {
      status: history.currentStatus,
      guid: history.guid,
      hasFailure: history.processingStatistics
        ? history.processingStatistics.invalidRows > 0
        : true
    };
    if (
      window.localStorage.getItem('lastClosedUploadNotification') !==
      history.guid
    ) {
      this.updateLogNotificationVisibility();
    }
  };

  _baseInvite = ({ rowId, method }) => {
    CommonStore.setPending();

    return InvitationProvider.getParticipantList({
      q: rowId
    }).then(participantsResponse => {
      const content = participantsResponse.content[0] || {};
      const currentMember = {
        ...this.members.find(member => rowId === member.phone),
        role_id: content.role && content.role.id,
        id: content.id
      };

      let payload = {};

      if (method === 'inviteUser') {
        payload = [
          {
            identity: currentMember.phone,
            identity_type: 'PHONE',
            role_id: currentMember.role_id,
            send_notification: true
          }
        ];
      } else if (method === 'resendInvitation') {
        payload = { participants: [currentMember.id] };
      } else {
        payload = { ...payload, role: currentMember.role_id };
      }

      return NpsProvider[method](payload)
        .then(this.updateParticipants)
        .catch(this._handleRequestError)
        .finally(CommonStore.clearPending);
    });
  };

  resendInvitation = rowId => {
    return this._baseInvite({
      rowId,
      method: 'resendInvitation'
    });
  };

  resendBatchInvitations = (data, isFilter) => {
    CommonStore.setPending();
    if (isFilter) {
      return NpsProvider.resendInvitation({
        filter: { statuses: data, roles: ['MEMBER'] }
      })
        .then(this.updateParticipants)
        .catch(this._handleRequestError)
        .finally(CommonStore.clearPending);
    }
    const requests = data.map(phone => {
      return InvitationProvider.getParticipantList({
        q: phone
      }).then(participantsResponse => {
        const content = participantsResponse.content[0] || {};
        return content.id;
      });
    });
    return Promise.all(requests).then(ids => {
      return NpsProvider.resendInvitation({
        participants: ids
      })
        .then(this.updateParticipants)
        .catch(this._handleRequestError)
        .finally(CommonStore.clearPending);
    });
  };

  sendInvitationsForNewParticipants = () => {
    CommonStore.setPending();
    return InvitationProvider.getParticipantList({
      statuses: 'NEW',
      size: 500
    })
      .then(response => {
        const membersForSend = response.content.map(item => {
          return {
            identity: item.phone,
            identity_type: 'PHONE',
            role_id: item.role.id,
            send_notification: true
          };
        });
        return this.sendBatchInvitations(membersForSend);
      })
      .catch(this._handleRequestError)
      .finally(CommonStore.clearPending);
  };

  sendBatchInvitations = payload => {
    CommonStore.setPending();
    const requests = payload.map(member => {
      return InvitationProvider.getParticipantList({
        q: member.identity || member.phone
      }).then(participantsResponse => {
        const content = participantsResponse.content[0] || {};
        return { ...member, role_id: content.role && content.role.id };
      });
    });
    return Promise.all(requests).then(members => {
      return NpsProvider.inviteUser(members)
        .then(this.updateParticipants)
        .catch(this._handleRequestError)
        .finally(CommonStore.clearPending);
    });
  };

  sendInvitation = rowId => {
    const currentMember = {
      ...this.members.find(member => rowId === member.phone),
      send_sms: true
    };
    delete currentMember.guid;
    delete currentMember.id;
    delete currentMember.role_id;
    delete currentMember.status;
    return this.createNewMember(currentMember, true);
  };

  revokeInvitation = rowId => {
    return this._baseInvite({
      rowId,
      method: 'revokeInvitation'
    });
  };

  inviteUser = rowId => {
    return this._baseInvite({
      rowId,
      method: 'inviteUser'
    });
  };

  bulkUpload = file => {
    const guid = generateGUID();
    const fd = new FormData();

    fd.append('directory', `/participants/bulk_upload/${guid}`);
    fd.append('file', file);

    return FileManagerProvider.upload(fd);
  };

  createBulkNewMembers = (data, fileName) => {
    const guid = generateGUID();
    const fd = {};
    fd.content = data;
    fd.contentType = 'plain/text';
    fd.directory = `/participants/bulk_upload/${guid}`;
    fd.fileName = fileName;
    this.updateLogNotificationVisibility(true);
    return FileManagerProvider.bulkUpload(fd);
  };

  getBulkTemplateFile = () => {
    CommonStore.setPending();

    return FileManagerProvider.getBulkTemplateFile()
      .then(blob => {
        const file = new File([blob], this.bulkTemplateFileName);

        this.setBulkTemplateDownloadURL(URL.createObjectURL(file));

        readXLSX(file).then(([sheet1]) => {
          const [headers] = sheet1;

          this.setBulkTemplateHeaders(headers);
        });
      })
      .finally(() => {
        CommonStore.clearPending();
      });
  };

  sendEmployeeUploadStartedEvent = (file, inviteNow) => {
    const tenantId = getParsedCookies().tenant;

    return WalletsProvider.getNode(tenantId).then(nodeResponse => {
      WalletsProvider.getPlatformProperties().then(
        platformPropertiesReponse => {
          const payload = {
            file,
            inviteNow
          };

          const event = generateEventBody({
            eventCode: 'EMPLOYEE.UPLOAD.STARTED',
            payload,
            scAddress: nodeResponse.smart_contract_address,
            tenant: tenantId
          });

          WalletsProvider.sendEvent(
            getSignedEvent(
              event,
              platformPropertiesReponse.event_ledger_address
            )
          );
        }
      );
    });
  };

  getLatestUploadStatus = () => {
    const query = {
      version: 1,
      sort: 'audit.updatedAt,desc',
      offset: 0,
      limit: 1
    };

    VaultProvider.getImportLogs(query).then(({ data }) => {
      const [latestHistory] = data;

      if (!latestHistory) {
        return false;
      }

      if (latestHistory.currentStatus === 'STARTED') {
        // remove the new upload state
        // because the latest history will replace it
        this.setNewUpload(false);
      }

      this.setLatestUploadHistory(latestHistory);

      return null;
    });
  };

  @action('MyTeamStore => toggleAllTableRowChecked')
  toggleAllTableRowChecked = () => {
    this.members.forEach((member, index) => {
      this.members[index].checked = !this.checkedAllRows;
    });
    this.checkedAllRows = !this.checkedAllRows;
    this.checkedCount = this.members.filter(member => member.checked).length;
    this.checkedPendingExpiredCount = this.members.filter(
      member =>
        member.checked &&
        (member.status === 'PENDING' || member.status === 'EXPIRED')
    ).length;
    this.checkedNewStatusCount = this.members.filter(
      member => member.checked && member.status === 'NEW'
    ).length;
  };

  @action('MyTeamStore => toggleTableRowChecked')
  toggleTableRowChecked = rowId => {
    this.members.forEach((member, index) => {
      if (rowId === member.phone) {
        this.members[index].checked = !member.checked;
      }
    });
    this.checkedAllRows = this.members.every(member => member.checked);
    this.checkedCount = this.members.filter(member => member.checked).length;
    this.checkedPendingExpiredCount = this.members.filter(
      member =>
        member.checked &&
        (member.status === 'PENDING' || member.status === 'EXPIRED')
    ).length;
    this.checkedNewStatusCount = this.members.filter(
      member => member.checked && member.status === 'NEW'
    ).length;
  };

  @action('MyTeamStore => loadStaticData')
  loadStaticData = () => {
    return Promise.all([
      this._getStaticData('TITLE', 'title', { limit: 300 }),
      this._getStaticData('GENDER', 'gender', { limit: 300 }),
      this._getStaticData('COUNTRY', 'country', { limit: 300 }),
      this._getStaticData('JOB_TITLE', 'jobTitle', { limit: 300 }),
      this._getStaticData('JOB_GRADE', 'jobGrade', { limit: 300 }),
      this._getStaticData('JOB_STATUS', 'jobStatus', { limit: 300 }),
      this._getStaticData('EMPLOYMENT_CATEGORY', 'employmentCategory', {
        limit: 300
      })
    ]).then(values => {
      values.forEach(staticData => {
        if (staticData != null) {
          const { id, data } = staticData;
          this.updateFieldProp(id, 'items', data);
        }
      });
    });
  };

  @action('MyTamStore => onCountryFieldChange')
  onCountryFieldChange = (field, selectValue, formName) => {
    Promise.all([this.onFieldChange(field, selectValue, formName)])
      .then(() => {
        this.updateFieldProp('city', 'value', '');
        this.updateFieldProp('region', 'value', '');
      })
      .then(() => {
        if (selectValue && selectValue.code) {
          this.setCountryCode(selectValue.code);
          return this._getStaticData('CITY', 'city', {
            version: 1,
            'country.ref.sd.code[eq]': selectValue.code,
            limit: 300
          });
        }
        return null;
      })
      .then(refDataObj => {
        this.updateFieldProp(
          'city',
          'items',
          refDataObj ? refDataObj.data : []
        );
        this.updateFieldProp('city', 'disabled', !refDataObj);
      });
  };

  @action('MyTeamStore => onCityFieldChange')
  onCityFieldChange = (field, selectValue, formName) => {
    Promise.all([this.onFieldChange(field, selectValue, formName)]).then(() =>
      this.updateFieldProp('region', 'value', selectValue.region)
    );
  };

  @action('MyTeamStore => updateFieldProp')
  updateFieldProp = (field, propName, value) => {
    if (this.forms.teamMemberCreateForm.fields[field]) {
      this.forms.teamMemberCreateForm.fields[field][propName] = value;
    }
    return null;
  };

  _getStaticData = (resource, field, params) => {
    const _params = !params ? {} : params;
    _params.sort = 'code';
    return StaticDataProvider.getStaticDataList(resource, _params).then(
      response => {
        return {
          id: field,
          data: response.data
        };
      }
    );
  };

  createNewMemberPayload = data => {
    const keysToSkip = ['region', 'country', 'sendCheckbox'];
    return Object.keys(data).reduce((acc, key) => {
      if (data[key].value && !keysToSkip.includes(key)) {
        if (key === 'dob') {
          const dateObj = data[key].value;
          acc[key] = moment(dateObj).format('YYYY-MM-DD');
        } else if (key === 'phone') {
          acc[key] = `+${data[key].value}`;
        } else if (key === 'city') {
          const city = this.createSelectOptionPayload(
            'CITY',
            data[key].items,
            data[key].value.code,
            'name'
          );
          city.country = data.country.value.name;
          city.region = data.region.value;
          acc.address = { city };
        } else {
          if (data[key].type && data[key].type === 'select') {
            acc[key] = this.createSelectOptionPayload(
              data[key].res,
              data[key].items,
              data[key].value.code
            );
          } else {
            acc[key] = data[key].value;
          }
        }
      }
      return acc;
    }, {});
  };

  createSelectOptionPayload = (res, opts, value, fieldName) => {
    const data = opts.find(opt => opt.code === value);
    if (!data) {
      return null;
    }
    const name = fieldName ? data[fieldName] : data.description;
    return {
      name,
      ref: {
        sd: {
          res,
          code: value
        }
      }
    };
  };

  @action('MyTeamStore => validateUniqueField')
  validateUniqueField = (field, value, phone) => {
    const fieldName = `${field}[eq]`;
    const params = {
      version: 1
    };
    params[fieldName] = value;
    return VaultProvider.getProfiles(params).then(profilesResponse => {
      if (profilesResponse.data != null && profilesResponse.data.length > 0) {
        if (
          !phone ||
          profilesResponse.data.some(profile => profile.phone !== phone)
        ) {
          const fieldStr = field === 'email' ? 'e-mail' : field;
          this.updateFieldProp(
            field,
            'error',
            `Employee with this ${fieldStr} already invited.`
          );
        }
      } else {
        this.updateFieldProp(field, 'error', null);
      }
      return null;
    });
  };

  @action('MyTeamStore => validateLineManagerExists')
  validateLineManagerExists = value => {
    const params = {
      'lineManager[eq]': value
    };
    return VaultProvider.getProfiles(params).then(profilesResponse => {
      if (profilesResponse.data != null && profilesResponse.data.length > 0) {
        this.updateFieldProp('lineManager', 'error', null);
      } else {
        this.updateFieldProp(
          'lineManager',
          'error',
          `The line manager does not exists.`
        );
      }
      return null;
    });
  };

  @action('MyTeamStore => loadMemberProfile')
  loadMemberProfile = phone => {
    const fields = this.forms.teamMemberCreateForm.fields;
    CommonStore.setPending();
    const params = {
      version: getResourcePayload('EMPLOYEE_PROFILE').audit.version,
      'phone[eq]': phone
    };
    return Promise.all([
      VaultProvider.getProfiles(params),
      this.loadStaticData()
    ])
      .then(responses => {
        const profileResponse = responses[0];
        if (profileResponse.data != null && profileResponse.data.length > 0) {
          const data = profileResponse.data[0];
          if (!data.address) {
            return data;
          }
          const countryName = data.address.city.country;
          const viableCountryOpt =
            fields.country.items != null &&
            fields.country.items.length > 0 &&
            fields.country.items.some(item => item.name === countryName);
          if (viableCountryOpt) {
            const countryVal = fields.country.items.find(
              item => item.name === countryName
            );
            this.updateFieldProp('country', 'name', countryName);
            this.updateFieldProp('country', 'value', countryVal);
            return this._getStaticData('CITY', 'city', {
              version: 1,
              'country.ref.sd.code[eq]': countryVal.code,
              limit: 300
            }).then(cityResponse => {
              this.updateFieldProp('city', 'items', cityResponse.data);
              this.updateFieldProp(
                'city',
                'disabled',
                cityResponse.data.length <= 0
              );
              return data;
            });
          }
          return data;
        }
        return null;
      })
      .then(data => {
        if (data != null) {
          const request = this.setMemberProfile(data);
          return Promise.all(request);
        }
        return null;
      })
      .catch(err => console.log(err))
      .finally(CommonStore.clearPending);
  };

  @action('MyTeamStore => updateTeamMember')
  updateTeamMember = data => {
    const tenantId = getParsedCookies().tenant;
    const formData = this.createNewMemberPayload(data);
    const payload = {
      guid: generateGUID(),
      employeeProfile: formData
    };
    return WalletsProvider.getNode(tenantId).then(nodeResponse => {
      WalletsProvider.getPlatformProperties().then(
        platformPropertiesReponse => {
          delete payload.employeeProfile.send_sms;
          const event = generateEventBody({
            eventCode: 'EMPLOYEE.PROFILE.LOADED',
            payload,
            scAddress: nodeResponse.smart_contract_address,
            tenant: tenantId
          });

          WalletsProvider.sendEvent(
            getSignedEvent(
              event,
              platformPropertiesReponse.event_ledger_address
            )
          );
        }
      );
    });
  };

  setMemberProfile = data => {
    const fields = this.forms.teamMemberCreateForm.fields;

    const address = data.address;
    const region = address ? address.city.region : null;
    const cityName = address ? address.city.name : null;
    const cityCode = address ? address.city.ref.sd.code : null;
    const viableCityCode =
      address &&
      fields.city.items != null &&
      fields.city.items.length > 0 &&
      fields.city.items.some(item => item.code === cityCode);
    return Object.keys(data).map(key => {
      const value = data[key];
      const excludedFields = ['guid', 'id', 'node'];
      const notFormFields = ['address', 'status'];
      if (!excludedFields.includes(key)) {
        const isSelectType =
          !notFormFields.includes(key) &&
          fields[key] &&
          fields[key].type === 'select';
        switch (key) {
          case 'dob':
            this.updateFieldProp(key, 'value', new Date(value));
            break;
          case 'phone':
            this.updateFieldProp(key, 'value', value.substr(1));
            this.updateFieldProp(key, 'disabled', true);
            break;
          case 'address':
            if (address) {
              if (viableCityCode) {
                const cityVal = fields.city.items.find(
                  item => item.code === cityCode
                );
                this.updateFieldProp('city', 'value', cityVal);
              }
              this.updateFieldProp('region', 'value', region);
              this.updateFieldProp('city', 'name', cityName);
            }
            break;
          case 'status':
            this.updateInvitationStatus(data[key]);
            break;
          default:
            if (isSelectType) {
              const name = data[key].name;
              const code = data[key].ref.sd.code;
              const selectVal =
                fields[key].items &&
                fields[key].items.find(item => item.code === code);
              this.updateFieldProp(key, 'name', name);
              this.updateFieldProp(key, 'value', selectVal);
            } else {
              this.updateFieldProp(key, 'value', value);
            }
            break;
        }
      }
      return null;
    });
  };

  @action('MyTeamStore => updateInvitationStatus')
  updateInvitationStatus = status => {
    this.forms.invitationStatus = status;
  };

  @action('MyTeamStore => updateInvitationStatus')
  updateShowResendNotification = status => {
    this.showResendNotification = status;
  };

  @action('MyTeamStore => updateLogNotificationVisibility')
  updateLogNotificationVisibility = isUploadStarted => {
    const latestUploadHistory = this.latestUploadHistory;
    const isStartedStatus =
      latestUploadHistory &&
      latestUploadHistory.status === BULK_UPLOAD_STATUS.STARTED;
    const isFinishedStatus =
      latestUploadHistory &&
      latestUploadHistory.status === BULK_UPLOAD_STATUS.FINISHED;
    const isHasFailure = latestUploadHistory && latestUploadHistory.hasFailure;

    if (isUploadStarted) {
      this.isHiddenLogNotification = undefined;
    }
    if (isStartedStatus) {
      this.isHiddenLogNotification = false;
    }
    if (this.isHiddenLogNotification !== undefined) {
      const isHiddenLogNotification =
        (isFinishedStatus && !isHasFailure) ||
        (this.lastClosedUploadNotification === latestUploadHistory &&
          latestUploadHistory.guid);
      this.isHiddenLogNotification = isHiddenLogNotification;
    }
  };

  @action('MyTeamStore => updateResendNotificationMsg')
  updateResendNotificationMsg = message => {
    this.resendNotificationMsg = message;
  };

  @action('MyTeamStore => sendHealthReminderEvent')
  sendHealthReminderEvent = () => {
    const tenantId = getParsedCookies().tenant;
    const payload = {
      guid: generateGUID()
    };
    return WalletsProvider.getNode(tenantId).then(nodeResponse => {
      WalletsProvider.getPlatformProperties().then(
        platformPropertiesReponse => {
          const event = generateEventBody({
            eventCode: 'REMINDER.FOR.DAILY.CHECKUP',
            scAddress: nodeResponse.smart_contract_address,
            payload,
            tenant: tenantId
          });

          WalletsProvider.sendEvent(
            getSignedEvent(
              event,
              platformPropertiesReponse.event_ledger_address
            )
          );
        }
      );
    });
  };

  @action('HAYFTStore =>reachedLastPage') reachedLastPage = () => {
    this.hasNextPage = false;
  };

  @action('MyTeamStore => sendHAYFTReminderEvent')
  sendHAYFTReminderEvent = () => {
    const tenantId = getParsedCookies().tenant;
    const payload = {
      guid: generateGUID()
    };
    return WalletsProvider.getNode(tenantId).then(nodeResponse => {
      WalletsProvider.getPlatformProperties().then(
        platformPropertiesReponse => {
          const event = generateEventBody({
            eventCode: 'REMINDER.STAY.C.Q.NOT.ANSWERED',
            scAddress: nodeResponse.smart_contract_address,
            payload,
            tenant: tenantId
          });

          WalletsProvider.sendEvent(
            getSignedEvent(
              event,
              platformPropertiesReponse.event_ledger_address
            )
          );
        }
      );
    });
  };

  @action('MyTeamStore => validateData')
  validateData = tableData => {
    tableData.forEach(row => {
      const errorsArray = ValidateRowsInit(
        row,
        toJS(this.countries),
        toJS(this.validRowsInCSV)
      );
      if (errorsArray.length) {
        const newRow = row;
        newRow.errors = errorsArray;
        this.invalidRowsInCSV.push(newRow);
      } else {
        this.validRowsInCSV.push(row);
      }
    });
  };

  @action('MyTeamStore => resetExcelData')
  resetExcelData = () => {
    this.invalidRowsInCSV.replace([]);
    this.validRowsInCSV.replace([]);
  };

  @action('MyTeamStore => setExcelObject')
  setExcelObject = (state, data) => {
    this.excelObject = state;
    this.uploadedFileName = data;
  };

  @action('MyTeamStore => setSendInvitation')
  setSendInvitation = sendInvitaionNow => {
    this.sendInvitationSMS = sendInvitaionNow === 'sendSms';
  };

  @action('MyTeamStore => getCountries')
  getCountries = () => {
    this._getStaticData('COUNTRY', 'country', { limit: 300 }).then(response => {
      const countries = [];
      response.data.forEach(country => countries.push(country.name));
      runInAction(() => {
        this.countries = countries;
      });
    });
  };
}

export default new MyTeamStore();
