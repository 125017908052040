/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

const styles = theme => ({
  title: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '23px',
    letterSpacing: '0.0015em',
    color: '#2F0A6B'
  },
  buttonsWrapper: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6)
  },
  contentWrapper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  label: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    letterSpacing: '0.005em',
    color: '#2F0A6B'
  },
  padding: {
    position: 'relative',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(6),
    '& input': {
      color: '#2F0A6B'
    }
  },
  image: {
    height: '33%',
    width: '33%',
    textAlign: 'center',
    display: 'block',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto'
  },
  inputRoot: {
    '&:before': {
      display: 'none'
    },
    '&:after': {
      display: 'none'
    }
  },
  input: {
    color: '#2F0A6B',
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
    boxShadow: '0 0 0px 1000px #ffffff inset !important',
    border: '1px solid #E9E2F4',
    borderRadius: 8
  },
  focused: {
    '& input': {
      borderColor: '#DCB8FD'
    }
  }
});

export default styles;
