/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';

// Material UI
import { Grid, Stepper, Step, StepLabel, withStyles } from '@material-ui/core';

// Components
import { MainContentWrapper, BlockWrapper, Notification } from '@CommonScene';
import Cards from '@HAYFTScene/components/Cards';
import Members from '@HAYFTScene/components/Members';
import StepIcon from '@HAYFTScene/components/StepIcon';
import { SecondaryButton } from '@CommonScene/materialUIComponents/PrimaryButton';
import { SendHAYFT } from '@Utils/constans/icons';

// Utils
import { compose } from '@Utils';

// Styles
import styles from './styles';

export class HAYFT extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    HAYFTStore: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  state = {
    activeCards: [0],
    activeStep: 0,
    steps: ['Choose a card', 'Choose respondents']
  };

  setActiveCardIndex = card => {
    const _activeCards = [...this.state.activeCards];
    if (_activeCards.includes(card)) {
      const index = _activeCards.indexOf(card);
      _activeCards.splice(index, 1);
    } else {
      _activeCards.push(card);
    }
    this.setState({ activeCards: _activeCards });
  };

  handleNext = () => {
    const {
      HAYFTStore: { alterProfile }
    } = this.props;
    alterProfile([]);
    if (this.state.activeStep !== this.state.steps.length) {
      this.setState({ activeStep: this.state.activeStep + 1 });
    }
    window.scrollTo(0, 0);
  };

  handleBack = () => {
    this.setState({ activeStep: this.state.activeStep - 1 });
  };

  componentDidMount() {
    const {
      HAYFTStore: { getHAYFTCards }
    } = this.props;

    getHAYFTCards();
  }

  getHeaderOptions() {
    return '';
  }

  getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <Cards
            activeCards={this.state.activeCards}
            setActiveCardIndex={this.setActiveCardIndex}
            data={this.props.HAYFTStore.HAYFTCards}
          />
        );
      case 1:
        return <Members />;
      default:
        return 'Unknown stepIndex';
    }
  }

  getButtonOptions = () => {
    const {
      HAYFTStore: { HAYFTCards, setCheckedProfile, sendCard }
    } = this.props;

    const { activeStep, steps, activeCards } = this.state;

    return [
      {
        isDisabled: activeCards.length <= 0,
        isShown: this.state.activeStep < steps.length - 1,
        text: 'Next',
        onClickHandler: this.handleNext
      },
      {
        isShown: activeStep > 0,
        text: 'back',
        onClickHandler: this.handleBack,
        Component: SecondaryButton
      },
      {
        isShown: activeStep >= steps.length - 1,
        text: 'submit',
        isDisabled:
          activeStep >= steps.length - 1 &&
          !setCheckedProfile.filter(profile => profile && profile.checked)
            .length,
        onClickHandler: () => {
          const cards = [...this.state.activeCards];
          const nodeIds = setCheckedProfile
            .filter(profile => profile && profile.checked)
            .map(checkedProfile => checkedProfile.node.nodeId);
          cards.forEach(cardIndex => {
            const card = HAYFTCards[cardIndex];
            sendCard(card, nodeIds).then(() =>
              this.setState({ activeStep: 0, activeCards: [0] })
            );
          });
        }
      }
    ];
  };

  render() {
    const { classes } = this.props;
    const {
      HAYFTStore: { setCheckedProfile, alterProfile }
    } = this.props;

    return (
      <MainContentWrapper
        headerOptions={this.getHeaderOptions()}
        buttonOptions={this.getButtonOptions()}
        buttonCentered
      >
        <Notification
          hasSuccess
          successLabel={`${
            setCheckedProfile.length === 1 ? `Card was` : `Cards were`
          } successfully sent to selected employees`}
          isHidden={this.state.activeStep || !setCheckedProfile.length}
          onClose={() => alterProfile([])}
        />
        <BlockWrapper>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item>
              <SendHAYFT />
            </Grid>
          </Grid>
          <Stepper activeStep={this.state.activeStep} alternativeLabel>
            {this.state.steps.map(label => (
              <Step key={label}>
                <StepLabel
                  classes={{
                    label: classes.stepLabel
                  }}
                  StepIconComponent={StepIcon}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          {this.getStepContent(this.state.activeStep)}
        </BlockWrapper>
      </MainContentWrapper>
    );
  }
}

export default compose(
  withStyles(styles),
  inject('HAYFTStore'),
  observer
)(HAYFT);
