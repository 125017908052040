/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

// Material UI
import { Grid, Typography, IconButton, withStyles } from '@material-ui/core';

// Components
import { BlockWrapper, CustomTooltip } from '@CommonScene';
import FieldWrapper from '@CommonScene/FieldWrapper';
import {
  PrimaryButton,
  SecondaryButton
} from '@CommonScene/materialUIComponents/PrimaryButton';
import AccountDetailsEditForm from '@ProfileManagementScene/scenes/Profile/components/AccountDetailsEditForm';

// Icons
import { AbsentIcon, IconEdit } from '@Utils/constans/icons';

// Utils
import { compose } from '@Utils';
import { useStore } from '@Utils/hooks';

// Styles
import styles from './styles';

const PasswordManagement = ({ classes }) => {
  const [readOnlyState, setReadOnlyState] = useState(true);

  const {
    AuthStore: { getCurrentAdminData, adminData, forms, clearForms },
    AdminsStore: { updateAdminData }
  } = useStore();

  useEffect(() => {
    getCurrentAdminData();
  }, []);

  const handleCancel = () => {
    setReadOnlyState(true);
    clearForms();
  };

  const submitForm = form => {
    updateAdminData(form.fields).then(() => {
      setReadOnlyState(true);
      getCurrentAdminData();
    });
  };

  return (
    <BlockWrapper withBottomMargin className={classes.wrapper}>
      <Typography variant="body1" className={classes.blockHeader}>
        Account Details
        {readOnlyState ? (
          <CustomTooltip key="edit-icon" label="Edit">
            <IconButton
              aria-label="edit"
              component="span"
              onClick={() => setReadOnlyState(false)}
              style={{ backgroundColor: 'transparent' }}
            >
              <IconEdit className={classes.pencil} />
            </IconButton>
          </CustomTooltip>
        ) : null}
      </Typography>
      {readOnlyState ? (
        <>
          <FieldWrapper
            label="First name"
            labelClassName={classes.label}
            childrenClassName={classes.fieldText}
          >
            <Typography variant="body2">
              {adminData.firstName ? adminData.firstName : <AbsentIcon />}
            </Typography>
          </FieldWrapper>
          <FieldWrapper
            label="Middle name"
            labelClassName={classes.label}
            childrenClassName={classes.fieldText}
          >
            <Typography variant="body2">
              {adminData.middleName ? adminData.middleName : <AbsentIcon />}
            </Typography>
          </FieldWrapper>
          <FieldWrapper
            label="Last name"
            labelClassName={classes.label}
            childrenClassName={classes.fieldText}
          >
            <Typography variant="body2">
              {adminData.lastName ? adminData.lastName : <AbsentIcon />}
            </Typography>
          </FieldWrapper>
          <FieldWrapper
            label="E-mail"
            labelClassName={classes.label}
            childrenClassName={classes.fieldText}
          >
            <Typography variant="body2">
              {adminData.email ? adminData.email : <AbsentIcon />}
            </Typography>
          </FieldWrapper>
          <FieldWrapper
            label="Phone"
            labelClassName={classes.label}
            childrenClassName={classes.fieldText}
          >
            <Typography variant="body2">
              {adminData.phone ? adminData.phone : <AbsentIcon />}
            </Typography>
          </FieldWrapper>
        </>
      ) : (
        <AccountDetailsEditForm form={forms.adminEditForm} />
      )}

      {!readOnlyState ? (
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <SecondaryButton onClick={handleCancel}>cancel</SecondaryButton>
          </Grid>
          <Grid item>
            <PrimaryButton
              disabled={!forms.adminEditForm.meta.isValid}
              onClick={() => {
                submitForm(forms.adminEditForm);
              }}
            >
              save
            </PrimaryButton>
          </Grid>
        </Grid>
      ) : null}
    </BlockWrapper>
  );
};

PasswordManagement.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  withStyles(styles),
  observer
)(PasswordManagement);
