/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

// Material UI
import { withStyles, Grid } from '@material-ui/core';

// Components
import HeaderTitle from '@AuthScene/scenes/common/HeaderTitle';
import ErrorSection from '@AuthScene/scenes/common/ErrorSection';
import ResetPasswordButtons from '@AuthScene/scenes/ResetPassword/components/ResetPasswordButtons';
import ResendCode from '@AuthScene/scenes/ResetPassword/components/ResendCode';
import { PhoneNumberField } from '@CommonScene';

// Utils
import { displayPhone } from '@Utils/display';
import { compose } from '@Utils';
import { getPublicPath } from '@Utils/constans/paths';

// Styles
import styles from './styles';

export class ResetPasswordCodeForm extends Component {
  static propTypes = {
    AuthStore: PropTypes.object,
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  componentDidMount() {
    const {
      AuthStore: { clearInputFields }
    } = this.props;

    clearInputFields('resetPasswordCode', ['code']);
  }

  handleResendCode = e => {
    e.preventDefault();

    const {
      AuthStore: { clearErrorMessage, sendPhoneForResetPassword }
    } = this.props;

    clearErrorMessage();
    sendPhoneForResetPassword();
  };

  handleSubmitForm = e => {
    e.preventDefault();

    const {
      AuthStore: { handleChangeForm, verifyPasswordResetCode }
    } = this.props;

    verifyPasswordResetCode().then(() => handleChangeForm(3));
  };

  backHandler = () => {
    const {
      AuthStore: { clearErrorMessage, resetStore },
      history
    } = this.props;

    clearErrorMessage();
    resetStore();
    history.replace(getPublicPath.login());
  };

  onFieldChange = e => {
    const {
      AuthStore: { onFieldChange, clearErrorMessage }
    } = this.props;

    clearErrorMessage();
    onFieldChange(
      e.target.name,
      e.target.value.replace(/\s+/g, '').replace(/_+/g, ''),
      'resetPasswordCode'
    );
  };

  render() {
    const {
      classes,
      AuthStore: {
        errors,
        forms,
        sendPhoneForResetPassword,
        resetPasswordPhone
      },
      t
    } = this.props;

    return (
      <form onSubmit={this.handleSubmitForm} className={classes.wrapper}>
        <HeaderTitle>{t('auth.resetPassword.stage2.heading')}</HeaderTitle>

        <Grid container direction="column" className={classes.fieldsWrapper}>
          <PhoneNumberField
            mask={[/\d/, ' ', /\d/, ' ', /\d/, ' ', /\d/, ' ', /\d/, ' ', /\d/]}
            name="code"
            value={forms.resetPasswordCode.fields.code.value}
            error={forms.resetPasswordCode.fields.code.error}
            onChange={this.onFieldChange}
            placeholderChar="_"
          />
          <ResendCode
            onClick={sendPhoneForResetPassword}
            phone={displayPhone(resetPasswordPhone)}
          />

          <ErrorSection errors={errors} />
        </Grid>

        <ResetPasswordButtons
          backBtnText={t('auth.login.loginButton')}
          nextBtnText={t('common.next')}
          backBtnOnclickHandler={this.backHandler}
          primaryBtnDisabled={!forms.resetPasswordCode.meta.isValid}
        />
      </form>
    );
  }
}

export default compose(
  withStyles(styles),
  withTranslation(),
  inject('AuthStore'),
  withRouter,
  observer
)(ResetPasswordCodeForm);
