/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright Â© 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import { observable, action } from 'mobx';

// Providers
import { ReportingProvider, VaultProvider } from '@Providers';

// Stores
import CommonStore from '@Stores/CommonStore';

// Constans
import { DOCUMENT_FORMATS } from '@Utils/constans/documentFormats';

// Utils
import { formatDate, mapToArray } from '@Utils/formatting';

import moment from 'moment';

class BaseReportingStore {
  @observable chartData = [];

  @observable tableData = [];

  @observable details = [];

  @observable groupData = {
    office: {
      data: [],
      totalCount: 0
    },
    team: {
      data: [],
      totalCount: 0
    }
  };

  @observable pageParams = {};

  @observable dateRange = {
    from: new Date(
      moment()
        .subtract(14, 'days')
        .startOf('day')
        .format()
    ).getTime(),
    to: Date.now()
  };

  @observable dateRange = {
    from: new Date(
      moment
        .utc(
          moment().add(
            moment()
              .parseZone()
              .utcOffset(),
            'minutes'
          )
        )
        .subtract(14, 'days')
        .startOf('day')
        .format()
    ).getTime(),
    to: new Date(
      moment
        .utc(
          moment().add(
            moment()
              .parseZone()
              .utcOffset(),
            'minutes'
          )
        )
        .format()
    ).getTime()
  };

  @observable linkToFile = '';

  @observable offset = 0;

  @observable limit = 20;

  @observable query = '';

  searchFilter = observable.array([], { deep: false });

  type = null;

  constructor(type) {
    this.type = type;
  }

  @action('ReportingStore => changeOffset') changeOffset = () => {
    this.offset = this.offset + this.limit;
  };

  @action('ReportingStore => resetOffset') resetOffset = () => {
    this.offset = 0;
  };

  @action('InvitationStore => _updateSearchFilter')
  _updateSearchFilter = filter => {
    this.searchFilter.replace(filter);
  };

  @action('ReportingStore => setQuery') setQuery = query => {
    this.resetOffset();
    this._updateSearchFilter([]);
    this.query = query;
    this.search();
  };

  @action('ReportingStore => assigning report to store')
  _setReport = (report, shouldRewriteData) => {
    if (shouldRewriteData) {
      this.details = report.details;
    } else {
      this.details.replace([...this.details, ...report.details]);
    }
    this.report = report;
  };

  @action('ReportingStore => set group data')
  _setGroupData = (groupKey, data, totalCount) => {
    this.groupData[groupKey.toLowerCase()].data = data;
    this.groupData[groupKey.toLowerCase()].totalCount = totalCount;
  };

  @action('ReportingStore => get covid chart data')
  getCovidChartData = dateRangeParms => {
    CommonStore.setPending();
    return VaultProvider.findAggregatedCovidResultData({
      ...dateRangeParms,
      'testResult.type': this.type
    })
      .then(response => {
        this.setChartData(
          response.content.map(chartItem => {
            return [formatDate(chartItem.date, 'MM/DD/YYYY'), chartItem.count];
          })
        );
      })
      .finally(CommonStore.clearPending);
  };

  @action('ReportingStore => set chart data')
  setChartData = data => {
    this.chartData = data;
  };

  @action('ReportingStore => update page params')
  updatePageParams = newData => {
    this.pageParams = { ...this.pageParams, ...newData };
  };

  @action('ReportingStore => reset details')
  resetDetails = () => {
    this.details = [];
  };

  @action('ReportingStore => reset report')
  resetReport = () => {
    this.report = { aggregation: [], details: [] };
  };

  @action('ReportingStore => reset date range') resetDateRange = () => {
    this.dateRange = {
      from: new Date(
        moment
          .utc(
            moment().add(
              moment()
                .parseZone()
                .utcOffset(),
              'minutes'
            )
          )
          .subtract(14, 'days')
          .startOf('day')
          .format()
      ).getTime(),
      to: new Date(
        moment
          .utc(
            moment().add(
              moment()
                .parseZone()
                .utcOffset(),
              'minutes'
            )
          )
          .format()
      ).getTime()
    };
  };

  @action('ReportingStore => update PDF link')
  updateFileLink = newLink => {
    this.linkToFile = `${newLink}`;
  };

  @action('ReportingStore => get report pdf content')
  getFileContent = (
    reportId,
    params = this.pageParams,
    reportFormat = 'pdf'
  ) => {
    CommonStore.setPending();
    return ReportingProvider.exportReport(reportId, params, reportFormat)
      .then(response => {
        const file = new Blob([response], {
          type: DOCUMENT_FORMATS[reportFormat]
        });
        this.updateFileLink(URL.createObjectURL(file));
      })
      .finally(CommonStore.clearPending);
  };

  @action('ReportingStore => get covid group data')
  getCovidGroupData = (groupKey, dateRangeParms) => {
    CommonStore.setPending();

    VaultProvider.getCovidTestResultGroupReport(groupKey, {
      ...dateRangeParms,
      'testResult.type': this.type
    })
      .then(({ grouped, totalCount }) => {
        this._setGroupData(
          groupKey,
          mapToArray(grouped, 'label', 'value'),
          totalCount
        );
      })
      .finally(CommonStore.clearPending);
  };
}

export default new BaseReportingStore();
export const COVID19ReportStore = new BaseReportingStore('SELF');
export const COVID19FamilyReportStore = new BaseReportingStore('FAMILY');
