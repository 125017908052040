import React from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { exportAsCSV, exportAsXML, exportAsExcel } from '@Utils/ag-grid';

export default function SimpleMenu({ count, jsonData }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <button className="clear-all-myteam" onClick={handleClick}>
        {`Export (${count})`}
      </button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            exportAsCSV(jsonData);
            handleClose();
          }}
        >
          CSV
        </MenuItem>
        <MenuItem
          onClick={() => {
            exportAsXML(jsonData);
            handleClose();
          }}
        >
          XML
        </MenuItem>
        <MenuItem
          onClick={() => {
            exportAsExcel(jsonData);
            handleClose();
          }}
        >
          XLSX
        </MenuItem>
      </Menu>
    </div>
  );
}

SimpleMenu.propTypes = {
  count: PropTypes.number.isRequired,
  jsonData: PropTypes.object.isRequired
};
