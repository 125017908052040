/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

// Material UI
import { Grid } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';

// Components
import FieldWrapper from '@CommonScene/FieldWrapper';
import {
  TextField,
  AutoCompleteSelect,
  PhoneNumberFieldNew
} from '@CommonScene';
import SendInviteCheckbox from '@ParticipantsManagementScene/scenes/NewParticipant/components/SendinviteCheckbox';

// Utils
import { compose } from '@Utils';
import { useStore } from '@Utils/hooks';

// Styles
import useStyles from './styles';

const MainForm = ({
  form,
  formClass,
  hideInviteCheckbox,
  justify,
  fieldWrapperClass,
  fieldLabelClass,
  fieldValueClass
}) => {
  const classes = useStyles();
  const {
    CommonStore: { pending },
    MyTeamStore: {
      onFieldChange,
      onCountryFieldChange,
      onCityFieldChange,
      setQuery
    }
  } = useStore();
  const handleOnChange = e => {
    const { name, value } = e.target;
    onFieldChange(name, value, 'teamMemberCreateForm');
  };

  const onSelectChange = (name, value) => {
    switch (name) {
      case 'country':
        onCountryFieldChange(name, value, 'teamMemberCreateForm');
        break;
      case 'city':
        onCityFieldChange(name, value, 'teamMemberCreateForm');
        break;
      default:
        onFieldChange(name, value, 'teamMemberCreateForm');
        break;
    }
  };

  const handleDatePhoneChange = (name, value) => {
    onFieldChange(name, value, 'teamMemberCreateForm');
  };

  const _formClass = !formClass ? classes.form : formClass;

  return (
    <Grid
      container
      className={classes.wrapper}
      direction="row"
      justify={justify}
      alignItems="center"
    >
      <Grid item className={_formClass}>
        <FieldWrapper
          label="First name*"
          labelClassName={fieldLabelClass}
          childrenClassName={fieldValueClass}
          className={fieldWrapperClass}
        >
          <TextField
            name="firstName"
            value={form.fields.firstName.value}
            error={form.fields.firstName.error}
            onChange={handleOnChange}
          />
        </FieldWrapper>
        <FieldWrapper
          label="Middle name"
          labelClassName={fieldLabelClass}
          childrenClassName={fieldValueClass}
          className={fieldWrapperClass}
        >
          <TextField
            name="middleName"
            inputProps={{ maxLength: 128 }}
            value={form.fields.middleName.value}
            error={form.fields.middleName.error}
            onChange={handleOnChange}
          />
        </FieldWrapper>
        <FieldWrapper
          label="Last name*"
          labelClassName={fieldLabelClass}
          childrenClassName={fieldValueClass}
          className={fieldWrapperClass}
        >
          <TextField
            name="lastName"
            inputProps={{ maxLength: 128 }}
            value={form.fields.lastName.value}
            error={form.fields.lastName.error}
            onChange={handleOnChange}
          />
        </FieldWrapper>
        <AutoCompleteSelect
          name="title"
          label="Title"
          idField="code"
          labelField="description"
          items={form.fields.title.items}
          value={form.fields.title.value}
          loading={pending}
          onChange={onSelectChange}
          labelClassName={fieldLabelClass}
          childrenClassName={fieldValueClass}
          fieldWrapperClass={fieldWrapperClass}
        />
        <FieldWrapper
          label="Date of birth"
          labelClassName={fieldLabelClass}
          childrenClassName={fieldValueClass}
          className={fieldWrapperClass}
        >
          <DatePicker
            autoOk
            clearable
            variant="inline"
            format="YYYY-MM-DD"
            value={form.fields.dob.value}
            error={form.fields.dob.error}
            TextFieldComponent={TextField}
            disableFuture
            onChange={date => handleDatePhoneChange('dob', date)}
          />
        </FieldWrapper>
        <FieldWrapper
          label="E-mail*"
          labelClassName={fieldLabelClass}
          childrenClassName={fieldValueClass}
          className={fieldWrapperClass}
        >
          <TextField
            name="email"
            value={form.fields.email.value}
            error={form.fields.email.error}
            onChange={handleOnChange}
          />
        </FieldWrapper>
        <PhoneNumberFieldNew
          label="Phone*"
          name="phone"
          onChange={value => handleDatePhoneChange('phone', value)}
          value={form.fields.phone.value}
          error={form.fields.phone.error}
          disabled={form.fields.phone.disabled}
          labelClassName={fieldLabelClass}
          childrenClassName={fieldValueClass}
          fieldWrapperClass={fieldWrapperClass}
        />
        <FieldWrapper
          label="Employee ID"
          labelClassName={fieldLabelClass}
          childrenClassName={fieldValueClass}
          className={fieldWrapperClass}
        >
          <TextField
            name="employeeId"
            value={form.fields.employeeId.value}
            error={form.fields.employeeId.error}
            onChange={handleOnChange}
          />
        </FieldWrapper>
        <AutoCompleteSelect
          name="country"
          label="Country"
          idField="code"
          labelField="name"
          items={form.fields.country.items}
          value={form.fields.country.value}
          loading={pending}
          onChange={onSelectChange}
          labelClassName={fieldLabelClass}
          childrenClassName={fieldValueClass}
          fieldWrapperClass={fieldWrapperClass}
        />
        <FieldWrapper
          label="Region/Area"
          labelClassName={fieldLabelClass}
          childrenClassName={fieldValueClass}
          className={fieldWrapperClass}
        >
          <TextField
            disabled
            name="region"
            value={form.fields.region.value}
            error={form.fields.region.error}
          />
        </FieldWrapper>
        <AutoCompleteSelect
          name="city"
          label="City"
          idField="code"
          labelField="name"
          items={form.fields.city.items}
          value={form.fields.city.value}
          loading={pending}
          disabled={form.fields.city.disabled}
          onChange={onSelectChange}
          onInputChange={setQuery}
          labelClassName={fieldLabelClass}
          childrenClassName={fieldValueClass}
          fieldWrapperClass={fieldWrapperClass}
        />
        <FieldWrapper
          label="Office"
          labelClassName={fieldLabelClass}
          childrenClassName={fieldValueClass}
          className={fieldWrapperClass}
        >
          <TextField
            name="officeAddress"
            value={form.fields.officeAddress.value}
            error={form.fields.officeAddress.error}
            onChange={handleOnChange}
          />
        </FieldWrapper>
        <AutoCompleteSelect
          name="jobTitle"
          label="Job title"
          idField="code"
          labelField="description"
          items={form.fields.jobTitle.items}
          value={form.fields.jobTitle.value}
          loading={pending}
          onChange={onSelectChange}
          labelClassName={fieldLabelClass}
          childrenClassName={fieldValueClass}
          fieldWrapperClass={fieldWrapperClass}
        />
        <FieldWrapper
          label="Team/Dept."
          labelClassName={fieldLabelClass}
          childrenClassName={fieldValueClass}
          className={fieldWrapperClass}
        >
          <TextField
            name="teamName"
            value={form.fields.teamName.value}
            error={form.fields.teamName.error}
            onChange={handleOnChange}
          />
        </FieldWrapper>
        <FieldWrapper
          label="Line manager"
          labelClassName={fieldLabelClass}
          childrenClassName={fieldValueClass}
          className={fieldWrapperClass}
        >
          <TextField
            name="lineManager"
            value={form.fields.lineManager.value}
            error={form.fields.lineManager.error}
            onChange={handleOnChange}
          />
        </FieldWrapper>
        <AutoCompleteSelect
          name="gender"
          label="Gender"
          idField="code"
          labelField="description"
          items={form.fields.gender.items}
          value={form.fields.gender.value}
          loading={pending}
          onChange={onSelectChange}
          labelClassName={fieldLabelClass}
          childrenClassName={fieldValueClass}
          fieldWrapperClass={fieldWrapperClass}
        />
        <AutoCompleteSelect
          name="jobGrade"
          label="Job grade"
          idField="code"
          labelField="description"
          items={form.fields.jobGrade.items}
          value={form.fields.jobGrade.value}
          onChange={onSelectChange}
          labelClassName={fieldLabelClass}
          childrenClassName={fieldValueClass}
          fieldWrapperClass={fieldWrapperClass}
        />
        <AutoCompleteSelect
          name="jobStatus"
          label="Job status"
          idField="code"
          labelField="description"
          items={form.fields.jobStatus.items}
          value={form.fields.jobStatus.value}
          loading={pending}
          onChange={onSelectChange}
          labelClassName={fieldLabelClass}
          childrenClassName={fieldValueClass}
          fieldWrapperClass={fieldWrapperClass}
        />
        <AutoCompleteSelect
          name="employmentCategory"
          label="Employment category"
          idField="code"
          labelField="description"
          items={form.fields.employmentCategory.items}
          value={form.fields.employmentCategory.value}
          loading={pending}
          onChange={onSelectChange}
          labelClassName={fieldLabelClass}
          childrenClassName={fieldValueClass}
          fieldWrapperClass={fieldWrapperClass}
        />
        {!hideInviteCheckbox ? (
          <SendInviteCheckbox
            checked={form.fields.sendCheckbox.value}
            onChange={form.fields.sendCheckbox.onChange}
          />
        ) : null}
      </Grid>
    </Grid>
  );
};

MainForm.propTypes = {
  form: PropTypes.object,
  formClass: PropTypes.object,
  hideInviteCheckbox: PropTypes.bool,
  justify: PropTypes.string,
  fieldWrapperClass: PropTypes.string,
  fieldLabelClass: PropTypes.string,
  fieldValueClass: PropTypes.string
};

MainForm.defaultProps = {
  hideInviteCheckbox: false,
  justify: 'center'
};

export default compose(observer)(MainForm);
