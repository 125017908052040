/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

// Material UI
import { Grid } from '@material-ui/core';

// Components
import FieldWrapper from '@CommonScene/FieldWrapper';
import { TextField, PhoneNumberFieldNew } from '@CommonScene';
import SendInviteCheckbox from '@ParticipantsManagementScene/scenes/NewParticipant/components/SendinviteCheckbox';
import { PrimaryButton } from '@CommonScene/materialUIComponents/PrimaryButton';

// Utils
import { compose } from '@Utils';
import { useStore } from '@Utils/hooks';

// Styles
import useStyles from './styles';

const Form = ({
  form,
  handleSubmit,
  formClass,
  justify,
  fieldWrapperClass,
  fieldLabelClass,
  fieldValueClass
}) => {
  const classes = useStyles();
  const {
    AdminsStore: { onFieldChange }
  } = useStore();
  const handleOnChange = e => {
    const { name, value } = e.target;
    onFieldChange(name, value, 'newAdminForm');
  };

  const handleDatePhoneChange = (name, value) => {
    onFieldChange(name, value, 'newAdminForm');
  };

  const _formClass = !formClass ? classes.form : formClass;

  return (
    <>
      <Grid
        container
        className={classes.wrapper}
        direction="row"
        justify={justify}
        alignItems="center"
      >
        <Grid item className={_formClass}>
          <FieldWrapper
            label="First name*"
            labelClassName={fieldLabelClass}
            childrenClassName={fieldValueClass}
            className={fieldWrapperClass}
          >
            <TextField
              name="firstName"
              value={form.fields.firstName.value}
              error={form.fields.firstName.error}
              onChange={handleOnChange}
            />
          </FieldWrapper>
          <FieldWrapper
            label="Middle name"
            labelClassName={fieldLabelClass}
            childrenClassName={fieldValueClass}
            className={fieldWrapperClass}
          >
            <TextField
              name="middleName"
              inputProps={{ maxLength: 128 }}
              value={form.fields.middleName.value}
              error={form.fields.middleName.error}
              onChange={handleOnChange}
              placeholder="E.g. Van"
            />
          </FieldWrapper>
          <FieldWrapper
            label="Last name*"
            labelClassName={fieldLabelClass}
            childrenClassName={fieldValueClass}
            className={fieldWrapperClass}
          >
            <TextField
              name="lastName"
              inputProps={{ maxLength: 128 }}
              value={form.fields.lastName.value}
              error={form.fields.lastName.error}
              onChange={handleOnChange}
            />
          </FieldWrapper>
          <FieldWrapper
            label="E-mail*"
            labelClassName={fieldLabelClass}
            childrenClassName={fieldValueClass}
            className={fieldWrapperClass}
          >
            <TextField
              name="email"
              value={form.fields.email.value}
              error={form.fields.email.error}
              onChange={handleOnChange}
              placeholder="e-mail@address.com"
            />
          </FieldWrapper>
          <PhoneNumberFieldNew
            label="Phone*"
            name="phone"
            onChange={value => handleDatePhoneChange('phone', value)}
            value={form.fields.phone.value}
            error={form.fields.phone.error}
            disabled={form.fields.phone.disabled}
            labelClassName={fieldLabelClass}
            childrenClassName={fieldValueClass}
            fieldWrapperClass={fieldWrapperClass}
          />

          <SendInviteCheckbox
            checked={form.fields.sendCheckbox.value}
            onChange={form.fields.sendCheckbox.onChange}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item>
          <PrimaryButton disabled={!form.meta.isValid} onClick={handleSubmit}>
            submit
          </PrimaryButton>
        </Grid>
      </Grid>
    </>
  );
};

Form.propTypes = {
  form: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  formClass: PropTypes.object,
  hideInviteCheckbox: PropTypes.bool,
  justify: PropTypes.string,
  fieldWrapperClass: PropTypes.string,
  fieldLabelClass: PropTypes.string,
  fieldValueClass: PropTypes.string
};

Form.defaultProps = {
  hideInviteCheckbox: false,
  justify: 'center'
};

export default compose(observer)(Form);
