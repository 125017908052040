// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Styles
import './index.css';

class BulkUploadRowDetails extends Component {
  render() {
    const {
      successRowCount,
      errorRowCount,
      onInviteClick,
      setGridDataType,
      disableInvite,
      sendInvitationSMS
    } = this.props;
    return (
      <>
        <div className="recordsBtn">
          {successRowCount + errorRowCount} Records
        </div>
        <button
          className={`successBtn${disableInvite ? '' : ' active'}`}
          onClick={() => setGridDataType('success')}
        >
          <span>&nbsp;</span>
          {successRowCount} Success
        </button>
        <button
          className={`errorBtn${disableInvite ? ' active' : ''}`}
          onClick={() => setGridDataType('error')}
        >
          <span>!</span>
          {errorRowCount} Error
        </button>
        <button
          onClick={onInviteClick}
          className={`inviteBtn ${
            disableInvite || successRowCount === 0 ? 'disabledInvite' : ''
          }`}
          disabled={disableInvite || successRowCount === 0}
        >
          {`${sendInvitationSMS ? 'Upload and Invite' : 'Upload'}`}
        </button>
      </>
    );
  }
}

BulkUploadRowDetails.propTypes = {
  totalRowsCount: PropTypes.array.isRequired,
  errorRowCount: PropTypes.array.isRequired,
  successRowCount: PropTypes.array.isRequired,
  onInviteClick: PropTypes.func.isRequired,
  setGridDataType: PropTypes.func.isRequired,
  disableInvite: PropTypes.any,
  sendInvitationSMS: PropTypes.any
};
export default BulkUploadRowDetails;
