/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';

// Material UI
import { Grid, Typography, withStyles, IconButton } from '@material-ui/core';

// Components
import { PasswordField, BlockWrapper, CustomTooltip } from '@CommonScene';
import PasswordFields from '@AuthScene/scenes/common/PasswordFields';
import ErrorSection from '@AuthScene/scenes/common/ErrorSection';
import {
  PrimaryButton,
  SecondaryButton
} from '@CommonScene/materialUIComponents/PrimaryButton';
import { IconEdit } from '@Utils/constans/icons';

// Utils
import { compose } from '@Utils';
import { useStore } from '@Utils/hooks';
import {
  normalizePassword,
  convertToSha256,
  // encrypt,
  decrypt,
  encrypt
  // getBlockchainAddress
} from '@Utils/cryptographic';

// Styles
import styles from './styles';

const PasswordManagement = ({ classes }) => {
  const { t } = useTranslation();
  const [passwordFormVisible, setPasswordFormVisible] = useState(false);

  const {
    AuthStore: {
      forms,
      onFieldChange,
      errors,
      clearErrorMessage,
      updatePassword,
      updateContainer,
      clearInputFields,
      getContainer,
      recoveryQuestionsIds
    },
    CommonStore: { isBtnDisabled }
  } = useStore();

  const clearFields = () => {
    clearInputFields('updatePassword', [
      'oldPassword',
      'password',
      'passwordConfirm'
    ]);
    clearErrorMessage();
  };

  const handleOpenPasswordChange = () => {
    setPasswordFormVisible(!passwordFormVisible);
    clearFields();
  };

  const handleClose = () => {
    handleOpenPasswordChange();
    clearFields();
  };

  const handleOldPasswordFieldChange = event => {
    clearErrorMessage();
    onFieldChange(event.target.name, event.target.value, 'updatePassword');
  };

  const handleSubmitForm = () => {
    updatePassword(forms.updatePassword.fields.oldPassword.value).then(() => {
      getContainer().then(containerData => {
        const decrypted = decrypt(
          containerData.container,
          normalizePassword(
            convertToSha256(forms.updatePassword.fields.oldPassword.value)
          )
        );
        const updatedContainer = encrypt(
          decrypted,
          normalizePassword(
            convertToSha256(forms.updatePassword.fields.password.value)
          )
        );

        const payload = {
          app_version: 'default_app_version',
          challenges: toJS(recoveryQuestionsIds),
          enc_version: 'default_enc_version',
          container: updatedContainer,
          type: 'DEFAULT'
        };

        updateContainer(payload).then(data => {
          const PK = decrypt(
            data.container,
            normalizePassword(
              convertToSha256(forms.updatePassword.fields.password.value)
            )
          );
          window.localStorage.setItem('PK', PK);
          handleClose();
        });
      });
    });
  };

  return (
    <BlockWrapper withBottomMargin className={classes.wrapper}>
      <Grid container direction="column">
        <Typography variant="body1" className={classes.blockHeader}>
          {'Password Management'}
          {!passwordFormVisible && (
            <CustomTooltip key="edit-icon" label="Edit">
              <IconButton
                aria-label="edit"
                component="span"
                onClick={handleOpenPasswordChange}
                style={{
                  backgroundColor: 'transparent',
                  padding: '0 12px'
                }}
              >
                <IconEdit className={classes.pencil} />
              </IconButton>
            </CustomTooltip>
          )}
        </Typography>
      </Grid>

      <Grid container item direction="row" spacing={6} xs={12}>
        {passwordFormVisible ? (
          <>
            <Grid item xs={8}>
              <PasswordField
                id="password-input-old"
                name="oldPassword"
                label={'Old'}
                error={errors}
                inputProps={{ maxLength: 40 }}
                value={forms.updatePassword.fields.oldPassword.value}
                onChange={handleOldPasswordFieldChange}
              />

              {errors ? <ErrorSection errors={errors} textOnly /> : ''}

              <PasswordFields
                passwordValue={forms.updatePassword.fields.password.value}
                confirmValue={forms.updatePassword.fields.passwordConfirm.value}
                errors={errors}
                passwordLabel={'New'}
                confirmLabel={'Confirm'}
                onFieldChange={onFieldChange}
                formName="updatePassword"
              />
            </Grid>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
              className={classes.btnWrapper}
            >
              <SecondaryButton
                className={classes.cancelBtn}
                onClick={handleClose}
              >
                {t('common.cancel')}
              </SecondaryButton>
              <PrimaryButton
                onClick={handleSubmitForm}
                color="primary"
                id="change-password-btn"
                className={classes.button}
                disabled={!forms.updatePassword.meta.isValid || isBtnDisabled}
              >
                Save
              </PrimaryButton>
            </Grid>
          </>
        ) : (
          <>
            <Grid item style={{ paddingRight: 16 }}>
              <Typography variant="body1" className={classes.label}>
                {'Password'}
              </Typography>
            </Grid>
            <Grid item style={{ padding: '24px 0' }}>
              <Typography variant="body1" className={classes.placeholder}>
                ••••••••••••
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    </BlockWrapper>
  );
};

PasswordManagement.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  withStyles(styles),
  observer
)(PasswordManagement);
