/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';

// Material UI
import { Grid, withStyles } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

// Components
import MainForm from '@MyTeamScene/scenes/NewTeamMember/components/MainForm';
import { PrimaryButton } from '@CommonScene/materialUIComponents/PrimaryButton';

// Utils
import { compose } from '@Utils';

// Styles
import styles from './styles';

export class NewTeamMember extends Component {
  state = {
    snackBar: {
      show: false
    }
  };

  static propTypes = {
    classes: PropTypes.object.isRequired,
    MyTeamStore: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  componentWillUnmount() {
    const {
      MyTeamStore: { clearForms }
    } = this.props;
    clearForms();
  }

  componentDidMount() {
    const {
      MyTeamStore: { loadStaticData }
    } = this.props;
    loadStaticData();
  }

  validateForm = form => {
    const {
      MyTeamStore: {
        validateUniqueField,
        validateFields,
        validateLineManagerExists,
        createNewMember
      },
      history
    } = this.props;
    const validateLineManagerReq = form.fields.lineManager.value
      ? validateLineManagerExists(form.fields.lineManager.value)
      : null;
    Promise.all([validateFields])
      .then(() =>
        Promise.all([
          validateUniqueField('email', form.fields.email.value),
          validateUniqueField(
            'phone',
            `+${form.fields.phone.value}`,
            validateLineManagerExists
          ),
          validateLineManagerReq
        ])
      )
      .then(() => {
        if (!Object.values(form.fields).some(field => field.error != null)) {
          createNewMember(form.fields).then(() => {
            history.push('/my-team');
          });
        } else {
          const snackBar = { ...this.state.snackBar };
          snackBar.show = true;
          snackBar.message =
            'Validation failed. Please complete the form without validation errors';
          snackBar.severity = 'error';
          this.setState({ snackBar });
        }
      });
  };

  handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    const snackBar = { ...this.state.snackBar };
    snackBar.show = false;
    this.setState({ snackBar });
  };

  render() {
    const {
      MyTeamStore: { forms }
    } = this.props;
    const { snackBar } = this.state;
    return (
      <>
        <MainForm form={forms.teamMemberCreateForm} />
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item>
            <PrimaryButton
              disabled={!forms.teamMemberCreateForm.meta.isValid}
              onClick={() => {
                this.validateForm(forms.teamMemberCreateForm);
              }}
            >
              submit
            </PrimaryButton>
          </Grid>
        </Grid>
        <Snackbar
          open={snackBar.show}
          autoHideDuration={3000}
          onClose={this.handleCloseSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert
            onClose={this.handleCloseSnackbar}
            severity={snackBar.severity}
          >
            {snackBar.message}
          </Alert>
        </Snackbar>
      </>
    );
  }
}

export default compose(
  inject('MyTeamStore'),
  withStyles(styles),
  withRouter,
  observer
)(NewTeamMember);
