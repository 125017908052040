/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { observer } from 'mobx-react';

// Utils
import classNames from 'classnames';
import { compose } from '@Utils';

// Components
import { SendIcon } from '@Utils/constans/icons';
import { CustomTooltip } from '@CommonScene';

// Material- ui
import IconButton from '@material-ui/core/IconButton';
import ResendIcon from '@material-ui/icons/Refresh';

// styles
import useStyles from './styles';

const IconActions = ({
  enableSend,
  enableResend,
  handleResendCode,
  handleSendCode
}) => {
  const classes = useStyles();
  return (
    <div className={classNames(classes.actionIconWrapper)}>
      <CustomTooltip key={shortid.generate()} label={'Send Invitation'}>
        <span>
          <IconButton
            disabled={!enableSend}
            color="primary"
            onClick={() => handleSendCode()}
            className={classNames(enableSend ? classes.active : null)}
          >
            <SendIcon />
          </IconButton>
        </span>
      </CustomTooltip>
      <CustomTooltip key={shortid.generate()} label={'Resend Invitation'}>
        <span>
          <IconButton
            disabled={!enableResend}
            color="primary"
            onClick={() => handleResendCode()}
            className={classNames(enableResend ? classes.active : null)}
          >
            <ResendIcon />
          </IconButton>
        </span>
      </CustomTooltip>
    </div>
  );
};

IconActions.propTypes = {
  enableSend: PropTypes.bool,
  enableResend: PropTypes.bool,
  handleResendCode: PropTypes.func.isRequired,
  handleSendCode: PropTypes.func.isRequired
};

export default compose(observer)(IconActions);
