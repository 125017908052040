/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import i18n from 'i18next';

// Utils
import {
  ParticipantsManagement,
  StatisticsIcon,
  HAYFTIcon
} from '@Utils/constans/icons';

// Alias Routes
import NoAdminRights from '@CommonScene/noAdminRights';
import NotFoundPage from '@CommonScene/notFoundPage';

// MyTeamRoutes
import MyTeamList from '@MyTeamScene/scenes/MyTeamList/container';
import NewTeamMember from '@MyTeamScene/scenes/NewTeamMember/container';
import TeamMemberProfile from '@MyTeamScene/scenes/TeamMemberProfile/container';
import BulkDataDetails from '@MyTeamScene/scenes/BulkDataDetails/container';

// DashboardsRoutes
import DashboardsComponent from '@DashboardsScene';
import MyTeamActivity from '@MyTeamActivityScene/TeamActivityList/container';
import MyTeamActivityDetails from '@MyTeamActivityScene/TeamActivityDetails/container';

// CompanySettingsRoutes
import CompanySettings from '@CompanySettingsScene/container';

// AdminsRoutes
import AdminsList from '@AdminsScene/scenes/AdminsList';
import NewAdmin from '@AdminsScene/scenes/NewAdmin/container';
import AdminDetails from '@AdminsScene/scenes/AdminDetails/container';

// HAYFTRoutes
import HAYFT from '@HAYFTScene/container';

// ParticipantsManagement
import ParticipantsList from '@ParticipantsManagementScene/scenes/ParticipantsList/container';
import NewParticipant from '@ParticipantsManagementScene/scenes/NewParticipant/container';
import ParticipantDetails from '@ParticipantsManagementScene/scenes/ParticipantDetails/container';

// ProfileManagement
import ProfileManagement from '@ProfileManagementScene/scenes/Profile/container';

// Utils
// import { getMenuItems } from '@Utils/formatting';
import {
  INVITATIONS,
  CANCEL_INVITATIONS,
  CREATE_INVITATIONS,
  RESEND_INVITATIONS,
  VIEW_INVITATIONS
} from './permissions';

// Used for Breadcrumbs and Main menu
const participantsManagementSection = 'Participants';
const myTeamSection = 'My Team';
const dashboardsSection = 'Dashboards';
const HAYFTSection = 'HAYFT';

// TODO: del after implementing new roles
const WM_ADMIN = 'SYSTEM_DECK';
export const PAYMENT_ADMIN = 'PAYMENT_ADMIN_DECK';
export const CAN_SPONSOR = 'CAN_SPONSOR_DECK';
export const CAN_STATISTICS = 'REPORTING_ADMIN';

const ParticipantsManagementRoutes = [
  {
    path: '/participants',
    name: 'participants',
    title: i18n.t('mwAdmin.sidebar.participants'),
    backLink: i18n.t('routes.backToParticipantList'),
    section: participantsManagementSection,
    exact: true,
    icon: '',
    menu: true,
    component: ParticipantsList,
    roles: [
      WM_ADMIN,
      INVITATIONS,
      CANCEL_INVITATIONS,
      CREATE_INVITATIONS,
      RESEND_INVITATIONS,
      VIEW_INVITATIONS
    ]
  },
  {
    path: '/participants/new',
    name: 'participants_new',
    title: i18n.t('mwAdmin.invitations.newParticipant'),
    parent: 'participants',
    section: participantsManagementSection,
    exact: true,
    icon: '',
    component: NewParticipant,
    roles: [
      WM_ADMIN,
      INVITATIONS,
      CANCEL_INVITATIONS,
      CREATE_INVITATIONS,
      RESEND_INVITATIONS
    ]
  },
  {
    path: '/participants/:id/view',
    name: 'participants_view',
    title: i18n.t('mwAdmin.invitations.participantDetails'),
    parent: 'participants',
    section: participantsManagementSection,
    exact: true,
    icon: '',
    component: ParticipantDetails,
    roles: [
      WM_ADMIN,
      INVITATIONS,
      CANCEL_INVITATIONS,
      CREATE_INVITATIONS,
      RESEND_INVITATIONS
    ]
  }
];

const MyTeamRoutes = [
  {
    path: '/my-team',
    name: 'my_team',
    backLink: 'Back to My Team list',
    title: 'My Team',
    section: myTeamSection,
    exact: true,
    component: MyTeamList,
    roles: [
      WM_ADMIN,
      INVITATIONS,
      CANCEL_INVITATIONS,
      CREATE_INVITATIONS,
      RESEND_INVITATIONS,
      VIEW_INVITATIONS
    ]
  },
  {
    path: '/my-team/team-member/:phone',
    name: 'team_member',
    parent: 'my_team',
    title: 'Team Member Profile',
    backLink: i18n.t('routes.backToParticipantList'),
    section: myTeamSection,
    exact: true,
    component: TeamMemberProfile,
    roles: [
      WM_ADMIN,
      INVITATIONS,
      CANCEL_INVITATIONS,
      CREATE_INVITATIONS,
      RESEND_INVITATIONS,
      VIEW_INVITATIONS
    ]
  },
  {
    path: '/my-team/new-team-member/individual',
    name: 'new_team_member_individual',
    parent: 'my_team',
    title: 'My Team',
    backLink: i18n.t('routes.backToParticipantList'),
    section: myTeamSection,
    exact: true,
    component: NewTeamMember,
    roles: [
      WM_ADMIN,
      INVITATIONS,
      CANCEL_INVITATIONS,
      CREATE_INVITATIONS,
      RESEND_INVITATIONS,
      VIEW_INVITATIONS
    ]
  },
  {
    path: '/my-team/new-team-member/bulk',
    name: 'new_team_member_bulk',
    parent: 'my_team',
    title: 'My Team',
    backLink: i18n.t('routes.backToParticipantList'),
    section: myTeamSection,
    exact: true,
    component: NewTeamMember,
    roles: [
      WM_ADMIN,
      INVITATIONS,
      CANCEL_INVITATIONS,
      CREATE_INVITATIONS,
      RESEND_INVITATIONS,
      VIEW_INVITATIONS
    ]
  },
  {
    path: '/my-team/new-team-member/bulk-details',
    name: 'new_team_member_bulk_details',
    parent: 'my_team',
    title: 'My Team',
    backLink: i18n.t('routes.backToParticipantList'),
    section: myTeamSection,
    exact: true,
    component: BulkDataDetails,
    roles: [
      WM_ADMIN,
      INVITATIONS,
      CANCEL_INVITATIONS,
      CREATE_INVITATIONS,
      RESEND_INVITATIONS,
      VIEW_INVITATIONS
    ]
  },
  {
    path: '/my-team/import-log',
    name: 'my_team_import_log',
    title: 'My Team',
    section: myTeamSection,
    exact: true,
    component: MyTeamList,
    roles: [
      WM_ADMIN,
      INVITATIONS,
      CANCEL_INVITATIONS,
      CREATE_INVITATIONS,
      RESEND_INVITATIONS,
      VIEW_INVITATIONS
    ]
  }
];

const HAYFTRoutes = [
  {
    path: '/hayft-cards',
    name: '/hayft_cards',
    title: 'HAYFT',
    section: HAYFTSection,
    exact: true,
    component: HAYFT,
    roles: [
      WM_ADMIN,
      INVITATIONS,
      CANCEL_INVITATIONS,
      CREATE_INVITATIONS,
      RESEND_INVITATIONS,
      VIEW_INVITATIONS
    ]
  }
];

const Dashboards = [
  {
    path: '/dashboards',
    name: 'my_team_activity',
    title: 'Dashboards',
    section: dashboardsSection,
    exact: true,
    component: DashboardsComponent,
    roles: [
      WM_ADMIN,
      INVITATIONS,
      CANCEL_INVITATIONS,
      CREATE_INVITATIONS,
      RESEND_INVITATIONS,
      VIEW_INVITATIONS
    ]
  },
  {
    path: '/dashboards/reported-trips',
    name: 'dashboard_reported_trips',
    title: 'Dashboards',
    section: dashboardsSection,
    exact: true,
    component: DashboardsComponent,
    roles: [
      WM_ADMIN,
      INVITATIONS,
      CANCEL_INVITATIONS,
      CREATE_INVITATIONS,
      RESEND_INVITATIONS,
      VIEW_INVITATIONS
    ]
  },
  {
    path: '/dashboards/covid19-family',
    name: 'my_team_activity',
    title: 'Dashboards',
    section: dashboardsSection,
    exact: true,
    component: DashboardsComponent,
    roles: [
      WM_ADMIN,
      INVITATIONS,
      CANCEL_INVITATIONS,
      CREATE_INVITATIONS,
      RESEND_INVITATIONS,
      VIEW_INVITATIONS
    ]
  },
  {
    path: '/dashboards/covid19-risk-status',
    name: 'my_team_activity',
    title: 'Dashboards',
    section: dashboardsSection,
    exact: true,
    component: DashboardsComponent,
    roles: [
      WM_ADMIN,
      INVITATIONS,
      CANCEL_INVITATIONS,
      CREATE_INVITATIONS,
      RESEND_INVITATIONS,
      VIEW_INVITATIONS
    ]
  },
  {
    path: '/dashboards/technical-challenges',
    name: 'my_team_activity',
    title: 'Dashboards',
    section: dashboardsSection,
    exact: true,
    component: DashboardsComponent,
    roles: [
      WM_ADMIN,
      INVITATIONS,
      CANCEL_INVITATIONS,
      CREATE_INVITATIONS,
      RESEND_INVITATIONS,
      VIEW_INVITATIONS
    ]
  },
  {
    path: '/dashboards/travel-risk',
    name: 'my_team_activity',
    title: 'Dashboards',
    section: dashboardsSection,
    exact: true,
    component: DashboardsComponent,
    roles: [
      WM_ADMIN,
      INVITATIONS,
      CANCEL_INVITATIONS,
      CREATE_INVITATIONS,
      RESEND_INVITATIONS,
      VIEW_INVITATIONS
    ]
  },
  {
    path: '/dashboards/employees-wellbeing',
    name: 'my_team_activity',
    title: 'Dashboards',
    section: dashboardsSection,
    exact: true,
    component: DashboardsComponent,
    roles: [
      WM_ADMIN,
      INVITATIONS,
      CANCEL_INVITATIONS,
      CREATE_INVITATIONS,
      RESEND_INVITATIONS,
      VIEW_INVITATIONS
    ]
  },
  {
    path: '/team-activity',
    name: 'my_team_activity',
    title: 'Team Activity',
    section: dashboardsSection,
    exact: true,
    component: MyTeamActivity,
    roles: [
      WM_ADMIN,
      INVITATIONS,
      CANCEL_INVITATIONS,
      CREATE_INVITATIONS,
      RESEND_INVITATIONS,
      VIEW_INVITATIONS
    ]
  },
  {
    path: '/team-activity/:id',
    parent: 'my_team_activity',
    name: 'my_team_activity_details',
    title: 'Team Member Activity Details',
    section: dashboardsSection,
    exact: true,
    component: MyTeamActivityDetails,
    roles: [
      WM_ADMIN,
      INVITATIONS,
      CANCEL_INVITATIONS,
      CREATE_INVITATIONS,
      RESEND_INVITATIONS,
      VIEW_INVITATIONS
    ]
  }
];

const CompanySettingsRoute = [
  {
    path: '/company-settings',
    name: 'company_settings',
    title: 'Company Settings',
    exact: true,
    component: CompanySettings,
    roles: [
      WM_ADMIN,
      INVITATIONS,
      CANCEL_INVITATIONS,
      CREATE_INVITATIONS,
      RESEND_INVITATIONS,
      VIEW_INVITATIONS
    ]
  }
];

const ProfileManagementRoutes = [
  {
    path: '/profile',
    name: 'profile',
    exact: true,
    title: 'Profile',
    parent: 'my_team',
    component: ProfileManagement,
    roles: [
      WM_ADMIN,
      INVITATIONS,
      CANCEL_INVITATIONS,
      CREATE_INVITATIONS,
      RESEND_INVITATIONS,
      VIEW_INVITATIONS
    ]
  }
];

const AdminsRoutes = [
  {
    path: '/admins',
    backLink: 'Back to Admins list',
    name: 'admins',
    parent: 'my_team',
    title: 'Admins',
    exact: true,
    component: AdminsList,
    roles: [
      WM_ADMIN,
      INVITATIONS,
      CANCEL_INVITATIONS,
      CREATE_INVITATIONS,
      RESEND_INVITATIONS,
      VIEW_INVITATIONS
    ]
  },
  {
    path: '/admins/new',
    name: 'new_admin',
    title: 'New Admin',
    parent: 'admins',
    exact: true,
    component: NewAdmin,
    roles: [
      WM_ADMIN,
      INVITATIONS,
      CANCEL_INVITATIONS,
      CREATE_INVITATIONS,
      RESEND_INVITATIONS,
      VIEW_INVITATIONS
    ]
  },
  {
    path: '/admins/:phone',
    name: 'admin_details',
    title: 'Admin Details',
    parent: 'admins',
    exact: true,
    component: AdminDetails,
    roles: [
      WM_ADMIN,
      INVITATIONS,
      CANCEL_INVITATIONS,
      CREATE_INVITATIONS,
      RESEND_INVITATIONS,
      VIEW_INVITATIONS
    ]
  }
];

const AliasRoutes = [
  {
    path: '/',
    exact: true,
    redirectURL: '/my-team'
  },
  {
    path: '/not-found',
    name: 'not_found',
    exact: true,
    title: 'Page not found',
    component: NotFoundPage
  },
  {
    path: '/no-admin-rights',
    exact: true,
    title: 'No Admin Rights',
    component: NoAdminRights
  }
];

export default [
  ...AliasRoutes,
  ...ParticipantsManagementRoutes,
  ...MyTeamRoutes,
  ...Dashboards,
  ...HAYFTRoutes,
  ...CompanySettingsRoute,
  ...ProfileManagementRoutes,
  ...AdminsRoutes
];

// routes for bi
export const menuSections = [
  {
    title: 'My Team',
    icon: <ParticipantsManagement />,
    route: MyTeamRoutes[0]
  },
  {
    title: 'HAYFT',
    icon: <HAYFTIcon />,
    route: HAYFTRoutes[0]
  },
  {
    title: 'Dashboards',
    icon: <StatisticsIcon />,
    route: Dashboards[0]
  }
];
