// Core
import { action } from 'mobx';

// Stores
import ValidationStore from '@Stores/ValidationStore';

// Providers
import { VaultProvider } from '@Providers/index';

class CompanySettingsStore extends ValidationStore {
  @action('CompanySettingsStore => getCompanyProfile')
  getCompanyProfile = (query, callback) => {
    return VaultProvider.getCompanyProfile(query)
      .then(response => callback(response))
      .catch(this._handleRequestError);
  };

  saveCompanyProfile = (payload, query, callback) => {
    return VaultProvider.saveCompanyProfile(payload, query)
      .catch(this._handleRequestError)
      .finally(response => callback(response));
  };

  updateCompanyProfile = (guid, payload, query, callback) => {
    return VaultProvider.updateCompanyProfile(guid, payload, query)
      .catch(this._handleRequestError)
      .finally(response => callback(response));
  };
}

export default new CompanySettingsStore();
