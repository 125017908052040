// Core
import { action, observable, runInAction } from 'mobx';

// Stores
import CommonStore from '@Stores/CommonStore';

// Providers
import { RiskStatusProvider } from '@Providers/';
import { formatDate, getStatusText } from '@Utils/formatting';

class RiskStatusStore {
  @observable stackbartData = [];

  @observable piChartData = [];

  @observable pichartDataDate = '';

  @observable pieChartData = [];

  @action('RiskStatusStore => getRiskStatusChartData')
  getRiskStatusChartData = dateRange => {
    CommonStore.setPending();
    return RiskStatusProvider.getRiskStatusChartData(dateRange)
      .then(resposnse => {
        runInAction(() => {
          this.stackbartData = this.processStackbarData(resposnse.content);
          this.piChartData = this.processPiChartData(resposnse.content);
        });
      })
      .finally(CommonStore.clearPending);
  };

  processStackbarData = rows => {
    const data = rows.slice().sort((a, b) => a.date - b.date);
    const sortedStatuses = this.getSortedStatuses(data);
    const dataArray = [];
    data.forEach(item => {
      const test = [];
      test.push(formatDate(item.date, 'DD/MM/YYYY'));
      [...sortedStatuses].forEach(status => {
        if (item.values[status]) {
          test.push(item.values[status]);
        } else {
          test.push(0);
        }
      });
      dataArray.push(test);
    });

    return [
      ['Date', ...[...sortedStatuses].map(status => getStatusText(status))],
      ...dataArray
    ];
  };

  processPiChartData = rows => {
    const data = rows.slice().sort((a, b) => b.date - a.date);
    const sortedStatuses = this.getSortedStatuses(data);
    const dataArray = [];
    const lastItem = data.length && data[0].values;
    [...sortedStatuses].forEach(status => {
      const temp = [];
      if (lastItem[status]) {
        temp.push(getStatusText(status), lastItem[status]);
        dataArray.push(temp);
      }
    });
    this.pichartDataDate = formatDate(
      data.length ? data[0].date : '',
      'MM/DD/YYYY'
    );
    return [['Date', 'Cases'], ...dataArray];
  };

  getSortedStatuses = data => {
    const uniqueStatuses = new Set();
    data.forEach(item => {
      Object.keys(item.values).forEach(status => {
        uniqueStatuses.add(status);
      });
    });

    const orderOfStatues = [
      'UNKOWN',
      'LOW_RISK',
      'HIGH_RISK',
      'EXTREME_RISK',
      'QUARANTINE'
    ];
    const sortedStatuses = [...uniqueStatuses];
    sortedStatuses.sort((a, b) => {
      return orderOfStatues.indexOf(a) - orderOfStatues.indexOf(b);
    });
    return sortedStatuses;
  };

  @action('RiskStatusStore => resetChartData')
  resetChartData = () => {
    this.stackbartData = [];
    this.piChartData = [];
  };
}
export default new RiskStatusStore();
