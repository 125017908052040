/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

// Material UI
import { DatePicker } from '@material-ui/pickers';

// Components
import Lablel from '@CommonScene/DateFilter/components/Label';

// Utils
import { compose } from '@Utils';

export class DateFilter extends Component {
  static propTypes = {
    i18n: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    handleChangeDate: PropTypes.func.isRequired,
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    clearDate: PropTypes.func.isRequired,
    disableFuture: PropTypes.bool
  };

  static defaultProps = {
    label: '',
    value: null,
    minDate: undefined,
    maxDate: undefined,
    disableFuture: false
  };

  state = {
    value: null,
    isCleared: false
  };

  static getDerivedStateFromProps(nextProps, nextState) {
    if (nextState.isCleared) {
      return { value: null };
    }
    // componentDidMount does't see value for the first time for some reason
    // so we assign value here
    return { value: nextProps.value || null };
  }

  onChange = () => {
    // not implemented but required
  };

  clearDate = () => {
    this.setState(
      {
        isCleared: true
      },
      this.props.clearDate()
    );
  };

  acceptDate = date => {
    if (date) {
      this.setState(
        {
          isCleared: false
        },
        this.props.handleChangeDate(date)
      );
    } else {
      // Clear button was clicked
      this.clearDate();
    }
  };

  render() {
    const {
      label,
      minDate,
      maxDate,
      disableFuture,
      i18n: { language },
      t
    } = this.props;

    moment.locale(language);

    return (
      <DatePicker
        clearable
        label={label}
        TextFieldComponent={Lablel}
        format="MM/DD/YYYY"
        value={this.state.value}
        onChange={() => this.onChange()}
        onAccept={date => this.acceptDate(date)}
        minDate={minDate}
        maxDate={maxDate}
        autoOk
        disableFuture={disableFuture}
        cancelLabel={t('common.cancel')}
        clearLabel={t('common.clear')}
        okLabel={t('common.ok')}
      />
    );
  }
}

export default compose(
  withTranslation(),
  observer
)(DateFilter);
